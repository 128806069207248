import * as yup from 'yup'

const schema = yup.object({
  question: yup.string().required('Aggiungi una domanda'),

  options: yup
    .array()
    .of(
      yup.object({
        value: yup.string(),
        votes: yup
          .array()
          .of(
            yup.object({
              identity: yup.string().required(),
              timestamp: yup.number().required(),
            }),
          )
          .required(),
        key: yup.string().required(),
      }),
    )
    .min(2)
    .max(5)
    .test((options = []) => {
      const nonEmptyOptionsValue = options.map(({ value }) => value).filter(Boolean).length
      const hasDuplicates = (function checkForDuplicates() {
        const array = options.map(({ value }) => value).filter(Boolean)
        return new Set(array).size !== array.length
      })()

      if (nonEmptyOptionsValue < 2) {
        return false
      }

      if (hasDuplicates) {
        return false
      }

      return true
    })
    .required(),

  multipleAnswers: yup.bool().required(),
  publishInAllTheCities: yup.bool(),
})

export type Schema = yup.InferType<typeof schema>
export default schema
