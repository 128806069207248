import React, { memo } from 'react'
import Api from 'services/api/api.service'
import { Box, useDisclosure, Checkbox, Flex, useToast, Collapse, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { Category } from 'typings/database'

const CategoryInput = ({ value, add, remove, disabled }: any) => {
  const toast = useToast()
  const {
    isLoading: isLoadingCategories,
    error,
    data: categories,
  } = useQuery('categories', () => Api.categories(), {
    retry: false,
  }) as {
    error: String | any
    isLoading: boolean
    data: { data: Category[] }
  }
  useEffect(() => {
    if (error && typeof error == 'string') {
      toast({
        title: error,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    } else if (error && typeof error?.message == 'string') {
      toast({
        title: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    }
  }, [error, toast])

  if (isLoadingCategories || !categories?.data) return null
  const categoriesByType = categories?.data.reduce(
    (a, prev) => ({ ...a, [prev.categoryType.name]: (a[prev.categoryType.name] || []).concat(prev) }),
    {},
  )
  return (
    <Box border="2px" borderRadius="15px" p="10px 20px" borderColor="#F7F7F7">
      {Object.keys(categoriesByType).map(type => (
        <CategoryAccordion key={type} {...{ type, categories: categoriesByType[type], value, add, remove, disabled }} />
      ))}
    </Box>
  )
}
const CategoryAccordion = ({
  type,
  categories,
  add,
  remove,
  value,
  disabled,
}: {
  add: Function
  remove: Function
  value: any[]
  type: string
  categories: Category[]
  disabled?: boolean
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box>
      <Flex cursor="pointer" justifyContent="space-between" onClick={isOpen ? onClose : onOpen}>
        <Text color="#465362" fontSize="16px" py="5px" fontWeight="semibold">
          {type}
        </Text>
        <Box transform={isOpen ? 'rotate(0deg)' : 'rotate(-90deg)'} transition="all .3s" mr="2px">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <g transform="translate(30) rotate(90)">
              <rect id="Rettangolo_57" data-name="Rettangolo 57" width="30" height="30" fill="transparent" />
              <g id="Raggruppa_20" data-name="Raggruppa 20" transform="translate(-2.258 8.485)">
                <line x1="6" y1="6" transform="translate(15.258 0.515)" fill="none" stroke="#011936" strokeLinecap="round" strokeWidth="3" />
                <line y1="6" x2="6" transform="translate(15.258 6.515)" fill="none" stroke="#011936" strokeLinecap="round" strokeWidth="3" />
              </g>
            </g>
          </svg>
        </Box>
      </Flex>
      <Collapse in={isOpen}>
        <Box pl="10px">
          {categories.map(c => (
            <Box key={c._id} onClick={() => !disabled && (value?.indexOf(c._id) > -1 ? remove(c._id) : add(c._id))}>
              <Flex alignItems="center" my="7px" cursor="pointer" justifyContent="space-between">
                {c.name}{' '}
                <Checkbox disabled={disabled} colorScheme="brand" pointerEvents="none" size="lg" mr="7px" isChecked={value?.indexOf(c._id) > -1} />
              </Flex>
            </Box>
          ))}
        </Box>
      </Collapse>
    </Box>
  )
}

export default CategoryInput
