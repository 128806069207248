import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { Message } from '@twilio/conversations'
import { AppContext } from 'app.context'
import { SYSTEM_AUTHOR_NAME } from 'costants'
import React, { useContext } from 'react'
import ChatMessage from '..'
import getTimeFromMessage, { getTimeFromTimestamp } from '../../hooks/get-time-from-message'
import useGetUserFromMessageQuery, { getAuthorNameFromUser } from '../../hooks/use-get-user-from-message'
import ChatMessageReplyBox from '../chat-message-reply-box'

function DeletedMessageModal({ msg, isOpen, onClose }: { msg: Message; isOpen: boolean; onClose: () => void }) {
  const { client: twilioClient } = useContext(AppContext)

  const { data: user } = useGetUserFromMessageQuery(msg)
  const author = msg.author === 'system' ? SYSTEM_AUTHOR_NAME : getAuthorNameFromUser(user)
  const time = getTimeFromMessage(msg)

  const attributes = msg.attributes as any // TODO: implement TS types for all the messages
  const deletedTime = getTimeFromTimestamp(attributes.deleted)
  const reply = attributes?.reply

  const isMine = user?.identity === twilioClient?.user.identity

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{author}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="40px">
          {reply && <ChatMessageReplyBox isMine={isMine} author={reply.author} body={reply.body} />}

          <ChatMessage msg={msg} />
        </ModalBody>
        <ModalFooter>
          <Flex direction="column" gap=".25rem" alignItems="flex-end">
            <div>
              <strong>Inviato il:</strong> {time}
            </div>

            <div>
              <strong>Cancellato il:</strong> {deletedTime}
            </div>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeletedMessageModal
