import { AppContext } from 'app.context'
import { useContext } from 'react'
import { useQuery } from 'react-query'

const useGetConversationQueryBySid = (sid, options = {}) => {
  const { client: twilioClient, setConversationAsRead } = useContext(AppContext)

  return useQuery(
    `event_chat_${sid}`,
    async () => {
      if (!twilioClient || !sid) {
        return
      }

      const conversation = await twilioClient.getConversationBySid(sid)
      const paginator = await conversation.getMessages()
      const participantsCount = await conversation.getParticipantsCount()

      const messages = await Promise.all(paginator.items)
      const attributes = conversation.attributes as { data?: { id: string; coverUrl: string | null }; type?: string }

      if (messages.length) {
        await conversation.updateLastReadMessageIndex(messages[messages.length - 1].index)
      }

      await setConversationAsRead?.(sid)

      return {
        conversation: {
          self: conversation,
          name: conversation.friendlyName,
          participantsCount,
          attributes,
        },
        paginator,
        messages,
      }
    },
    {
      enabled: !!twilioClient,
      ...options,
    },
  )
}

export default useGetConversationQueryBySid
