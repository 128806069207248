import { SignInWithApple } from '@capacitor-community/apple-sign-in'
import { Button } from '@chakra-ui/react'
import { OAuthProvider, signInWithCredential } from 'firebase/auth'
import React from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'
import { getFirebaseAuth } from 'services/firebase.service'
import shajs from 'sha.js'

const AppleAccessButton = (props: { signInWebWith: any; handleSignError: any; authProvider: string; setAuthProvider: any; isNative: any }) => {
  const navigate = useNavigate()

  const signInWithApple = async () => {
    if (!props.isNative) return props.signInWebWith('apple')()
    if (!!props.authProvider) return

    props.setAuthProvider('apple')

    const rawNonce = `sbam-nonce-${Date.now()}`
    const nonce = shajs('sha256').update(rawNonce).digest('hex')

    const options = {
      clientId: 'com.sbam.app', // Your app's client ID registered with Apple
      redirectURI: `https://${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}/__/auth/handler`, // Your app's redirect URI
      scopes: 'email name', // Scopes you want to request from the user
      state: '123456', // A random string to maintain state between request and callback
      nonce, // A unique, random string for each authentication request
    }

    try {
      const signInResult = await SignInWithApple.authorize(options)

      // saves name on LS in order to precompile field later
      localStorage.setItem(
        'apple-signin-cache',
        JSON.stringify({ firstName: signInResult.response.givenName, lastName: signInResult.response.familyName }),
      )

      const auth = getFirebaseAuth()
      const provider = new OAuthProvider('apple.com')
      const appleCredential = provider.credential({
        idToken: signInResult.response.identityToken,
        rawNonce,
      })

      const userCredential = await signInWithCredential(auth, appleCredential)
      const { claims } = await userCredential.user.getIdTokenResult(true)
      if (claims.sbamRegistered) {
        navigate('/')
        return
      }
      navigate(`/signup?${createSearchParams({ email: userCredential.user.email + '', isSSO: 'true' })}`)
    } catch (error: any) {
      ;(window as any).a = error
      props.handleSignError(error)
    }
  }

  return (
    <Button
      isLoading={props.authProvider === 'apple'}
      disabled={!!props.authProvider}
      onClick={signInWithApple}
      color="black"
      width="90%"
      bgColor="white"
      _hover={{ color: 'black' }}>
      Accedi con Apple
    </Button>
  )
}

export default AppleAccessButton
