import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import NotificationRow from 'components/shared/notification-row/notification-row.component'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import React, { useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import Api from 'services/api/api.service'
import { Notification } from 'typings/database.d'

const NotificationsScreen = () => {
  const toast = useRef(useToast())
  const { isLoading, error, data, refetch } = useQuery('notifications', () => Api.notifications()) as {
    error: String
    isLoading: boolean
    data: any
    refetch: any
  }
  useEffect(() => {
    if (error) {
      toast.current({
        title: error,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    }
  }, [error])
  const throttle = useRef<any>()
  const notifications = data?.data || []

  const handleVisibilityChange = async (id: string) => {
    await Api.readNotification(id)
    clearTimeout(throttle.current)
    throttle.current = setTimeout(() => {
      refetch()
    }, 800)
  }

  return (
    <Wrapper>
      <Box pt="20px" pb="25px" fontSize="1.25rem" fontWeight="semibold">
        Notifiche
      </Box>

      <Flex flex={1} direction="column">
        {!isLoading && !notifications.length && <Text>Nessuna notifica.</Text>}
        {notifications.map((notification: Notification) => (
          <NotificationRow key={notification._id} {...notification} onChange={handleVisibilityChange} />
        ))}
      </Flex>
    </Wrapper>
  )
}

export default NotificationsScreen
