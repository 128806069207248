import { Share } from '@capacitor/share'
import { Box, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Spacer, Text } from '@chakra-ui/react'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { IoMdShare } from 'react-icons/io'
import { ReactComponent as WarningIcon } from './../../assets/warning-icon.svg'
import EventDeletionDialog from './event-deletion-dialog'
import ShareEventDialog from './share-event-dialog'
import AttendanceDeletionDialog from './attendance-deletion-dialog'

const EventScreenHeader = props => {
  const [copied, setCopied] = useState(false)
  const [isSocialOpen, setSocialOpen] = useState(false)
  const [isDeleteOpen, setDeleteOpen] = useState(false)
  const [isDeleteAttendanceOpen, setDeleteAttendanceOpen] = useState(false)
  const onDeleteAttendanceClose = () => setDeleteAttendanceOpen(false)
  const cancelDeleteAttendanceRef = useRef(null)

  const onSocialClose = () => {
    setCopied(false)
    setSocialOpen(false)
  }

  const shareEvent = async () => {
    const { value: canShare } = await Share.canShare()
    if (!canShare) {
      // toast({
      //   title: "Condivisione non abilitata",
      //   status: 'error',
      //   duration: 2500,
      //   isClosable: true,
      //   position: 'top',
      // })
      setSocialOpen(true)

      return
    }

    try {
      await Share.share({
        dialogTitle: 'Condividi evento sui social',
        title: props.event.title,
        text: props.event.description,
        url: `${process.env.REACT_APP_APP_URL}${window.location.pathname}`,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const goToAssistance = () => {
    window.open('https://sbam.social/supporto', '_blank')
  }

  const deleteEvent = async () => {
    if (props.isOrganizer) {
      setDeleteOpen(true)
    }
  }

  const openEditingEventScreen = () => props.navigate('edit')

  const deleteAttendance = async () => {
    setDeleteAttendanceOpen(true)
  }

  const goToReportProfile = () => {
    props.navigate('/profile/65508a651530cb00136f5548')
  }

  return (
    <>
      <Flex direction="row" justify="center" align="center" pt="2" pb="2" position="relative" width="100%" height="fit-content">
        <Icon as={IoMdShare} boxSize="1.6em" onClick={shareEvent} _hover={{ color: 'brand.500' }} />
        <Spacer />
        <Text fontSize="15px" fontWeight="600" fontFamily="Poppins">
          #{props.event.category?.name}
        </Text>
        <Spacer />
        {props.isOrganizer && moment(props.event.startDate).isAfter() && (
          <Box pos="relative" top="1">
            <Menu>
              <MenuButton>
                <Icon as={GiHamburgerMenu} boxSize="1.6em" _hover={{ color: 'brand.500' }} />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={openEditingEventScreen}>Modifica evento</MenuItem>
                <MenuItem onClick={deleteEvent}>Cancella evento</MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}
        {!props.isOrganizer && !!props.attendance && (
          <Box pos="relative" top="1">
            <Menu>
              <MenuButton>
                <Icon as={GiHamburgerMenu} boxSize="1.6em" _hover={{ color: 'brand.500' }} />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={goToReportProfile}>Segnala evento</MenuItem>
                {!props.isApproved && <MenuItem onClick={deleteAttendance}>Annulla partecipazione</MenuItem>}
              </MenuList>
            </Menu>
          </Box>
        )}
        {((!props.isOrganizer && !props.attendance) || (props.isOrganizer && !moment(props.event.startDate).isAfter())) && (
          <Icon as={WarningIcon} boxSize="1.6em" _hover={{ color: 'brand.500' }} cursor="pointer" onClick={goToAssistance} />
        )}
      </Flex>
      <ShareEventDialog isSocialOpen={isSocialOpen} onSocialClose={onSocialClose} copied={copied} setCopied={setCopied} />
      <EventDeletionDialog
        event={props.event}
        isDeleteOpen={isDeleteOpen}
        setDeleteOpen={setDeleteOpen}
        id={props.id}
        isOrganizer={props.isOrganizer}
        navigate={props.navigate}
      />
      <AttendanceDeletionDialog
        isDeleteAttendanceOpen={isDeleteAttendanceOpen}
        cancelDeleteAttendanceRef={cancelDeleteAttendanceRef}
        onDeleteAttendanceClose={onDeleteAttendanceClose}
        setDeleteAttendanceOpen={setDeleteAttendanceOpen}
        attendance={props.attendance}
        event={props.event}
        refetch={props.refetch}
      />
    </>
  )
}

export default EventScreenHeader
