import { AppTrackingTransparency } from "capacitor-plugin-app-tracking-transparency";

export const iosTracking = async (email) => {
  if (localStorage.getItem('brevo-track') === 'true') {
    const { status } = await AppTrackingTransparency.getStatus();
    if (status === 'authorized') {
      tracking(email)
    }
  } else {
    const { status: requestStatus } = await AppTrackingTransparency.requestPermission();
    if (requestStatus === 'authorized') {
      tracking(email)
    }
    localStorage.setItem('brevo-track', 'true')
  }
}

export const tracking = async (email) => {
  trackBrevo(email)
}

export const trackBrevo = (email) => {
  // @ts-ignore
  window.sib = {
    equeue: [],
    client_key: "k0le2xfdq7cdqtomekk3334r"
  };
  /* OPTIONAL: email for identify request*/
  // @ts-ignore
  window.sib.email_id = email;
  // @ts-ignore
  window.sendinblue = {};
  for (let j = ['track', 'identify', 'trackLink', 'page'], i = 0; i < j.length; i++) {
    (function (k) {
      // @ts-ignore
      window.sendinblue[k] = function () {
        var arg = Array.prototype.slice.call(arguments);
        // @ts-ignore
        (window.sib[k] || function () {
          var t = {};
          t[k] = arg;
          // @ts-ignore
          window.sib.equeue.push(t);
        })(arg[0], arg[1], arg[2], arg[3]);
      };
    })(j[i]);
  }
  // @ts-ignore
  var n = document.createElement("script"), i = document.getElementsByTagName("script")[0];
  // @ts-ignore
  n.type = "text/javascript";
  n.id = "sendinblue-js";
  n.async = !0;
  // @ts-ignore
  n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key;
  // @ts-ignore
  i.parentNode.insertBefore(n, i);
  // @ts-ignore
  window.sendinblue.page();
  }