import { FirebaseAnalytics } from '@capacitor-firebase/analytics'
import { Capacitor } from '@capacitor/core'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useSetGARoutes = () => {
  const location = useLocation()

  useEffect(() => {
    const trackPageView = async () => {
      if (!Capacitor.isNativePlatform() || !(await FirebaseAnalytics.isEnabled()).enabled) return

      FirebaseAnalytics.logEvent({ name: 'screen_view', params: { firebase_screen_class: location.pathname } })

      // await FirebaseAnalytics.setCurrentScreen({
      //   screenName: location.pathname,
      //   screenClassOverride: location.pathname,
      // })
    }

    trackPageView()
  }, [location])
}
