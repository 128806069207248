import { Flex, FormControl, FormErrorMessage, Icon, Radio, RadioGroup, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import { ReactComponent as MaleIcon } from '../../assets/male-symbol.svg'
import { ReactComponent as FemaleIcon } from '../../assets/female-symbol.svg'
import { ReactComponent as GenderQueerIcon } from '../../assets/genderqueer.svg'

function GenderRadioGroup(props: { errors: any; register: any; gender: string | undefined }) {
  return (
    <Flex flexDir="column" gap="0.5rem">
      <Text textColor="#465362" fontWeight="600" fontSize="0.9rem">
        In che genere ti identifichi?{' '}
        <Text color="#FF7436" display="inline">
          *
        </Text>
      </Text>
      <FormControl isInvalid={!!props.errors.gender}>
        <RadioGroup width="100%" colorScheme="brand" defaultValue={props.gender}>
          <Flex flexDir="row" alignItems="center">
            <Radio size="lg" value="male" {...props.register('gender', { required: 'Devi specificare un sesso' })}>
              <Flex flexDir="row" gap="0.3rem" alignItems="center">
                <Icon as={MaleIcon} width="0.9375rem" height="0.9375rem" />
                <Text fontSize="1rem">Uomo</Text>
              </Flex>
            </Radio>
            <Spacer />
            <Radio size="lg" value="female" {...props.register('gender', { required: 'Devi specificare un sesso' })}>
              <Flex flexDir="row" gap="0.3rem" alignItems="center">
                <Icon as={FemaleIcon} width="0.9375rem" height="0.9375rem" />
                <Text fontSize="1rem">Donna</Text>
              </Flex>
            </Radio>
            <Spacer />
            <Radio size="lg" value="other" {...props.register('gender', { required: 'Devi specificare un sesso' })}>
              <Flex flexDir="row" gap="0.3rem" alignItems="center">
                <Icon as={GenderQueerIcon} width="0.9375rem" height="0.9375rem" />
                <Text fontSize="1rem">Altrə</Text>
              </Flex>
            </Radio>
          </Flex>
        </RadioGroup>
      </FormControl>
    </Flex>
  )
}

export default GenderRadioGroup
