import { Clipboard } from '@capacitor/clipboard'
import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'
import { Box, Button, CloseButton, Flex, Spacer, Text, VStack } from '@chakra-ui/react'
import { SbamIcon } from 'components/shared/icons/icons'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { selectorAuth } from 'features/auth/auth.slice'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import friendsImage from '../../assets/invite-friends.svg'

const ReferralScreen = () => {
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [isCodeCopied, setIsCodeCopied] = useState<boolean>(false)
  const navigate = useNavigate()
  const { user } = useSelector(selectorAuth)

  const handleOnShareLink = async () => {
    const url = `${process.env.REACT_APP_APP_URL}/signup?userCode=${user.ambassadorCode ?? user.userCode}`

    if (Capacitor.isNativePlatform()) {
      await Share.share({
        text: 'Condividi il tuo link per far registrare altrə col tuo codice e ottenere saette extra!',
        url,
      })
    } else {
      await Clipboard.write({ url })

      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 3000)
    }
  }

  const handleOnCopyCode = async () => {
    await Clipboard.write({ string: user.ambassadorCode ?? user.userCode })

    setIsCodeCopied(true)
    setTimeout(() => {
      setIsCodeCopied(false)
    }, 3000)
  }

  const handleOnClose = () => {
    navigate(-1)
  }

  return (
    <>
      <Wrapper height="100%" pb="90px" pl="0" pr="0" pt="20px">
        <Flex pl="10px" pr="10px">
          <Text fontSize="2rem" fontWeight="semibold">
            Invita amici
          </Text>

          <Spacer />

          <CloseButton size="lg" onClick={handleOnClose} />
        </Flex>

        <Text fontSize="xl" mt="60px" fontWeight="medium" pl="10px" pr="10px">
          Ottieni {user.ambassadorCode ? 20 : 10}€ in saette
          <SbamIcon ml="6px" />
          per ogni persona che si iscrive grazie a te.
        </Text>

        <VStack
          bgImg={friendsImage}
          width="100%"
          height="100%"
          bgSize="contain"
          bgPos="bottom"
          bgRepeat="no-repeat"
          mt="20px"
          padding={0}
          margin={0}
          position="relative"
          right="0">
          <Spacer />

          <Box pl="20px" pr="20px" mb="65px">
            <Button width="100%" mb="15px" onClick={handleOnShareLink}>
              {!isCopied ? 'Condividi link invito' : 'Link invito copiato'}
            </Button>

            <Button width="100%" variant="outline_white" onClick={handleOnCopyCode}>
              {!isCodeCopied ? 'Copia codice invito' : 'Codice invito copiato'}
            </Button>
          </Box>
        </VStack>
      </Wrapper>
    </>
  )
}

export default ReferralScreen
