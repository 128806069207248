import { Capacitor } from '@capacitor/core'
import { Flex, Spacer, useToast } from '@chakra-ui/react'
import { selectorAuth } from 'features/auth/auth.slice'
import { browserPopupRedirectResolver, fetchSignInMethodsForEmail, GoogleAuthProvider, OAuthProvider, signInWithPopup } from 'firebase/auth'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router'
import { getFirebaseAuth } from 'services/firebase.service'
import { isIos } from 'utils/platform'
import AppVersion from './app-version'
import AppleAccessButton from './apple-access-button'
import EmailAccess from './email-access'
import GoogleAccessButton from './google-access-button'
import { createSearchParams } from 'react-router-dom'

const AccessButtons = () => {
  const authState = useSelector(selectorAuth)
  const toast = useToast()
  const isNative = Capacitor.isNativePlatform()
  const [authProvider, setAuthProvider] = useState<string>('')
  const navigate = useNavigate()

  const switchWebProvider = (providerSlug: string) => {
    switch (providerSlug) {
      case 'google':
        return GoogleAuthProvider
      default:
        throw new Error('Provider not supported')
    }
  }

  const signInWebWith = (providerSlug: string) => async () => {
    if (!!authProvider) return
    setAuthProvider(providerSlug)
    let providerInstance
    if (providerSlug === 'apple') {
      providerInstance = new OAuthProvider('apple.com')
      providerInstance.addScope('name')
    } else {
      const provider = switchWebProvider(providerSlug)
      providerInstance = new provider()
    }

    try {
      const auth = getFirebaseAuth()
      const userCredential = await signInWithPopup(auth, providerInstance, browserPopupRedirectResolver)
      const { claims } = await userCredential.user.getIdTokenResult(true)
      if (claims.sbamRegistered) {
        navigate('/')
        return
      }
      navigate(`/signup?${createSearchParams({ email: userCredential.user.email + '', isSSO: 'true' })}`)
    } catch (error: any) {
      handleSignError(error)
    }
  }

  const handleSignError = useCallback(
    async (error: any) => {
      // Handle Errors here.
      const errorCode = (error.code || '').replace('auth/', '')
      const errorMessage = error.message
      // The email of the user's account used.
      const email = error.customData?.email
      // The AuthCredential type that was used.
      if (errorCode === 'account-exists-with-different-credential') {
        if (email) {
          const signInMethods = await fetchSignInMethodsForEmail(getFirebaseAuth(), email)
          // methods contains the strings of methods, last element is separated by "e"
          const methods = signInMethods
            .map(m => {
              switch (m) {
                case 'password':
                  return 'Email'
                case 'google.com':
                  return 'Google'
                case 'apple.com':
                  return 'Apple'
                default:
                  return m
              }
            })
            .join(', ')
            .replace(/,([^,]*)$/, ' e$1')
          toast({
            title: 'Account già registrato',
            description: `Questo account è già registrato con ${methods}.`,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        } else {
          toast({
            title: 'Account già registrato',
            description: `Questa email è registrata con un altro metodo di accesso.`,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        }
      } else {
        if (
          !errorMessage.includes('com.apple.AuthenticationServices.AuthorizationError 1001') &&
          errorCode !== 'popup-closed-by-user' &&
          errorCode !== 'cancelled-popup-request' &&
          errorMessage !== 'Sign in canceled.' &&
          errorMessage !== 'The user canceled the sign-in flow.'
        ) {
          toast({
            title: 'Errore',
            description: errorMessage,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        }
      }
      setAuthProvider('')
    },
    [toast],
  )

  if (authState.isAuthenticated && authState.isRegistered) {
    return (
      <Navigate
        to={{
          pathname: '/',
        }}
      />
    )
  }

  return (
    <Flex flexDir="column" width="100%" gap="1.5rem" alignItems="center" position="absolute" bottom="0" left="0">
      {isIos() && (
        <AppleAccessButton
          signInWebWith={signInWebWith}
          handleSignError={handleSignError}
          authProvider={authProvider}
          setAuthProvider={setAuthProvider}
          isNative={isNative}
        />
      )}
      <GoogleAccessButton
        signInWebWith={signInWebWith}
        switchWebProvider={switchWebProvider}
        handleSignError={handleSignError}
        authProvider={authProvider}
        setAuthProvider={setAuthProvider}
        isNative={isNative}
      />
      <EmailAccess authProvider={authProvider} />
      <AppVersion />
      <Spacer />
    </Flex>
  )
}

export default AccessButtons
