import { Box, FormControl, FormErrorMessage, Text } from '@chakra-ui/react'
import InputField from '@components/shared/input/input.component'
import React from 'react'

const EmailField = (props: { register: any; email: string | null; label: string; placeholder: string | null }) => {
  return (
    <Box>
      <Text textColor="#465362" fontWeight="600" fontSize="0.9rem">
        {props.label}{' '}
        <Text color="#FF7436" display="inline">
          *
        </Text>
      </Text>
      <InputField
        type="email"
        {...props.register('email', { required: 'Devi inserire la tua e-mail' })}
        placeholder={props.placeholder}
        value={props.email}
        isReadOnly={props.email !== null}
      />
    </Box>
  )
}

export default EmailField
