import { Center, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import QrCodeSvg from 'components/shared/qr-code/qr-code-svg'
import React from 'react'

const EventConfirmAttendance = props => {
  return (
    <Modal isCentered isOpen={props.isQROpen} onClose={props.onQRClose}>
      <ModalOverlay bg="blackAlpha.400" backdropFilter="blur(3px)" />
      <ModalContent mr="10px" ml="10px">
        <ModalHeader fontWeight="bold">Conferma la tua presenza</ModalHeader>
        <ModalCloseButton size="lg" />
        <ModalBody pb="40px">
          <Flex direction="column" gap=".5rem">
            <Center>
              <QrCodeSvg
                size={Math.min(window.innerWidth, 560) * 0.6}
                level={'L'}
                value={JSON.stringify({ attendanceId: props.attendance?._id, attendanceCode: props.attendance?.code })}
              />
            </Center>

            <Text textAlign="left" fontSize="15px" mb="2">
              Fai scansionare questo codice all'organizzatore per confermare la tua presenza e ottenere saette bonus.
            </Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default EventConfirmAttendance
