import { Box } from '@chakra-ui/react'
import React from 'react'
import AccessBG from '../../assets/sbam-access-bg.mp4'

const BackgroundVideo = () => {
  return (
    <>
      <Box width="100%" height="100%" position="fixed" zIndex="-1" background="rgba(1, 25, 54, 0.6)" />
      <Box width="100%" height="100%" position="fixed" zIndex="-2">
        <video
          loop
          controls={false}
          playsInline={true}
          autoPlay={true}
          muted
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            zIndex: -2,
            filter: 'blur(10px)',
            transform: 'scale(1.1)',
          }}>
          <source src={AccessBG} type="video/mp4" />
        </video>
      </Box>
    </>
  )
}

export default BackgroundVideo
