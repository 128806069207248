import Api from 'services/api/api.service'
import { Dispatch } from 'react'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { RootState } from '..'
import { setAuthState, setIsRegistered, setLoading, setSavedSignupState, setUser } from './auth.slice'
import { queryClient } from 'query-client'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { getFirebaseAuth } from 'services/firebase.service'
import { FirebaseAnalytics } from '@capacitor-firebase/analytics'
import { Capacitor } from '@capacitor/core'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'

export const loginAction = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const auth = getFirebaseAuth()
      const token = await auth.currentUser?.getIdToken(false)
      if (!token) throw new Error('No token')
      const { data: user } = await Api.currentProfile()
      if (user._id && Capacitor.isNativePlatform()) {
        FirebaseAnalytics.setUserId({
          userId: user._id,
        })
      }
      dispatch(setUser(user))
      dispatch(setAuthState(true))
      dispatch(setIsRegistered(true))
      dispatch(setLoading(false))
    } catch (error) {
      console.log(error)
    }
  }
}

export const refreshUser = () => {
  return async (dispatch: Dispatch<any>) => {
    const { data: user } = await Api.currentProfile()
    dispatch(setUser(user))
  }
}

export const logoutAction = (): ThunkAction<void, RootState, unknown, Action<string>> => {
  const auth = getFirebaseAuth()
  // if is native
  FirebaseAuthentication.signOut()
  auth.signOut()
  FirebaseMessaging.deleteToken()
  return async dispatch => {
    if (Capacitor.isNativePlatform()) {
      FirebaseAnalytics.setUserId({
        userId: null,
      })
    }
    dispatch(setUser(null))
    dispatch(setAuthState(false))
    dispatch(setIsRegistered(false))
    queryClient.clear()
  }
}

export const resetSavedSignupState = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(
    setSavedSignupState({
      email: '',
      firebaseUid: '',
      password: '',
      confirmPassword: '',
      hasTermsAndConditionAccepted: false,
      hasPrivacyPolicyAccepted: false,
      hasMarketingCommunicationsAccepted: false,
      birthdate: '',
      firstName: '',
      lastName: '',
      gender: '',
    }))
  }
}
