import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import appReducer from 'features/app/app.slice'
import authReducer from 'features/auth/auth.slice'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['isLoading'],
  version: 3,
}

const rootReducer = combineReducers({
  auth: persistReducer(persistConfig, authReducer),
  app: appReducer,
})


export const store = configureStore({ reducer: rootReducer, middleware: [thunk] })
export const persistor = persistStore(store)
type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export type RootState = ReturnType<typeof rootReducer>
