import { Flex, Icon, Spacer, Text } from '@chakra-ui/react'
import Attendees from 'components/shared/attendees/attendees.component'
import React from 'react'
import { Attendance } from 'typings/database'
import { ReactComponent as AgeIcon } from '@assets/age-restricion.svg'
import { ReactComponent as FemaleIcon } from '@assets/female-symbol.svg'
import { ReactComponent as NonBinaryIcon } from '@assets/genderqueer.svg'
import { ReactComponent as GroupIcon } from '@assets/group2-icon.svg'
import { ReactComponent as MaleIcon } from '@assets/male-symbol.svg'

function EventParticipantsInfo(props) {
  const organizerAttends = props.event.attendances && !props.event.attendances.find((e: Attendance) => e.user._id === props.event.organizer._id)
  const organizerAttendance = organizerAttends && { user: props.event.organizer }

  const minAge = props.event.limitations.minAge
  const maxAge = props.event.limitations.maxAge

  return (
    <Flex gap="1rem" alignItems="center">
      <MaxParticipants {...props} />

      <AgeRange minAge={minAge} maxAge={maxAge} />

      <GenderBalance {...props} />

      <Spacer />

      {(props.isOrganizer || props.attendance) && (
        <Attendees
          max={props.event.limitations.maxParticipants}
          list={props.event.attendances}
          organizer={props.event.organizer}
          organizerAttendance={organizerAttendance}
          limitations={props.event.limitations}
          counts={props.event.genderBalanceCounts}
          endDate={props.event.endDate}
        />
      )}
    </Flex>
  )
}

function MaxParticipants(props) {
  return (
    <Flex gap=".25rem" alignItems="center">
      <Icon as={GroupIcon} boxSize="19px" color="#011936" />

      <Text fontFamily="Poppins" fontWeight="400" fontSize="15px">
        {(props.isOrganizer || props.attendance) && `${props.event.attendancesCount}/`}
        {props.event.limitations.maxParticipants}
      </Text>
    </Flex>
  )
}

function AgeRange({ minAge, maxAge }) {
  if (!minAge || !maxAge) {
    return <></>
  }

  return (
    <Flex gap=".25rem" alignItems="center">
      <Icon as={AgeIcon} width="15px" height="15px" />

      <Text fontFamily="Poppins" fontWeight="400" fontSize="15px">
        {`${minAge}-${maxAge}`}
      </Text>
    </Flex>
  )
}

function GenderBalance(props) {
  const genderBalanceActive = props.event.limitations.genderBalanceActive

  const maleParticipants = props.event.limitations.maleParticipants
  const MaleParticipants = () =>
    maleParticipants ? (
      <Flex gap=".15rem" alignItems="center">
        <Icon as={MaleIcon} width="15px" height="15px" />
        <Text fontFamily="Poppins" fontWeight="400" fontSize="15px" display="inline">
          {maleParticipants}
        </Text>
      </Flex>
    ) : (
      <></>
    )

  const femaleParticipants = props.event.limitations.femaleParticipants
  const FemaleParticipants = () =>
    femaleParticipants ? (
      <Flex gap=".15rem" alignItems="center">
        <Icon as={FemaleIcon} width="15px" height="15px" />
        <Text fontFamily="Poppins" fontWeight="400" fontSize="15px" display="inline">
          {femaleParticipants}
        </Text>
      </Flex>
    ) : (
      <></>
    )

  const otherParticipants = props.event.limitations.otherParticipants
  const OtherParticipants = () =>
    otherParticipants ? (
      <Flex gap=".15rem" alignItems="center">
        <Icon as={NonBinaryIcon} width="15px" height="15px" />
        <Text fontFamily="Poppins" fontWeight="400" fontSize="15px" display="inline">
          {otherParticipants}
        </Text>
      </Flex>
    ) : (
      <></>
    )

  if (!genderBalanceActive) {
    return <></>
  }

  return (
    <Flex gap=".25rem">
      <MaleParticipants />
      <FemaleParticipants />
      <OtherParticipants />
    </Flex>
  )
}

export default EventParticipantsInfo
