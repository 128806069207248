import { Box, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import useAmIAdminOrSupport from '@hooks/use-am-i-admin-or-support'
import useGetConversationQueryBySid from '@screens/chat/hooks/use-get-conversation-query'
import { ChatPageTab } from '@screens/chatlist/types'
import React from 'react'
import { FaPaperclip } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import Announcement from './announcement'
import Photocamera from './photocamera'
import Poll from './poll'
import SharePosition from './shared-position'

function ChatPlusMenu() {
  const isAdminOrSupport = useAmIAdminOrSupport()
  const { sid } = useParams()
  const { data } = useGetConversationQueryBySid(sid)

  const isAnnouncementEnabled = isAdminOrSupport && data?.conversation.attributes.type === ChatPageTab.CommunityChat

  return (
    <Menu>
      <MenuButton as="button">
        <Box color="brand.500" cursor="pointer" fontSize="1.5rem">
          <FaPaperclip />
        </Box>
      </MenuButton>

      <MenuList>
        {isAnnouncementEnabled && (
          <MenuItem paddingTop=".5rem" paddingBottom=".5rem">
            <Announcement />
          </MenuItem>
        )}

        <MenuItem paddingTop=".5rem" paddingBottom=".5rem">
          <Poll />
        </MenuItem>

        <MenuItem paddingTop=".5rem" paddingBottom=".5rem">
          <SharePosition />
        </MenuItem>

        <MenuItem paddingTop=".5rem" paddingBottom=".5rem">
          <Photocamera />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default ChatPlusMenu
