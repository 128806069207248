import {
  Box,
  Button,
  Checkbox,
  Flex,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FormbitContextProvider, useFormbitContext } from '@radicalbit/formbit'
import { I } from 'components/shared/input/input.style'
import React, { ChangeEvent, useState } from 'react'
import { FaPollH } from 'react-icons/fa'
import schema, { Schema } from './schema'
import useHandleOnSubmit from './use-handle-on-submit'
import useAmIAdminOrSupport from '@hooks/use-am-i-admin-or-support'

const initialValues: Schema = {
  question: '',
  options: [
    { value: undefined, votes: [], key: crypto.randomUUID().toString() },
    { value: undefined, votes: [], key: crypto.randomUUID().toString() },
  ],
  multipleAnswers: false,
  publishInAllTheCities: false,
}

function Poll() {
  return (
    <FormbitContextProvider<Schema> initialValues={initialValues} schema={schema}>
      <PollInner />
    </FormbitContextProvider>
  )
}

function PollInner() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const {
    handleOnSubmit,
    args: { isLoading },
    isSubmitDisabled,
  } = useHandleOnSubmit(setIsModalOpen)

  const handleOnClick = () => {
    setIsModalOpen(true)
  }

  const handleOnClose = () => {
    setIsModalOpen(false)
  }

  return (
    <Flex alignItems="center" gap=".75rem" onClick={handleOnClick} cursor="pointer">
      <Box color="brand.500" fontSize="1.5rem">
        <FaPollH />
      </Box>

      <div>Sondaggio</div>

      <Modal isOpen={isModalOpen} onClose={handleOnClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody pb="40px">
            <Flex gap="2.5rem" direction="column">
              <Question />

              <Options />

              <MultipleAnswersCheckbox />

              <PublishInAllTheCitiesCheckbox />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex color="brand.500" cursor="pointer" fontSize="1.5rem" width="100%" justifyContent="center" paddingBottom="3rem">
              <Button borderRadius="10px" isDisabled={isSubmitDisabled} isLoading={isLoading} onClick={handleOnSubmit} type="button">
                Crea Sondaggio
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

function Question() {
  const { write } = useFormbitContext<Schema>()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    write('question', e.target.value)
  }

  return (
    <Flex direction="column" gap="1rem" width="100%">
      <Box fontWeight="500">Domanda</Box>
      <I onChange={onChange} placeholder="Fai una domanda" />
    </Flex>
  )
}

function Options() {
  const { write, form } = useFormbitContext<Schema>()
  const options = form.options || []

  return (
    <Flex direction="column" gap="1rem" width="100%">
      <Box fontWeight="500">Opzioni</Box>

      {options.map((option, index) => {
        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
          if (index === options.length - 1 && !option[index] && options.length < 5) {
            options.push({ value: undefined, votes: [], key: crypto.randomUUID() })
          }

          write('options.[' + index + ']', { ...option, value: e.target.value })
        }

        const handleOnBlur = () => {
          const emptyValues = options.filter(o => !o.value)

          if (emptyValues.length <= 1) {
            return
          }

          if (options.length > 2 && !option.value) {
            options.splice(index, 1)
            write('options', options)
          }
        }

        return (
          <InputGroup key={option.key}>
            <I onBlur={handleOnBlur} onChange={onChange} value={option.value} placeholder="Scegli una opzione" />
          </InputGroup>
        )
      })}
    </Flex>
  )
}

function MultipleAnswersCheckbox() {
  const { write, form } = useFormbitContext<Schema>()
  const multipleAnswers = !!form.multipleAnswers

  const handleOnChangeMultipleOptions = ({ target: { checked } }) => {
    write('multipleAnswers', checked)
  }

  return (
    <Checkbox onChange={handleOnChangeMultipleOptions} colorScheme="brand.500" fontWeight="500" checked={multipleAnswers}>
      Consenti più risposte
    </Checkbox>
  )
}

function PublishInAllTheCitiesCheckbox() {
  const { write, form } = useFormbitContext<Schema>()
  const publishInAllTheCities = !!form.publishInAllTheCities

  const isEnabled = useAmIAdminOrSupport()

  if (!isEnabled) {
    return <></>
  }

  const handleOnChangeMultipleOptions = ({ target: { checked } }) => {
    write('publishInAllTheCities', checked)
  }

  return (
    <Checkbox onChange={handleOnChangeMultipleOptions} colorScheme="brand.500" fontWeight="500" checked={publishInAllTheCities}>
      Pubblica in tutte le città
    </Checkbox>
  )
}

export default Poll
