import { MessageAttributes, MessageType } from '@screens/chat/types'
import { Message } from '@twilio/conversations'
import React from 'react'
import EventMessage from './event-message'
import EventPreviewMessage from './event-preview-message'
import MediaMessage from './media-message'
import PollMessage from './poll-message'
import PositionMessage from './position-message'
import TextMessage from './text-message'

function ChatMessage({ msg }) {
  const attributes = msg.attributes as MessageAttributes
  const type = attributes.type

  switch (type?.toLowerCase()) {
    case MessageType.Location: {
      return <PositionMessage msg={msg} />
    }

    case MessageType.Media: {
      return <MediaMessage msg={msg} />
    }

    case MessageType.Poll: {
      return <PollMessage msg={msg} />
    }

    case MessageType.Event: {
      return <EventMessage msg={msg} />
    }

    case MessageType.EventPreview: {
      return <EventPreviewMessage msg={msg} />
    }

    case MessageType.Text: {
      return <TextMessage msg={msg} />
    }

    default: {
      return <TextMessage msg={msg} /> // Retrocompatibility: {type: reply} and old text message without attributes, just message.body
    }
  }
}

export default ChatMessage
