import { useToast } from '@chakra-ui/react'
import { Input } from '@components/shared/input/input.component'
import { useAppDispatch } from '@src/features'
import { resetSavedSignupState } from '@src/features/auth/auth.actions'
import { selectorAuth } from '@src/features/auth/auth.slice'
import { validEmail } from '@src/utils/validate-email'
import { fetchSignInMethodsForEmail } from 'firebase/auth'
import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'
import { getFirebaseAuth } from 'services/firebase.service'

const EmailAccessField = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const { savedSignupState } = useSelector(selectorAuth)
  const formRef: React.LegacyRef<HTMLFormElement> = useRef(null)

  const { register, handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const showError = errors => {
    if (errors.email) {
      toast({
        title: `${errors.email?.message}`,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    }
  }

  const onSubmit = async (data: any) => {
    const auth = getFirebaseAuth()
    // Check if user email exists in Firebase
    const email = data.email
    if (savedSignupState?.email && email !== savedSignupState.email) {
      dispatch<any>(resetSavedSignupState())
    }
    const methods = await fetchSignInMethodsForEmail(auth, email)
    if (methods.indexOf('password') > -1) {
      return navigate(`/login?${createSearchParams({ email: email })}`)
    } else if (methods.length > 0) {
      toast({
        title: 'Email già registrata con ' + methods.join(', '),
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
      return
    } else {
      return navigate(`/signup?${createSearchParams({ email: email })}`)
    }
  }

  const onSubmitClick = () => {
    formRef?.current?.requestSubmit()
  }

  return (
    <form ref={formRef} style={{ display: 'contents' }} onSubmit={handleSubmit(onSubmit, showError)}>
      <Input
        height="2.9375rem"
        width="90%"
        type="email-center"
        {...register('email', { required: 'Inserisci una e-mail', validate: val => validEmail(val) || 'Devi specificare una mail valida' })}
        placeholder="Inserisci qui la tua email..."
        onClick={onSubmitClick}
      />
    </form>
  )
}

export default EmailAccessField
