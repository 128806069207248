import React from 'react'
import { UnsplashImage } from './use-unsplash-image'

export const UnsplashCredits = ({ unsplashPhoto }: { unsplashPhoto: UnsplashImage }) => {
  return (
    <small style={{ fontSize: 8 }}>
      Photo by{' '}
      <a href={`${unsplashPhoto.portfolioUrl}?utm_source=Sbam!&utm_medium=referral`} target="black" style={{ textDecoration: 'underline' }}>
        {unsplashPhoto.name}
      </a>{' '}
      on{' '}
      <a href="https://unsplash.com?utm_source=Sbam!&utm_medium=referral" target="black" style={{ textDecoration: 'underline' }}>
        Unsplash
      </a>
    </small>
  )
}
