import React, { useCallback, useEffect, useState } from 'react'
import { Box, Flex, Text, Heading } from '@chakra-ui/react'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import Api from 'services/api/api.service'
import { Link as RouterLink } from 'react-router-dom'

export const ConfirmSignup = () => {
  const [message, setMessage] = useState('')
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')
  const userId = urlParams.get('id')
  
  const confirmSignup = useCallback(async () => {
    try {
      if (!token) {
        return alert('Token non valido')
      }
      if (!userId) {
        return alert('Richiesta non valida')
      }
      await Api.confirmSignup(token, userId)
      setMessage("Email confermata. Torna indietro per effettuare il login o per tornare all'app.")
    } catch (e) {
      setMessage("C'é stato un problema nella conferma mail, verifica che il link sia valido o contatta l'assistenza.")
    }
  }, [token, userId])

  useEffect(() => {
    confirmSignup()
  }, [confirmSignup])

  return (
    <Wrapper justifyContent="center" minH="100vh" py="30px">
      <Flex direction="column">
        <Box mb="30px" textAlign="center">
          <Heading mb={4}>Benvenuto in Sbam!</Heading>
          <Text fontWeight="600">Conferma account</Text>
        </Box>
      </Flex>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" maxWidth={800} height="100%" pb="40px">
        {message && (
          <Text alignSelf="flex-start" fontSize="14px" mb="24px">
            {message}
          </Text>
        )}
      </Flex>
      <Box textAlign="center">
        <Text>
          <RouterLink to="/">Torna al login</RouterLink>
        </Text>
      </Box>
    </Wrapper>
  )
}
