import { Capacitor } from '@capacitor/core'
import { Text } from '@chakra-ui/react'
import React from 'react'
import packageJson from '../../../package.json'

const platform = Capacitor.getPlatform()

const AppVersion = () => {
  return (
    <Text fontSize="0.7rem" fontWeight="500" textAlign="center" textColor="white">
      Sbam! x {platform} V.{packageJson.version}
    </Text>
  )
}

export default AppVersion
