import { Box, Flex } from '@chakra-ui/react'
import { Spinner } from '@chakra-ui/spinner'
import { Message, Paginator } from '@twilio/conversations'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import useAmIAdminOrSupport from 'hooks/use-am-i-admin-or-support'
import useOnLongPress from 'hooks/use-on-long-press'
import React, { useState } from 'react'
import ChatBox from './chat-box'
import ChatDrawer from './chat-drawer'
import ChatHeader from './chat-header'
import ChatInputText from './chat-input-text'
import ChatContextProvider, { useChatContext } from './context/chat-context'
import MessageContextProvider, { useMessageContext } from './context/message-context'
import useGetConversationQueryBySid from './hooks/use-get-conversation-query'
import useGetToNextPageMutation from './hooks/use-get-to-next-page-mutation'
import useGetUserFromMessageQuery from './hooks/use-get-user-from-message'
import useOnMessageAdded from './hooks/use-on-message-added'
import ReplyBox from './reply-box'
import { useParams } from 'react-router-dom'

const ChatScreen = () => {
  return (
    <ChatContextProvider>
      <ChatScreenInner />
    </ChatContextProvider>
  )
}

const ChatScreenInner = () => {
  const { sid } = useParams()

  const [messages, setMessages] = useState<Message[]>([])
  const [paginator, setPaginator] = useState<Paginator<Message>>()

  const { abortOnLongPressWithCustomEvents } = useOnLongPress({ namespace: 'chat' })

  const { chatRef } = useChatContext()

  const { isIdle, isLoading, isError } = useGetConversationQueryBySid(sid, {
    onSuccess: ({ paginator, messages }) => {
      setPaginator(paginator)
      setMessages(messages.reverse())
    },
  })

  const getNextPage = useGetToNextPageMutation({
    onSuccess: ({ paginator, messages }) => {
      setMessages(prev => [...prev, ...messages.reverse()])
      setPaginator(paginator)
    },
  })

  useOnMessageAdded(setMessages)

  const handleOnScroll = event => {
    abortOnLongPressWithCustomEvents()

    const scrollPosition = event.currentTarget.scrollTop + event.currentTarget.scrollHeight - event.currentTarget.clientHeight

    if (scrollPosition < 100 && paginator?.hasPrevPage && !getNextPage.isLoading) {
      getNextPage.mutate()
    }
  }

  if (isIdle) {
    return <></>
  }

  if (isLoading) {
    return (
      <Flex className="chat-list-screen" height="calc(100vh - 125px)" direction="row" justifyContent="center" alignItems="center">
        <Spinner size="sm" mr="10px" /> Caricamento chat
      </Flex>
    )
  }

  if (isError) {
    return (
      <Flex className="chat-list-screen" height="calc(100vh - 125px)" direction="column" justifyContent="center" alignItems="center">
        <div>Qualcosa è andato storto</div>

        <div>Riprova fra qualche istante</div>
      </Flex>
    )
  }

  return (
    <Wrapper h="100%" pos="relative" px="0">
      <ChatHeader />

      <Flex
        ref={chatRef}
        className="chat-screen"
        direction="column-reverse"
        alignContent="flex-end"
        width="100%"
        flex={1}
        pt="26px"
        px=".5rem"
        gap=".25rem"
        overflowY="scroll"
        onScroll={handleOnScroll}>
        {messages.map((msg, index) => (
          <MessageContextProvider key={msg.sid}>
            <ChatBoxWrapper msg={msg} messages={messages} index={index} />
          </MessageContextProvider>
        ))}
      </Flex>

      <ChatDrawer />

      <Flex direction="column">
        <ReplyBox />

        <Box h="75px"></Box>

        <ChatInputText />
      </Flex>
    </Wrapper>
  )
}

function ChatBoxWrapper({ msg, index, messages }) {
  const { hideMessage } = useMessageContext()

  const { data: user, isLoading } = useGetUserFromMessageQuery(msg)
  const { data: nextMessageUser } = useGetUserFromMessageQuery(messages[index + 1])

  const amIAdminOrSupport = useAmIAdminOrSupport()
  const isDeleted = msg?.attributes?.deleted

  const isSameAuthorAsNext = index !== messages.length - 1 && nextMessageUser?.identity === user?.identity

  // UX: we have to wait for this info because is needed e.g. to calculate isMine
  if (isLoading) {
    return <></>
  }

  // RETROCOMPATIBILITY ROBUSTNESS: needed to handle old messages that have DTOs that breaks the page
  if (hideMessage) {
    return <></>
  }

  // FEATURE: only admin or support users can see that a message was deleted
  if (!amIAdminOrSupport && isDeleted) {
    return <></>
  }

  return <ChatBox msg={msg} isSameAuthorAsNext={isSameAuthorAsNext} />
}

export default ChatScreen
