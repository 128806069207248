import { useMutation } from 'react-query'
import Api from 'services/api/api.service'
import { Position } from 'typings/database'

export const useSetLastPositionMutation = (options = {}) => {
  return useMutation(
    `set-last-position`,
    async (position: Position) => {
      await Api.editLastPosition({ data: { lat: position.lat, lng: position.lon } })
    },
    {
      ...options,
    },
  )
}
