import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import useQueryParams from './use-query-params';
import { useEffect } from 'react';

type UTMStore = {
    utmParams: {
        utm_id: string;
        utm_source: string;
        utm_medium: string;
        utm_campaign: string;
        utm_term: string;
        utm_content: string;
    };
    updateUtmParams: (queryParams: URLSearchParams) => void;
};

const useUtmStore = create(persist<UTMStore>(
    (set, get) => ({
        utmParams: {
            utm_id: '',
            utm_source: '',
            utm_medium: '',
            utm_campaign: '',
            utm_term: '',
            utm_content: ''
        },
        updateUtmParams: (queryParams) => {
            const currentUtmParams = get().utmParams;
            const newUtmParams = {
                utm_id: queryParams.get('utm_id') ?? currentUtmParams.utm_id,
                utm_source: queryParams.get('utm_source') ?? currentUtmParams.utm_source,
                utm_medium: queryParams.get('utm_medium') ?? currentUtmParams.utm_medium,
                utm_campaign: queryParams.get('utm_campaign') ?? currentUtmParams.utm_campaign,
                utm_term: queryParams.get('utm_term') ?? currentUtmParams.utm_term,
                utm_content: queryParams.get('utm_content') ?? currentUtmParams.utm_content
            };
            set({ utmParams: newUtmParams });
        }
    }),
    {
        name: 'utm-storage',
    }
));

const useUTMTracking = () => {
    const queryParams = useQueryParams();
    const updateUtmParams = useUtmStore(state => state.updateUtmParams);

    useEffect(() => {
        updateUtmParams(queryParams);
    }, [queryParams, updateUtmParams]);

    return useUtmStore(state => state.utmParams);
};

export default useUTMTracking;
