import { useContext, useEffect, useState } from 'react'

import * as Sentry from '@sentry/capacitor'
import { AppContext } from 'app.context'
import { useToast } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { Conversation, Paginator } from '@twilio/conversations'

export type ChatAttributes = { data?: { id: string; coverUrl: string | null }; type?: string }

export const byCreationDate = (a: Conversation, b: Conversation) => {
  if (a.lastMessage?.dateCreated && b.lastMessage?.dateCreated) {
    return b.lastMessage?.dateCreated.getTime() - a.lastMessage?.dateCreated.getTime()
  }

  if (a.lastMessage?.dateCreated) {
    return -1
  }

  if (b.lastMessage?.dateCreated) {
    return 1
  }

  return 0
}

export const useGetFirstConversationPage = () => {
  const { client: twilioClient } = useContext(AppContext)
  const toast = useToast()

  return useQuery(
    'optimized-conversations',
    async () => {
      if (twilioClient) {
        return await twilioClient.getSubscribedConversations()
      }
    },
    {
      enabled: !!twilioClient,
      onError: error => {
        toast({
          title: 'Impossibile ottenere la lista di chat',
          position: 'bottom',
          status: 'error',
          duration: 2500,
          isClosable: true,
        })
        Sentry.captureException(error)
      },
    },
  )
}

export const useGetOptimizedRestConversations = (startingPage: Paginator<Conversation> | undefined) => {
  const { client: twilioClient } = useContext(AppContext)
  const toast = useToast()

  return useQuery(
    'optimized-rest-conversations',
    async () => {
      if (twilioClient && startingPage) {
        const fn = async function getAllConversations(current: Paginator<Conversation>, acc: Conversation[]) {
          if (!current.hasNextPage && !current.hasPrevPage) {
            return acc
          }

          if (!current.hasNextPage) {
            return acc.concat(current.items)
          }

          const next = await current.nextPage()

          if (!current.hasPrevPage) {
            return getAllConversations(next, acc)
          }

          return getAllConversations(next, acc.concat(current.items))
        }

        return await fn(startingPage, [])
      }
    },
    {
      enabled: !!twilioClient && !!startingPage,
      onError: error => {
        toast({
          title: 'Impossibile ottenere la lista di chat',
          position: 'bottom',
          status: 'error',
          duration: 2500,
          isClosable: true,
        })
        Sentry.captureException(error)
      },
    },
  )
}

export const useGetChatAttribute = (conversation: Conversation) => {
  const { client: twilioClient } = useContext(AppContext)

  return useQuery(
    `conversations-attributes-${conversation.sid}`,
    async () => {
      if (twilioClient) {
        return (await conversation.getAttributes()) as ChatAttributes
      }
    },
    {
      enabled: !!twilioClient,
    },
  )
}

export const useGetLastMessage = (conversation: Conversation) => {
  const { client: twilioClient } = useContext(AppContext)

  return useQuery(
    `conversations-last-message-${conversation.sid}`,
    async () => {
      if (twilioClient) {
        const messages = await conversation.getMessages(1)
        return messages.items.pop()
      }
    },
    {
      enabled: !!twilioClient,
    },
  )
}

export const useUnreadCounters = conversation => {
  const [unreadCounters, setUnreadCounters] = useState({})

  useEffect(() => {
    conversation.getUnreadMessagesCount().then(unreadCount => {
      setUnreadCounters(prev => ({ ...prev, [conversation.sid]: unreadCount }))
    })

    conversation.on('messageAdded', async () => {
      const unreadCount = await conversation.getUnreadMessagesCount()
      setUnreadCounters(prev => ({ ...prev, [conversation.sid]: unreadCount }))
    })
  }, [conversation, setUnreadCounters])

  return { unreadCounters }
}
