import { Box, Flex, Text } from '@chakra-ui/react'
import EventPreview from 'components/shared/event-preview/event-preview.component'
import { AttendeesIconNeg } from 'components/shared/icons/icons'
import moment from 'moment'
import React from 'react'
import { IoIosPin, IoLogoEuro, IoMdCalendar } from 'react-icons/io'
import { useQuery } from 'react-query'
import Api from 'services/api/api.service'
import { Category } from 'typings/database'

function EventPreviewMessage({ msg }) {
  const event = msg?.attributes?.event

  const title = event?.title
  const categoryId = event?.categoryId
  const startDate = event?.startDate
  const location = event?.location
  const cost = event?.cost
  const description = event?.description
  const maxParticipants = event?.limitations?.maxParticipants
  const minAge = event?.limitations?.minAge
  const maxAge = event?.limitations?.maxAge
  const maleParticipants = event?.limitations?.maleParticipants
  const femaleParticipants = event?.limitations?.femaleParticipants
  const otherParticipants = event?.limitations?.otherParticipants

  return (
    <Flex className="c-event-preview-message" direction="column" gap="1rem">
      <EventTitle title={title} />

      <EventImage {...event} />

      <EventCategory categoryId={categoryId} />

      <Box border=".1px solid black" />

      <Flex direction="column" gap=".25rem">
        <EventDate startDate={startDate} />

        <EventPosition location={location} />

        <EventCost cost={cost} />
      </Flex>
      <EventDescription description={description} />

      <Box border=".1px solid black" />

      <Flex direction="row" justifyContent="space-around">
        <EventAttendeesCount maxParticipants={maxParticipants} />

        <EventAgeRange minAge={minAge} maxAge={maxAge} />

        <EventAttendeesDistribution
          maleParticipants={maleParticipants}
          femaleParticipants={femaleParticipants}
          otherParticipants={otherParticipants}
        />
      </Flex>
    </Flex>
  )
}

function EventTitle({ title }) {
  return (
    <Text
      fontWeight="medium"
      fontSize="1.1rem"
      lineHeight="1.2"
      pr="5px"
      style={{
        display: '-webkit-box',
        maxWidth: '100%',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      }}>
      {title}
    </Text>
  )
}

function EventDate({ startDate }) {
  return (
    <Flex gap=".25rem" alignItems="center">
      <IoMdCalendar />

      <Text fontSize="14px" dangerouslySetInnerHTML={{ __html: moment(startDate).format('<b>D MMMM YYYY</b> [alle] <b>h:mm A z</b>') }}></Text>
    </Flex>
  )
}

function EventPosition({ location }) {
  const address = location?.details?.address

  if (!address) {
    return <></>
  }

  return (
    <Flex gap=".25rem" alignItems="center">
      <IoIosPin />

      <Text as="span" fontSize="14px">
        {address}
      </Text>
    </Flex>
  )
}

function EventImage({ categoryId }) {
  const coverUrl = `https://fra1.digitaloceanspaces.com/space.sbam/${categoryId}`

  return <EventPreview coverUrl={coverUrl} />
}

function EventCategory({ categoryId }) {
  const { isLoading, data } = useGetCategoriesQuery()
  const categories = data?.data

  const category = categories?.find(c => c._id === categoryId)
  const categoryName = category?.categoryType.name

  if (isLoading || !categoryName) {
    return <div />
  }

  return <Text fontWeight="semibold">{`#${categoryName}`}</Text>
}

function EventCost({ cost }) {
  if (!cost) {
    return <></>
  }

  return (
    <Flex gap=".25rem" alignItems="center">
      <IoLogoEuro />

      <Text fontSize="14px">{`${cost} - Pagamento fuori dall'app`}</Text>
    </Flex>
  )
}

function EventDescription({ description }) {
  if (!description) {
    return <></>
  }

  return (
    <Flex fontSize="14px" color="gray.500">
      {description}
    </Flex>
  )
}

const EventAttendeesCount = ({ maxParticipants }) => {
  if (!maxParticipants) {
    return <div />
  }

  return (
    <Flex gap=".25rem" alignItems="center">
      <Text fontWeight="semibold">{maxParticipants}</Text>
      <AttendeesIconNeg boxSize={6} mb="3px" mr="8px" />
    </Flex>
  )
}

const EventAgeRange = ({ maxAge, minAge }) => {
  if (!maxAge || !maxAge) {
    return <div />
  }

  return <Text fontWeight="semibold">{`${minAge}-${maxAge}Y`}</Text>
}

const EventAttendeesDistribution = ({ maleParticipants, femaleParticipants, otherParticipants }) => {
  const one = maleParticipants && `${maleParticipants}M`
  const two = femaleParticipants && `${femaleParticipants}F`
  const three = otherParticipants && `${otherParticipants}A`

  const label = [one, two, three].filter(Boolean).join('|')

  return <Text fontWeight="semibold">{label}</Text>
}

const useGetCategoriesQuery = () =>
  useQuery('categories', () => Api.categories()) as {
    error: String | any
    isLoading: boolean
    data: { data: Category[] }
  }

export default EventPreviewMessage
