import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import App from 'app'
import { ChakraProvider } from '@chakra-ui/react'
import { Capacitor } from '@capacitor/core'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from 'features'
import { customTheme } from 'theme'
import { QueryClientProvider } from 'react-query'

import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { defineCustomElements } from '@ionic/pwa-elements/loader'

import { ReactQueryDevtools } from 'react-query/devtools'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { queryClient } from 'query-client'

const container = document.getElementById('root')
if (!container) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(container)
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ChakraProvider theme={customTheme}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            <App />
          </QueryClientProvider>
        </ChakraProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
)

// Call the element loader after the app has been rendered the first time
defineCustomElements(window)
if (!Capacitor.isNativePlatform()) {
  // Initialize Firebase
  initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  })
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorker.register()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log)
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
