import type { ChatSession } from 'firebase/vertexai-preview'
import type { GeminiAnswer } from './types'
import { useQuery } from 'react-query'
import { getWelcomeMessage } from './gemini-messages'

const useGetWelcomeMessageFromGemini = (username: string, chat: ChatSession, options = {}) => {
  return useQuery(
    'welcome-message-from-gemini',
    async (): Promise<GeminiAnswer> => {
      const result = await chat.sendMessage(getWelcomeMessage(username))
      const response = await result.response
      const text = response.text()

      const parsed = text.replaceAll('json', '').replaceAll('`', '').replaceAll('´', '')
      console.debug(parsed)
      return JSON.parse(parsed)
    },
    {
      ...options,
    },
  )
}

export default useGetWelcomeMessageFromGemini
