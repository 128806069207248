import { Box, Button, Flex, InputGroup } from '@chakra-ui/react'
import { AppContext } from 'app.context'
import { I } from 'components/shared/input/input.style'
import React, { useContext, useState } from 'react'
import { PiPaperPlaneRightFill } from 'react-icons/pi'
import { getMessageForGemini } from './gemini-messages'
import { Factory } from './types'

function Footer({ eventJSON, prevQuestion, sendMessage, setMessages, yupErrors }) {
  return (
    <Flex direction="column">
      <Box h="75px"></Box>

      <ChatInputText eventJSON={eventJSON} prevQuestion={prevQuestion} yupErrors={yupErrors} sendMessage={sendMessage} setMessages={setMessages} />
    </Flex>
  )
}

function ChatInputText({ eventJSON, prevQuestion, sendMessage, setMessages, yupErrors }) {
  const { client: twilioClient } = useContext(AppContext)
  const identity = twilioClient?.user.identity

  const [value, setValue] = useState<string>('')
  const isSubmitDisabled = !value || !identity

  const handleOnInput = ({ currentTarget: { value } }) => {
    setValue(value)
  }

  const handleOnKeyDown = event => {
    if (event.key === 'Enter') {
      if (isSubmitDisabled) {
        return
      }

      setMessages(rest => rest.concat(Factory.textMessage(identity, event.target.value)))
      sendMessage.mutate(getMessageForGemini(prevQuestion, event.target.value, yupErrors, eventJSON))
      setValue('')
    }
  }

  const handleOnSendMessage = () => {
    if (isSubmitDisabled) {
      return
    }

    setMessages(rest => rest.concat(Factory.textMessage(identity, value)))
    sendMessage.mutate(getMessageForGemini(prevQuestion, value, yupErrors, eventJSON))
    setValue('')
  }

  return (
    <Flex alignItems="center" px="1rem" gap="1rem" my="10px" width="100%" position="absolute" left="0" bottom="0">
      <InputGroup>
        <I type="text" placeholder="Scrivi un messaggio" onInput={handleOnInput} onKeyDown={handleOnKeyDown} value={value} />
      </InputGroup>

      <Button isDisabled={isSubmitDisabled} variant="ghost">
        <Box color="brand.500" fontSize="1.5rem">
          <PiPaperPlaneRightFill onClick={handleOnSendMessage} />
        </Box>
      </Button>
    </Flex>
  )
}

export default Footer
