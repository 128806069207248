import React from 'react'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { ModalProps } from '@components/types'

export const BanInfoModal = (props: ModalProps) => {
  return (
    <Modal isCentered closeOnOverlayClick isOpen={props.isOpen} onClose={props.onClose} size="md">
      <ModalOverlay />
      <ModalContent borderRadius="15px" pb="20px">
        <ModalHeader>Utente bannato!</ModalHeader>
        <ModalCloseButton borderRadius={'15px'} />
        <ModalBody>
          <Text>
            Questo utente è stato bannato per comportamenti scorretti ripetuti come non essersi presentato a un evento a cui partecipava o aver
            infranto il regolamento.
            <br />
            <br />
            Essere bannati impedisce di partecipare e organizzare e limita le funzioni dell’app.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
