import { AspectRatio, Button, Flex, Spacer, Text, useDisclosure } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import UnblockChatModal from './unblock-chat-modal'

const EventCover = props => {
  const { isOpen: isUnblockChatModalOpen, onOpen: onUnblockChatModalOpen, onClose: onUnblockChatModalClose } = useDisclosure()

  const goToChat = () => {
    if (props.event.chatSid && (!!props.attendance || props.isOrganizer)) {
      props.navigate(`/event/${props.event.chatSid}/chat`)
    } else if (props.event.chatSid && !props.attendance && !props.isOrganizer && moment(props.event.startDate).isAfter()) {
      onUnblockChatModalOpen()
    }
  }

  return (
    <>
      <AspectRatio ratio={375 / 240} bg={`url('${props.event.coverUrl}') center`} bgSize="cover" ml="-5" mr="-5" height="200px" mb="5">
        <Flex direction="column" bgColor="rgba(1, 25, 54, 0.7)" onClick={goToChat} pr="5" pl="5">
          <Spacer />
          <Text fontSize="20px" fontWeight="600" fontFamily="Poppins" textColor="white" lineHeight="30px">
            {props.event.title}
          </Text>
          <Spacer />
          <Spacer />
          {props.event.chatSid && (props.attendance || moment(props.event.startDate).isAfter()) && (
            <Button
              variant="outline_white_text"
              width="165px"
              height="40px"
              borderRadius="15px"
              fontSize="15px"
              fontWeight="600"
              lineHeight="22.5px"
              onClick={goToChat}>
              Chat evento
            </Button>
          )}
          <Spacer />
        </Flex>
      </AspectRatio>
      <UnblockChatModal
        isOpen={isUnblockChatModalOpen}
        onClose={onUnblockChatModalClose}
        attendanceCost={props.event.attendanceCost}
        createAttendance={props.createAttendance}
      />
    </>
  )
}

export default EventCover
