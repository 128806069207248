import React from 'react'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { ModalProps } from '@components/types'

export const AdmonitionInfoModal = (props: ModalProps) => {
  return (
    <Modal isCentered closeOnOverlayClick isOpen={props.isOpen} onClose={props.onClose} size="sm">
      <ModalOverlay />
      <ModalContent borderRadius="15px" pb="20px">
        <ModalHeader>Utente ammonito!</ModalHeader>
        <ModalCloseButton borderRadius={'15px'} />
        <ModalBody>
          <Text>
            Questo utente è stato ammonito per comportamenti scorretti come non essersi presentato a un evento a cui partecipava o aver infranto il
            regolamento.
            <br />
            <br />
            Essere ammoniti aumenta il costo in saette per partecipare e organizzare eventi e limita le funzioni dell’app.
            <br />
            <br />
            Ricevendo 2 ammonizioni si viene bannati per un determinato periodo di tempo.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
