import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import useGetUserByIdentityFromPartecipants from '../../hooks/use-get-user-by-identity-from-partecipants'
import { PollOption } from '../../hooks/use-send-poll-mutation'
import MessageAvatar from '../../message-avatar'

function PollHeader({ option }: { option: PollOption }) {
  const value = option.value
  const votes = option.votes

  return (
    <Flex className="poll-header" direction="row" justifyContent="space-between" alignItems="flex-start">
      <Box fontWeight="600" paddingLeft=".75rem">
        {value}
      </Box>

      <VotesWithAvatar votes={votes} />
    </Flex>
  )
}

function VotesWithAvatar({ votes }) {
  const votesCount = votes.length
  const { data: firstUser } = useGetUserByIdentityFromPartecipants(votes[0]?.identity)
  const { data: secondUser } = useGetUserByIdentityFromPartecipants(votes[1]?.identity)

  if (votesCount === 0) {
    return <Box width="10px">{votesCount}</Box>
  }

  if (votesCount === 1) {
    return (
      <Flex gap=".5rem">
        <MessageAvatar user={firstUser} />

        <Box width="10px">{votesCount}</Box>
      </Flex>
    )
  }

  return (
    <Flex gap=".5rem">
      <Flex position="relative" width="20px" gap=".5rem">
        <Box position="absolute" top={0} left={0}>
          <MessageAvatar user={firstUser} />
        </Box>

        <Box position="absolute" top={0} left={-2.5}>
          <MessageAvatar user={secondUser} />
        </Box>
      </Flex>

      <Box width="10px">{votesCount}</Box>
    </Flex>
  )
}

export default PollHeader
