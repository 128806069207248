import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Checkbox,
  CloseButton,
  Flex,
  HStack,
  Progress,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import EventCreatedModal from 'components/shared/event-created-modal/event-created-modal'
import { SbamCoinArrow } from 'components/shared/icons/icons'
import InputField from 'components/shared/input/input.component'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { genderMap } from 'costants/genders'
import { useAppDispatch } from 'features'
import { refreshUser } from 'features/auth/auth.actions'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Api from 'services/api/api.service'
import { Event } from 'typings/database.d'
import resizeFile from 'utils/resize-file'
import CategoryInput from './category-input'
import MaxParticipantsInput from './max-participants-input'
import { useUnsplashImage } from './use-unsplash-image'
import { UnsplashCredits } from './unsplash-credits'

export const defaultLocation = {
  description: '',
  details: { address: '' },
  fakePosition: { type: 'Point', coordinates: [] },
  placeId: '',
  realPosition: { type: 'Point', coordinates: [] },
}

const CreateEventScreen = () => {
  const { id } = useParams<{ id?: string }>()
  const isEdit = !!id
  const startingProgressPercent = 33.33333333

  const {
    isFetching,
    error: eventError,
    data: defaultEvent,
  } = useQuery<
    any,
    any,
    {
      retry: false
      error: String
      isLoading: boolean
      data: Event
      refetch: () => {}
    }
  >('getEvent' + id, () => Api.event(id as string), { enabled: isEdit })
  const defaultValues = useMemo(() => {
    if (isEdit) {
      if (isFetching || !defaultEvent?.data) return undefined
      return defaultEvent.data
    }
    if (process.env?.NODE_ENV === 'development') {
      return {
        title: 'Test ' + Math.random() * 100,
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus amet deleniti sint natus error cupiditate reprehenderit ea quibusdam libero, delectus exercitationem nam dolores commodi autem impedit magni dicta corrupti perferendis.',
        limitations: {
          maxAge: 80,
          minAge: 20,
          maxParticipants: 5,
          maleParticipants: 2,
          femaleParticipants: 3,
          otherParticipants: 0,
        },
        cost: 15,
        startDate: undefined,
        startDateTime: moment().add(5, 'minutes').format('YYYY-MM-DD HH:mm'),
        maxParticipants: 100,
        categoryId: undefined,
        location: defaultLocation,
        cover: undefined,
      }
    }
    return {
      title: '',
      description: '',
      limitations: {
        maxAge: 99,
        minAge: 18,
        maleParticipants: 2,
        femaleParticipants: 3,
        otherParticipants: 0,
        maxParticipants: 5,
      },
      cost: undefined,
      startDateTime: moment().add(5, 'minutes').format('YYYY-MM-DD HH:mm'),
      categoryId: undefined,
      cover: undefined,
      location: defaultLocation,
    }
  }, [defaultEvent])
  const [postImage, setPostImage] = useState<any>('')
  const [canEdit, setCanEdit] = useState<boolean>(false)
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPageNum, setCurrentPageNum] = useState(1)
  const [progressPercent, setProgressPercent] = useState(startingProgressPercent)
  const [includeInfo, setIncludeInfo] = useState(false)
  const [includeAge, setIncludeAge] = useState(false)
  const [includeRate, setIncludeRate] = useState(false)
  const [includeGender, setIncludeGender] = useState(false)
  const [showCover, setShowCover] = useState(false)
  const [previewString, setPreviewString] = useState('')
  const { getRandomImage, unsplashPhoto } = useUnsplashImage()

  const [participationCostColor, setParticipationCostColor] = useState('#737377')
  const [detailsColor, setDetailsColor] = useState('#737377')
  const [genderColor, setGenderColor] = useState('#737377')
  const [ageColor, setAgeColor] = useState('#737377')
  const [coverColor, setCoverColor] = useState('#737377')

  const { isOpen, onOpen, onClose } = useDisclosure()

  const navigate = useNavigate()

  const [createdEventId, setCreatedEventId] = useState('')

  const methods = useForm({
    defaultValues,
    mode: 'onSubmit',
  })

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = methods

  const nextPage = async () => {
    if (currentPageNum + 1 <= 3) {
      if (
        (currentPageNum === 1 && (await trigger(['categoryId', 'title'], { shouldFocus: true }))) ||
        (currentPageNum === 2 && (await trigger(['location', 'startDateTime'], { shouldFocus: true }))) ||
        (currentPageNum === 3 && (await trigger(['limitations.maxParticipants'], { shouldFocus: true })))
      ) {
        setCurrentPageNum(currentPageNum + 1)
        setProgressPercent(progressPercent + startingProgressPercent)
      } else {
        showError(errors)
      }
    }
  }

  const previousPage = async () => {
    if (currentPageNum - 1 >= 1) {
      setCurrentPageNum(currentPageNum - 1)
      setProgressPercent(progressPercent - startingProgressPercent)
    }
  }

  const checkInfo = () => {
    if (includeInfo) {
      setDetailsColor('#737377')
    } else {
      setDetailsColor('black')
    }
    setIncludeInfo(!includeInfo)
  }

  const checkGender = () => {
    if (includeGender) {
      setGenderColor('#737377')
    } else {
      setGenderColor('black')
    }
    setIncludeGender(!includeGender)
    setValue('limitations.genderBalanceActive', !includeGender)
  }

  const checkRate = () => {
    if (includeRate) {
      setParticipationCostColor('#737377')
    } else {
      setParticipationCostColor('black')
    }
    setIncludeRate(!includeRate)
  }

  const checkAge = () => {
    if (includeAge) {
      setAgeColor('#737377')
    } else {
      setAgeColor('black')
    }
    setIncludeAge(!includeAge)
  }

  const onTagClick = async (categoryId, categoryName) => {
    if (postImage) return

    setShowCover(false)
    setValue('cover', undefined)
    setPostImage('')

    const photoUrl = await getRandomImage(categoryName)

    setPreviewString(photoUrl || `https://fra1.digitaloceanspaces.com/space.sbam/${categoryId}`)

    setShowCover(true)
  }

  const onSetCover = url => {
    setShowCover(false)
    setPreviewString(url)
    setShowCover(true)
    setCoverColor('black')
  }

  useEffect(() => {
    if (eventError && typeof eventError == 'string') {
      toast({
        title: eventError,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    } else if (eventError && typeof eventError?.message == 'string') {
      toast({
        title: eventError.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    }
  }, [eventError, toast])

  const dispatch = useAppDispatch()

  const onSubmit = async (data: any) => {
    if (isEdit) return editEvent(data)
    createEvent(data)
  }
  const editEvent = async (data: any) => {
    setIsLoading(true)
    const body = new FormData()
    body.set('title', data.title)
    body.set('description', data.description)
    try {
      const { data: response } = await Api.editEvent(id + '', body)
      toast({
        title: 'Evento modificato con successo',
        status: 'success',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      })
      dispatch<any>(refreshUser())
      navigate('/event/' + response, { replace: true })
    } catch (e: any) {
      toast({
        title: e?.response?.data?.message || 'Error',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      })
      setIsLoading(false)
    }
  }

  const createEvent = async (data: any) => {
    if (isLoading) return
    setIsLoading(true)
    let response

    try {
      await Api.quoteEvent(data.limitations.maxParticipants)

      if (Number(totalPartecipationDifference) !== 0 && includeGender) {
        setIsLoading(false)
        return toast({
          title: 'Il numero di partecipanti per genere non è corretto',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        })
      }

      const body = new FormData()
      body.set('title', data.title)
      const location = {
        realPosition: data.location.realPosition,
        fakePosition: data.location.fakePosition,
        province: data.location.province,
        details: data.location.details,
      }
      body.set('location', JSON.stringify(location))
      if (postImage) {
        body.set('cover', postImage)
      } else if (unsplashPhoto?.url) {
        body.set('coverUrl', unsplashPhoto?.url)
      }
      body.set('limitations', JSON.stringify(data.limitations))
      body.set('categoryId', data.categoryId)
      if (includeRate && data.cost) {
        body.set('cost', data.cost)
      }
      body.set('startDate', new Date(data.startDateTime).toISOString())
      if (includeInfo && data.description) {
        body.set('description', data.description)
      }
      const { data: dataResponse } = await Api.createEvent(body)
      response = dataResponse
      dispatch<any>(refreshUser())
    } catch (e: any) {
      setIsLoading(false)
      return toast({
        title: e?.response?.data?.message || 'Error',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      })
    }
    try {
      setCreatedEventId(response)
      await Api.createAttendance(response)
      onOpen()
    } catch (e: any) {
      toast({
        title: e?.response?.data?.message || 'Error',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      })
      setIsLoading(false)
    }
  }
  useEffect(() => {
    showError(errors)
  }, [errors])

  useEffect(() => {
    reset(defaultValues)
    if (isEdit && defaultValues) {
      setCanEdit(true)
    }
  }, [defaultValues, reset])

  const showError = e => {
    if (typeof e === 'string') {
      return toast({
        title: e,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      })
    }
    Object.values(e).forEach(er => {
      if (typeof er === 'string') {
        toast({
          title: er,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        })
      } else {
        if (typeof er === 'object' && er) {
          if ((er as any)?.message) {
            toast({
              title: (er as any).message,
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'bottom',
            })
          } else {
            return Object.values(er).forEach(err => {
              if (err.message) {
                toast({
                  title: (err as any).message,
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                  position: 'bottom',
                })
              } else {
                showError(err)
              }
            })
          }
        } else {
          toast({
            title: (er as any).message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'bottom',
          })
        }
      }
    })
  }

  const categoryId = watch('categoryId')
  const location = watch('location')
  const limitations = watch('limitations')
  const totalPartecipationGenderLimits = includeGender && genderMap.reduce((acc, [s, l, c]) => acc + limitations[s + 'Participants'], 0)
  const totalPartecipationDifference = includeGender && limitations.maxParticipants - Number(totalPartecipationGenderLimits)

  const updateGendersDefault = () => {
    setValue(
      'limitations.maleParticipants',
      limitations.maxParticipants % 2 === 0 ? limitations.maxParticipants / 2 : Math.trunc(limitations.maxParticipants / 2),
    )
    setValue(
      'limitations.femaleParticipants',
      limitations.maxParticipants % 2 === 0 ? limitations.maxParticipants / 2 : Math.trunc(limitations.maxParticipants / 2) + 1,
    )
  }

  const title = watch('title')
  const description = watch('description')
  const startDateTimeWatch = watch('startDateTime')

  return (
    <>
      <Wrapper overflowY="auto" height="100%">
        <FormProvider {...methods}>
          <form style={{ display: 'contents', height: '100%' }} onSubmit={handleSubmit(onSubmit, showError)}>
            {!isEdit && (
              <>
                {currentPageNum === 1 && (
                  <Flex direction="column" height="100%">
                    <HStack mb="5">
                      <Box pt="20px" pb="25px" fontSize="22px" fontWeight="700">
                        Che vuoi fare?
                      </Box>
                      <Spacer />
                      <CloseButton
                        size="lg"
                        onClick={() => {
                          navigate(-1)
                        }}
                      />
                    </HStack>
                    <Box flex="1" overflow="auto" pr="2px" pl="2px">
                      <Box mb="30px" position="relative">
                        <InputField
                          label="Racconta in breve"
                          required
                          placeholder='Ad es. "Aperitivo ignorante in centro"'
                          {...register('title', {
                            required: "Devi inserire un titolo per l'evento",
                            maxLength: { message: 'Il titolo non può superare i 40 caratteri', value: 40 },
                          })}
                          maxLength="40"
                        />
                        <Text fontSize="13" position="absolute" right="0">
                          {title.length} / 40
                        </Text>
                      </Box>
                      <Box mb="30px">
                        <Text pb="5px" fontWeight="semibold" color="black" id="category-label" position="relative">
                          Tagga la tua idea{' '}
                          <Text as="span" color="brand.500" ml="4px" mt="-2px" fontSize="26px" position="absolute">
                            *
                          </Text>
                        </Text>
                        <CategoryInput
                          categoryId={categoryId}
                          setValue={setValue}
                          register={register}
                          fieldName="categoryId"
                          onTagClick={onTagClick}
                        />
                      </Box>

                      {showCover && (
                        <Box>
                          <Text pb="5px" fontWeight="semibold" color={coverColor} id="category-label" position="relative">
                            Scegli una copertina
                          </Text>
                          <InputField
                            placeholder="Dillo con un'immagine"
                            type="file"
                            {...register('cover')}
                            onFileSelected={async newFile => {
                              const file = await resizeFile(newFile)
                              setPostImage(file)
                              setValue('cover', newFile)
                            }}
                            previewstring={previewString}
                            onsetcover={onSetCover}
                          />
                        </Box>
                      )}
                      {!postImage && unsplashPhoto && <UnsplashCredits unsplashPhoto={unsplashPhoto} />}
                    </Box>
                    <HStack mt="5" mb="5" flexDirection="row-reverse">
                      <Button alignSelf="flex-end" variant="solid" onClick={nextPage}>
                        Avanti
                      </Button>
                    </HStack>
                  </Flex>
                )}
                {currentPageNum === 2 && (
                  <Flex direction="column" height="100%">
                    <HStack mb="5">
                      <Box pt="20px" pb="25px" fontSize="22px" fontWeight="700">
                        Cosa bisogna sapere?
                      </Box>
                      <Spacer />
                      <CloseButton
                        size="lg"
                        onClick={() => {
                          navigate(-1)
                        }}
                      />
                    </HStack>
                    <Box flex="1" overflow="auto" pr="2px" pl="2px">
                      <Box mb="30px">
                        <InputField
                          type="location"
                          {...register('location', {
                            required: false,
                            validate: v =>
                              (v && !!v.placeId && !!v.details && !!v.fakePosition && !!v.realPosition && !!v.province) ||
                              'Nessuna posizione specificata',
                          })}
                          onChange={v => setValue('location', v)}
                          value={location}
                          required
                          label="Punto di ritrovo"
                          placeholder="Indica l'indirizzo"
                        />
                      </Box>
                      <Box mb="40px">
                        <InputField
                          required
                          label="Data e ora inizio"
                          min={moment().format('YYYY-MM-DD HH:mm')}
                          defaultValue={startDateTimeWatch}
                          type="datetime-local"
                          {...register('startDateTime', {
                            required: 'Devi specificare una data ed un orario di inizio',
                            validate: v =>
                              moment(v).isSameOrAfter(moment()) || `Data ed ora di inizio devono partire da ${moment().format('YYYY-MM-DD HH:mm')}`,
                          })}
                          onChange={({ target: { value } }) => {
                            setValue('startDateTime', value)
                          }}
                        />
                      </Box>
                      <Box mb="30px">
                        <Box mb="5px">
                          <Checkbox isChecked={includeRate} size="md" colorScheme="brand" onChange={checkRate}>
                            <Text color={participationCostColor} fontWeight="600">
                              Costo di partecipazione
                            </Text>
                          </Checkbox>
                        </Box>
                        {includeRate && (
                          <Box borderRadius={'15px'}>
                            <InputField
                              min="0"
                              max="999"
                              type="currency"
                              placeholder="Inserisci il prezzo richiesto"
                              {...register('cost', {
                                required: 'Devi specificare un costo di partecipazione',
                                validate: v => (v >= 0 && v <= 999) || 'Il costo di partecipazione deve essere tra 0 e 999',
                              })}
                            />
                          </Box>
                        )}
                      </Box>
                      <Box mb="5px">
                        <Checkbox isChecked={includeInfo} size="md" colorScheme="brand" onChange={checkInfo}>
                          <Text color={detailsColor} fontWeight="600">
                            Altri dettagli utili
                          </Text>
                        </Checkbox>
                      </Box>
                      {includeInfo && (
                        <Box>
                          <InputField
                            type="textarea"
                            placeholder="Ad es. 'C'è la formula apericena, il tavolo prenotato è nella sala principale, per il resto ci si sente in chat di gruppo'"
                            {...register('description', {
                              maxLength: { message: 'La descrizione non può superare i 1000 caratteri', value: 1000 },
                              required: 'Devi specificare dei dettagli aggiuntivi',
                            })}
                            maxLength="1000"
                            height="200px"
                          />
                        </Box>
                      )}
                    </Box>
                    <Flex mt="5" mb="5">
                      <ChevronLeftIcon
                        boxSize="2em"
                        aria-label="Back"
                        fontSize="20px"
                        tabIndex={0}
                        _hover={{ color: '#ff7436' }}
                        _focus={{ color: 'red' }}
                        onClick={previousPage}
                        focusable="true"
                        onKeyDown={e => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            previousPage()
                          }
                        }}
                      />
                      <Spacer />
                      <Button alignSelf="flex-end" variant="solid" onClick={nextPage}>
                        Avanti
                      </Button>
                    </Flex>
                  </Flex>
                )}
                {currentPageNum === 3 && (
                  <Flex direction="column" height="100%">
                    <HStack mb="5">
                      <Box pt="20px" pb="25px" fontSize="22px" fontWeight="700">
                        Chi può partecipare?
                      </Box>
                      <Spacer />
                      <CloseButton
                        size="lg"
                        onClick={() => {
                          navigate(-1)
                        }}
                      />
                    </HStack>
                    <Box flex="1" overflowY="auto" overflowX="hidden" pr="2px" pl="2px">
                      <Text pb="5px" fontWeight="semibold" color="black" id="category-label" position="relative">
                        Posti totali disponibili{' '}
                        <Text as="span" color="brand.500" ml="4px" mt="-2px" fontSize="26px" position="absolute">
                          *
                        </Text>
                      </Text>
                      <Box mb="45px">
                        <MaxParticipantsInput
                          aria-labelledby="entrants-label"
                          maxParticipants={[5, 10, 15, 20, 25].includes(limitations.maxParticipants) ? limitations.maxParticipants : 'Altro'}
                          fieldName="limitations.maxParticipants"
                          setValue={setValue}
                          register={register}
                          updateGendersDefault={updateGendersDefault}
                          registerOptions={{
                            required: 'Devi inserire il numero di partecipanti',
                            validate: {
                              min: v => (v && v >= 2) || "Devi avere almeno 2 partecipanti all'evento",
                              genderBalance: () =>
                                !includeGender ||
                                Number(totalPartecipationDifference) === 0 ||
                                'Il numero di partecipanti non coincide con la distribuzione di genere',
                            },
                          }}
                        />
                      </Box>
                      <Box mb="45px">
                        <Box mb="10px">
                          <Checkbox isChecked={includeGender} size="md" onChange={checkGender} colorScheme="brand">
                            <Text color={genderColor} fontWeight="600">
                              Partecipanti per genere
                            </Text>
                          </Checkbox>
                        </Box>
                        {includeGender && (
                          <Box minW="80%" mt="15px" border="1px solid #EFEFEF" borderRadius={'15px'} p="15px">
                            {Number(totalPartecipationDifference) !== 0 && (
                              <Text pt="5px" color="#2b2b28" mb="5">
                                {Number(totalPartecipationDifference) > 0 && `Ancora ${Number(totalPartecipationDifference)} da assegnare`}
                                {Number(totalPartecipationDifference) < 0 &&
                                  `Hai assegnato ${-Number(totalPartecipationDifference)} partecipant${
                                    -Number(totalPartecipationDifference) > 1 ? 'i' : 'e'
                                  } in più`}
                              </Text>
                            )}
                            <Flex mb="5">
                              <Spacer />
                              {genderMap.map(([slug, label, color], i) => {
                                return (
                                  <>
                                    <HStack alignItems="center" key={i} spacing="0.2rem">
                                      <InputField
                                        type="number"
                                        max={limitations.maxParticipants}
                                        value={limitations[`${slug}Participants`]}
                                        w="2.8rem"
                                        onChange={e => {
                                          setValue(`limitations.${slug}Participants`, +e.target.value)
                                        }}
                                        margin="0"
                                        min="0"
                                        buttonstyle="LittleI"
                                      />
                                      <Text margin="0" color={color}>
                                        {label}
                                      </Text>
                                    </HStack>
                                    <Spacer />
                                  </>
                                )
                              })}
                            </Flex>
                            <Box>
                              <Flex direction="row" mt="10px" h="0.3rem" w="100%" bg="red" borderRadius="15px" overflow="hidden">
                                {genderMap.map(([slug, _, color], i) => (
                                  <Box
                                    key={i}
                                    w={(limitations[slug + 'Participants'] / limitations.maxParticipants) * 100 + '%'}
                                    h="full"
                                    bg={color}
                                  />
                                ))}
                              </Flex>
                            </Box>
                          </Box>
                        )}
                      </Box>
                      <Box mb="30px">
                        <Box mb="10px">
                          <Checkbox isChecked={includeAge} size="md" colorScheme="brand" onChange={checkAge}>
                            <Text color={ageColor} fontWeight="600">
                              Età consigliata
                            </Text>
                          </Checkbox>
                        </Box>
                        {includeAge && (
                          <Box mb="17px" p="15px" border="1px solid #EFEFEF" borderRadius={'15px'}>
                            <Flex>
                              <HStack>
                                <InputField
                                  width="2.5rem"
                                  type="number"
                                  min={18}
                                  max={limitations.maxAge}
                                  value={limitations.minAge}
                                  onChange={e => setValue('limitations.minAge', +e.target.value || 18)}
                                  buttonstyle="LittleI"
                                />
                                <Text fontWeight="semibold" color="#737377">
                                  Minimo
                                </Text>
                              </HStack>
                              <Spacer />
                              <HStack>
                                <InputField
                                  width="2.7rem"
                                  type="number"
                                  min={limitations.minAge}
                                  max={99}
                                  value={limitations.maxAge}
                                  onChange={e => setValue('limitations.maxAge', +e.target.value || 99)}
                                  buttonstyle="LittleI"
                                />
                                <Text fontWeight="semibold" color="#737377">
                                  Massimo
                                </Text>
                              </HStack>
                            </Flex>
                            <RangeSlider
                              min={18}
                              max={99}
                              mt="15px"
                              value={[limitations.minAge, limitations.maxAge]}
                              onChange={v => {
                                setValue('limitations.minAge', Math.min(...v))
                                setValue('limitations.maxAge', Math.max(...v))
                              }}
                              colorScheme="brand">
                              <RangeSliderTrack>
                                <RangeSliderFilledTrack />
                              </RangeSliderTrack>
                              <RangeSliderThumb index={0} />
                              <RangeSliderThumb index={1} />
                            </RangeSlider>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Flex mt="5" mb="5">
                      <ChevronLeftIcon
                        boxSize="2em"
                        aria-label="Back"
                        fontSize="20px"
                        tabIndex={0}
                        _hover={{ color: '#ff7436' }}
                        _focus={{ color: 'red' }}
                        focusable="true"
                        onClick={previousPage}
                        onKeyDown={e => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            previousPage()
                          }
                        }}
                      />
                      <Spacer />
                      <Button alignSelf="center" type="submit" variant="solid">
                        <Box mr="3">Pubblica</Box>
                        <Box>
                          {limitations.maxParticipants !== 'Altro' && limitations.maxParticipants ? limitations.maxParticipants : '0'}
                          <SbamCoinArrow />
                        </Box>
                      </Button>
                    </Flex>
                  </Flex>
                )}
              </>
            )}
            {isEdit && canEdit && (
              <>
                <Box>
                  <HStack>
                    <Box pt="20px" pb="25px" fontSize="22px" fontWeight="700">
                      Modifica evento
                    </Box>
                    <Spacer />
                    <CloseButton
                      size="lg"
                      onClick={() => {
                        navigate(-1)
                      }}
                    />
                  </HStack>
                  <Box mb="30px" position="relative">
                    <InputField
                      label="Modifica titolo"
                      required
                      placeholder='Ad es. "Aperitivo ignorante in centro"'
                      {...register('title', {
                        required: "Devi inserire un titolo per l'evento",
                        maxLength: { message: 'Il titolo non può superare i 40 caratteri', value: 40 },
                      })}
                      maxLength="40"
                    />
                    <Text fontSize="13" position="absolute" right="0">
                      {title.length} / 40
                    </Text>
                  </Box>
                  <Box mb="30px">
                    <InputField
                      type="textarea"
                      label="Modifica descrizione"
                      placeholder="Ad es. 'C'è la formula apericena, il tavolo prenotato è nella sala principale, per il resto ci si sente in chat di gruppo'"
                      {...register('description', {
                        maxLength: { message: 'La descrizione non può superare i 1000 caratteri', value: 1000 },
                      })}
                      maxLength="1000"
                    />
                  </Box>
                </Box>
                <Spacer />
                <Flex mt="5" mb="5">
                  <Spacer />
                  <Button alignSelf="center" type="submit" variant="solid">
                    Modifica
                  </Button>
                </Flex>
                <br />
              </>
            )}
          </form>
        </FormProvider>
      </Wrapper>
      {!isEdit && <Progress position="fixed" bottom="0" left="0" width="100%" height={2} value={progressPercent} colorScheme="brand" marginTop="1" />}
      <EventCreatedModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => {
          navigate('/event/' + createdEventId, { replace: true })
          onClose()
        }}
        eventId={createdEventId}
        eventTitle={title}
        eventDesc={description}
      />
    </>
  )
}

export default CreateEventScreen
