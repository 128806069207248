import { useMutation } from 'react-query'
import { Message } from '@twilio/conversations'

const useDeleteMessageMutation = (message: Message, options = {}) => {
  return useMutation(
    `remove-message-${message.sid}`,
    async () => {
      const removedMessage = await message.updateAttributes({ ...(message.attributes as object), deleted: Date.now() })

      return removedMessage
    },
    {
      ...options,
    },
  )
}

export default useDeleteMessageMutation
