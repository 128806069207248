import Resizer from 'react-image-file-resizer'

const resizeFile = (file: any) =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      'JPEG',
      80,
      0,
      uri => {
        resolve(uri)
      },
      'file',
    )
  })

export default resizeFile
