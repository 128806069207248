import { Checkbox, CheckboxGroup, Flex, FormControl, FormErrorMessage, Text } from '@chakra-ui/react'
import React from 'react'

function PrivacyTermsCheckboxGroup(props: {
  errors: any
  register: any
  hasTermsAndConditionAccepted: boolean
  hasPrivacyPolicyAccepted: boolean
  hasMarketingCommunicationsAccepted: boolean
}) {
  return (
    <Flex flexDir="column" gap="0.5rem">
      <Text textColor="#465362" fontWeight="600" fontSize="0.9rem">
        Giusto qualche formalità
      </Text>
      <CheckboxGroup
        colorScheme="brand"
        defaultValue={[
          props.hasTermsAndConditionAccepted ? 1 : '',
          props.hasPrivacyPolicyAccepted ? 2 : '',
          props.hasMarketingCommunicationsAccepted ? 3 : '',
        ]}>
        <Flex flexDir="column" gap="1.3rem">
          <FormControl isInvalid={!!props.errors.hasTermsAndConditionAccepted}>
            <Checkbox {...props.register('hasTermsAndConditionAccepted', { required: 'Devi accettare i termini e condizioni' })}>
              <Text fontSize="0.9rem">
                Accetto i{' '}
                <a
                  style={{ textDecoration: 'underline', display: 'inline' }}
                  href="https://sbam.social/condizioni-di-utilizzo"
                  target="_blank"
                  rel="noreferrer">
                  termini e condizioni
                </a>{' '}
                <Text display="inline" fontWeight="600" fontSize="1.125rem" textColor="#FF7436">
                  *
                </Text>
              </Text>
            </Checkbox>
          </FormControl>
          <FormControl isInvalid={!!props.errors.hasPrivacyPolicyAccepted}>
            <Checkbox {...props.register('hasPrivacyPolicyAccepted', { required: 'Devi accettare la privacy policy' })}>
              <Text fontSize="0.9rem">
                Accetto la{' '}
                <a
                  style={{ textDecoration: 'underline', display: 'inline' }}
                  href="https://sbam.social/privacy-cookies-policy"
                  target="_blank"
                  rel="noreferrer">
                  privacy policy
                </a>{' '}
                <Text display="inline" fontWeight="600" fontSize="1.125rem" textColor="#FF7436">
                  *
                </Text>
              </Text>
            </Checkbox>
          </FormControl>
          <FormControl isInvalid={!!props.errors.hasMarketingCommunicationsAccepted}>
            <Checkbox {...props.register('hasMarketingCommunicationsAccepted', { required: false })}>
              <Text fontSize="0.9rem">Accetto di ricevere sconti, offerte e promozioni da parte di Sbam!</Text>
            </Checkbox>
          </FormControl>
        </Flex>
      </CheckboxGroup>
    </Flex>
  )
}

export default PrivacyTermsCheckboxGroup
