import { useToast } from '@chakra-ui/react'
import axios, { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import Api from 'services/api/api.service'
import LocalizationService from 'services/localization.service'

const useGetCurrentPositionQuery = (options = {}): any => {
  const toast = useToast()

  return useQuery(
    'get-current-position',
    async () => {
      const coordinates = await LocalizationService.getCurrentPosition()

      // Can happen that for some lat-lng pairs, Api.placesFromCoordinates returns an empty array
      const { data: placeFromCoords } = await Api.placesFromCoordinates(coordinates.coords.latitude, coordinates.coords.longitude)
      const { placeId } = placeFromCoords?.[0] || {}

      if (!placeId) {
        console.error('Cannot retrieve placeId from placeFromCoords: ', placeFromCoords)
        throw new Error('Posizione corrente non trovata')
      }

      const { data: place } = await Api.place(placeId)

      return { place, coordinates }
    },
    {
      onError(error: AxiosError | any) {
        console.error(error)

        if (axios.isAxiosError(error)) {
          return
        }

        toast({
          title: error?.message || 'Qualcosa è andato storto',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        })
      },
      ...options,
    },
  )
}

export default useGetCurrentPositionQuery
