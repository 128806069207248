import { Box } from '@chakra-ui/react'
import useOnLongPress from 'hooks/use-on-long-press'
import React, { MouseEventHandler, TouchEventHandler, useCallback, useState } from 'react'
import useGetMediaFromMessageQuery from '../../hooks/use-get-media-from-message'
import MediaMessageModal from './media-message-modal'

const blockDrawerOpeningOnMouseDown: MouseEventHandler<HTMLDivElement> = e => {
  e.stopPropagation()
  e.preventDefault()
}

const blockDrawerOpeningOnTouchStart: TouchEventHandler<HTMLDivElement> = e => {
  e.stopPropagation()
  e.preventDefault()
}

function MediaMessage({ msg }) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { getIsLongPressOngoing } = useOnLongPress({ namespace: 'chat' })

  const { data: media } = useGetMediaFromMessageQuery(msg)
  const url = media?.url || ''

  const handleOnClose = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleOnClick = () => {
    if (getIsLongPressOngoing()) {
      return
    }

    setIsModalOpen(true)
  }

  return (
    <Box color="#465362" fontWeight="500" lineHeight="1.2rem">
      <img src={url} alt="chat-media" onClick={handleOnClick} />

      <div onMouseDown={blockDrawerOpeningOnMouseDown} onTouchStart={blockDrawerOpeningOnTouchStart}>
        <MediaMessageModal isOpen={isModalOpen} onClose={handleOnClose} url={url} />
      </div>
    </Box>
  )
}

export default MediaMessage
