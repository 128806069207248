import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { FormbitContextProvider, useFormbitContext } from '@radicalbit/formbit'
import { I } from 'components/shared/input/input.style'
import React, { useState } from 'react'
import { FaVolumeUp } from 'react-icons/fa'
import schema, { Schema } from './schema'
import useHandleOnSubmit from './use-handle-on-submit'

function Announcement() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleOnClick = () => {
    setIsModalOpen(true)
  }

  return (
    <Flex alignItems="center" gap=".75rem" onClick={handleOnClick} cursor="pointer">
      <Box color="brand.500" fontSize="1.5rem">
        <FaVolumeUp />
      </Box>

      <div>Scrivi in tutte le città</div>

      {isModalOpen && (
        <FormbitContextProvider<Schema> initialValues={{}} schema={schema}>
          <AnnouncementModal setIsModalOpen={setIsModalOpen} />
        </FormbitContextProvider>
      )}
    </Flex>
  )
}

function AnnouncementModal({ setIsModalOpen }) {
  const {
    handleOnSubmit,
    args: { isLoading },
    isSubmitDisabled,
  } = useHandleOnSubmit(setIsModalOpen)

  const handleOnClose = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal isOpen onClose={handleOnClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody pb="40px">
          <InputText />
        </ModalBody>

        <ModalFooter>
          <Flex color="brand.500" cursor="pointer" fontSize="1.5rem" width="100%" justifyContent="center" paddingBottom="3rem">
            <Button borderRadius="10px" isDisabled={isSubmitDisabled} isLoading={isLoading} onClick={handleOnSubmit} type="button">
              Invia messaggio
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
function InputText() {
  const { write, error } = useFormbitContext()

  const handleOnChange = ({ target: { value } }) => {
    write('message', value)
  }

  return (
    <Flex direction="column" gap=".5rem">
      <Flex direction="column" gap="1rem" width="100%">
        <Box fontWeight="500">Testo</Box>
        <I onChange={handleOnChange} placeholder="Inserisci il tuo annuncio" />
      </Flex>

      {error('message') && (
        <Text as="i" color="red">
          {error('message')}
        </Text>
      )}
    </Flex>
  )
}

export default Announcement
