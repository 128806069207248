import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React from 'react'

const EventCostModal = props => {
  const goToOrganizerProfile = () => {
    props.onClose()
    props.navigate(`/profile/${props.organizerId}`)
  }

  return (
    <Modal isCentered isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay bg="blackAlpha.400" backdropFilter="blur(3px)" />
      <ModalContent ml="10px" mr="10px">
        <ModalHeader fontWeight="bold">Come pagare?</ModalHeader>
        <ModalCloseButton size="lg" />
        <ModalBody>
          L'eventuale importo da pagare è deciso dall'organizzatore così come le modalità di pagamento. Sbam! non gestisce i pagamenti né trattiene
          commissioni (art.14 T&C)
        </ModalBody>
        <ModalFooter>
          <Button
            fontWeight="600"
            fontFamily="Poppins"
            textAlign="center"
            variant="solid"
            height="40px"
            width="100%"
            fontSize="17px"
            mr={3}
            onClick={goToOrganizerProfile}>
            Chiedi all'organizzatore
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EventCostModal
