import { Box, Button, Collapse, Flex, InputGroup, Text, useDisclosure } from '@chakra-ui/react'
import useArray from 'hooks/use-array'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Location } from 'typings/database'
import ChipSelect from '../chip-select/chip-select.component'
import CategoryInput from '../input/category.input'
import { Input } from '../input/input.component'
import { I, ILE } from '../input/input.style'

const SearchOptions = ({ setSearch, search }: any) => {
  return (
    <Flex gap="1rem" padding="0rem 1.3rem 0rem 1.3rem" direction="column" position="relative" h="full">
      <SearchEvent search={search} setSearch={setSearch} temporaryHideThisElement={true} />
    </Flex>
  )
}

// temporaryHideThisElement: used to hide the component without deleteing it because it will be usefull
function SearchEvent({ setSearch, search, temporaryHideThisElement }: any) {
  const { value, add, remove, setValue } = useArray([])
  const [title, setTitle] = useState('')
  const [costMax, setCostMax] = useState<number | null>(null)
  const [location, setLocation] = useState<Location | null>(null)
  const [dateFrom, setDateFrom] = useState<string | null>(null)
  const [dateTo, setDateTo] = useState<string | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const save = () => {
    setSearch({
      idCategory: value,
      title,
      costMax,
      dateFrom,
      dateTo,
      location,
    })
    onClose()
  }

  useEffect(() => {
    setCostMax(search.costMax)
    setTitle(search.title)
    setValue(search.idCategory)
    setLocation(search.location)
    setDateFrom(search.dateFrom)
    setDateTo(search.dateTo)
  }, [search])

  if (temporaryHideThisElement) {
    return <></>
  }

  return (
    <>
      <InputGroup size="sm">
        <ILE
          children={
            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
              <g id="Raggruppa_182" data-name="Raggruppa 182" transform="translate(-163 -205)">
                <rect id="Rettangolo_160" data-name="Rettangolo 160" width="29" height="29" transform="translate(163 205)" fill="#fff" opacity="0" />
                <path
                  id="Icon_awesome-search"
                  data-name="Icon awesome-search"
                  d="M24.66,21.618l-4.869-4.869a1.171,1.171,0,0,0-.83-.342h-.8a10.152,10.152,0,1,0-1.758,1.758v.8a1.171,1.171,0,0,0,.342.83l4.869,4.869a1.167,1.167,0,0,0,1.655,0l1.382-1.382a1.177,1.177,0,0,0,0-1.66Zm-14.5-5.21a6.25,6.25,0,1,1,6.25-6.25A6.247,6.247,0,0,1,10.157,16.407Z"
                  transform="translate(165 206.998)"
                  fill="#ff7436"
                />
              </g>
            </svg>
          }
        />
        <I
          onChange={e => {
            setTitle(e.target.value)
          }}
          onFocus={onOpen}
          value={title}
          placeholder="Cerca eventi"
          variant="filled"
          pl="45px !important"
        />
      </InputGroup>

      <Collapse in={isOpen}>
        <Box background="#FFFFFF" minHeight={'calc(100vh - 60px)'} width={'100%'} zIndex={10}>
          <Box py="10px">
            <Text pb="8px" fontWeight="semibold">
              Posizione
            </Text>
            <Input type="location" onChange={setLocation} value={location} placeholder="Dove vuoi cercare eventi?" />
          </Box>
          <Box py="10px">
            <Text pb="8px" fontWeight="semibold">
              Categoria
            </Text>
            <CategoryInput {...{ value, add, remove }} />
          </Box>
          <Box py="10px">
            <Text pb="8px" fontWeight="semibold">
              Dal giorno
            </Text>
            <I type="date" value={dateFrom || ''} onChange={e => setDateFrom(e.target.value || null)} />
          </Box>
          <Box py="10px">
            <Text pb="8px" fontWeight="semibold">
              Al giorno
            </Text>
            <I type="date" value={dateTo || ''} onChange={e => setDateTo(e.target.value || null)} />
          </Box>
          <Box py="10px">
            <Text pb="8px" fontWeight="semibold">
              Contributo
            </Text>
            <ChipSelect
              value={costMax}
              onChange={setCostMax}
              data={[
                {
                  value: 0,
                  label: 'Gratis',
                },
                {
                  value: 5,
                  label: '5€',
                },
                {
                  value: 10,
                  label: '10€',
                },
                {
                  value: 15,
                  label: '15€',
                },
                {
                  value: 20,
                  label: '20€',
                },
                {
                  value: 25,
                  label: '25€',
                },
              ]}
            />
          </Box>
          <Box width="100%" py="20px" textAlign="center">
            <Button mx="auto" width="80%" onClick={save}>
              Cerca
            </Button>
          </Box>
          <Box h="100px" />
        </Box>
      </Collapse>
    </>
  )
}
export default SearchOptions
