import * as React from 'react'
import { Image, keyframes, ImageProps, forwardRef } from '@chakra-ui/react'
import logo from './logo.svg'
import { useNavigate } from 'react-router-dom'

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

export const Logo = forwardRef<ImageProps, 'img'>((props, ref) => {
  const navigate = useNavigate()
  return <Image h="19px" onClick={() => navigate('/')} alt="Sbam Logo" cursor="pointer" src={logo} ref={ref} {...props} />
})
