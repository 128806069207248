import moment from 'moment'

// *** *** *** *** *** *** *** ***
// Move this file in another folder
// *** *** *** *** *** *** *** ***
export const getTimeFromTimestamp = (timestamp): string => {
  return moment(timestamp).isSame(moment(), 'day') ? moment(timestamp).format('HH:mm') : moment(timestamp).format('HH:mm DD/MM/Y')
}

export default function getTimeFromMessage(message): string {
  return moment(message.dateCreated).isSame(moment(), 'day')
    ? moment(message.dateCreated).format('HH:mm')
    : moment(message.dateCreated).format('HH:mm DD/MM/Y')
}
