import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React from 'react'
import { ReactComponent as BoltsIcon } from './../../assets/bolts.svg'

const UnblockChatModal = props => {
  const joinParticipants = () => {
    props.onClose()
    props.createAttendance()
  }

  return (
    <Modal isCentered isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay bg="blackAlpha.400" backdropFilter="blur(3px)" />
      <ModalContent mr="10px" ml="10px">
        <ModalHeader fontWeight="bold">Sblocca la chat!</ModalHeader>
        <ModalCloseButton size="lg" />
        <ModalBody>La chat di gruppo dell'evento è riservata ai soli partecipanti. Partecipa all'evento per accedere.</ModalBody>
        <ModalFooter>
          <Button
            fontWeight="600"
            fontFamily="Poppins"
            textAlign="center"
            variant="solid"
            height="40px"
            width="100%"
            rightIcon={<BoltsIcon />}
            fontSize="17px"
            onClick={joinParticipants}>
            Unisciti ai partecipanti -{props.attendanceCost}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UnblockChatModal
