import { Button, Flex, Icon, Text } from '@chakra-ui/react'
import { selectorAuth } from 'features/auth/auth.slice'
import React from 'react'
import { useSelector } from 'react-redux'
import { calculateDistance } from 'utils/calculate-distance.util'
import { ReactComponent as PositionIcon } from './../../assets/position.svg'

const EventLocation = props => {
  const position = useSelector(selectorAuth).position

  const goToMap = () => {
    window.open(
      props.isOrganizer || props.attendance
        ? `https://www.google.com/maps/search/
          ${props.event.location.details.address}
          /@
          ${props.event.location.realPosition.coordinates[0]}
          ,
          ${props.event.location.realPosition.coordinates[1]}
          z/`
        : `https://www.google.com/maps/search/?api=1&query=${props.event.location.fakePosition.coordinates[1]}%2C${props.event.location.fakePosition.coordinates[0]}`,
      '_blank',
    )
  }

  return (
    <Flex direction="row" alignItems="center" justifyContent="space-between">
      <Flex direction="row" alignItems="center">
        <Icon mr="2" as={PositionIcon} boxSize="20px" color="#1A5436" />

        <Text fontFamily="Poppins" fontWeight="400" fontSize="15px" lineHeight="22.5px">
          {(props.isOrganizer || props.attendance) && `in ${props.event.location.details.address}`}
          {!props.isOrganizer &&
            !props.attendance &&
            position &&
            props.event.location.fakePosition &&
            `a ${calculateDistance(position, {
              lon: props.event.location.fakePosition.coordinates[0],
              lat: props.event.location.fakePosition.coordinates[1],
            })}km da te (${props.event.location.province.name})`}
          {!props.isOrganizer && !props.attendance && (!position || !props.event.location.fakePosition) && `a ${props.event.location.province.name}`}
        </Text>
      </Flex>

      <Button
        onClick={goToMap}
        variant="outline"
        height="30px"
        fontFamily="Poppins"
        fontWeight="500"
        fontSize="15px"
        lineHeight="18px"
        textAlign="center"
        width="62px"
        pl="9"
        pr="9"
        position="relative">
        Mappa
      </Button>
    </Flex>
  )
}

export default EventLocation
