import CheckLocationPermission from '@shared/check-location-permission'
import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { selectorAuth } from 'features/auth/auth.slice'

const AuthGate = ({ children }: { children: ReactNode }) => {
  const authState = useSelector(selectorAuth)

  if (authState.isAuthenticated) {
    return <CheckLocationPermission>{children}</CheckLocationPermission>
  } else {
    return <Navigate to={'/access'} />
  }
}

export default AuthGate
