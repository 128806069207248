import { Button } from '@chakra-ui/react'
import React from 'react'

const LoginScreenFormSubmit = (props: { isLoading: boolean }) => {
  return (
    <Button type="submit" width="100%" height="3.3rem" isLoading={props.isLoading}>
      Accedi
    </Button>
  )
}

export default LoginScreenFormSubmit
