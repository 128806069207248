import { Flex, Heading, Spacer, CloseButton, Text, OrderedList, ListItem, Card, CardBody, Icon, Button, Link } from '@chakra-ui/react'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as BanCard } from '../../assets/ban-card.svg'
import useCurrentUserStatus from 'hooks/use-user-status'
import moment from 'moment'

import React from 'react'

const BanScreen = () => {
  const navigate = useNavigate()

  const { activeWarning } = useCurrentUserStatus()

  return (
    <Wrapper height="100%" pb="90px" pl="10px" pr="10px" pt="20px">
      <Flex>
        <Heading as="h1" size="lg">
          Bannatə!
        </Heading>
        <Spacer />
        <CloseButton
          size="lg"
          onClick={() => {
            navigate(-1)
          }}
        />
      </Flex>
      <Heading as="h2" size="md" mt="30px">
        Come si viene bannatə?
      </Heading>
      <Text mt="10px">Succede quando per più volte:</Text>
      <OrderedList>
        <ListItem>Non ti sei presentato ad un evento a cui hai partecipato</ListItem>
        <ListItem>Sei stato segnalato per comportamenti scorretti</ListItem>
      </OrderedList>
      <Heading as="h2" size="md" mt="10px">
        Cosa comporta?
      </Heading>
      <Card mt="20px" borderRadius="15px" variant="outline">
        <CardBody>
          <Heading as="h3" size="sm">
            <Icon boxSize="6">
              <BanCard />
            </Icon>{' '}
            Bannatə
          </Heading>
          <Text mt="5px">
            Non puoi partecipare agli eventi fino al {moment(activeWarning.expiresAt).format('DD/MM/YYYY')} all'orario{' '}
            {moment(activeWarning.expiresAt).format('hh:mm:ss')}
          </Text>
        </CardBody>
      </Card>
      <Link href="https://sbam.social/supporto" isExternal>
        <Button mt="30px" width="100%" height="40px" variant="outline">
          Vai al supporto
        </Button>
      </Link>
    </Wrapper>
  )
}

export default BanScreen
