import React, { useState } from 'react'
import { Button, useToast, Grid, Text } from '@chakra-ui/react'
import useGetEventCategories from './use-get-event-categories'

function CategoryInput({ categoryId, setValue, register, fieldName, onTagClick }) {
  const toast = useToast()

  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(categoryId)

  const {
    isLoading: isLoadingCategories,
    error,
    data: categories,
  } = useGetEventCategories({
    onError: () => {
      if (error && typeof error == 'string') {
        toast({
          title: error,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      } else if (error && typeof error?.message == 'string') {
        toast({
          title: error.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      }
    },
  })

  if (isLoadingCategories || !categories?.data) {
    return null
  }

  register(fieldName, {
    required: "Devi inserire almeno una categoria per l'evento",
  })

  const isScreenXS = window.screen.width < 420

  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" mt="2" width="100%" columnGap="3">
        {categories.data.map(category => {
          const handleOnClick = () => {
            setValue(fieldName, category._id)
            setSelectedCategoryId(category._id)
            onTagClick(category._id, category.name)
          }

          return (
            <Button
              key={category._id}
              h="8"
              mt="3"
              pl="12"
              pr="12"
              fontSize="md"
              size="md"
              color={category._id === selectedCategoryId ? 'white' : '#737377'}
              variant={category._id === selectedCategoryId ? 'solid_static' : 'ghost_border'}
              borderRadius={50}
              onClick={handleOnClick}>
              <Text fontSize={isScreenXS ? 12 : 16}>{category.name}</Text>
            </Button>
          )
        })}
      </Grid>
    </>
  )
}

export default CategoryInput
