import { Box, Button, HStack, Heading, useToast } from '@chakra-ui/react'
import CategoryInput from 'components/shared/input/category.input.new'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { useAppDispatch } from 'features'
import { refreshUser } from 'features/auth/auth.actions'
import { selectorAuth, setSkipPreferredCategories } from 'features/auth/auth.slice'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Api from 'services/api/api.service'
import { User } from 'typings/database.d'

const PreferredCategoriesScreen = () => {
  const dispatch = useAppDispatch()

  const handleSkip = () => {
    dispatch(setSkipPreferredCategories(true))
    dispatch<any>(refreshUser())
  }

  const toast = useToast()
  const {
    user: { preferredCategories: preferredCategoriesRaw },
  } = useSelector(selectorAuth)
  const preferredCategories = preferredCategoriesRaw?.map((category: any) => category._id)
  const { handleSubmit, control } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      preferredCategories,
    },
  })
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (data: User) => {
    if (!data.preferredCategories || data.preferredCategories.length === 0) {
      return dispatch(setSkipPreferredCategories(true))
    }
    const body = new FormData()
    body.append('preferredCategories', data.preferredCategories)
    try {
      setIsLoading(true)
      await Api.editUser({
        preferredCategories: data.preferredCategories,
      })
      setIsLoading(false)
      dispatch<any>(refreshUser())
    } catch (e: any) {
      setIsLoading(false)
      if (e?.response?.data?.message) {
        toast({
          title: e.response.data.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      }
      if (typeof e?.response?.data === 'string') {
        toast({
          title: e.response.data,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      }
    }
  }

  const handleOnSubmit = (data: any) => {
    handleSubmit(onSubmit)(data)
  }

  return (
    <Wrapper pt="20px" pb="90px">
      <form style={{ display: 'contents' }} onSubmit={handleOnSubmit}>
        <Heading size="lg" mb={39} fontWeight="bold">
          Cosa ti interessa?
        </Heading>
        <Box mb="30px">
          <Controller control={control} name="preferredCategories" render={({ field }) => <CategoryInput {...field} />}></Controller>
        </Box>
        <HStack>
          <Button isLoading={isLoading} width="90%" alignSelf="center" onClick={handleSkip} variant="link">
            Salta
          </Button>
          <Button isLoading={isLoading} width="90%" alignSelf="center" type="submit">
            Avanti
          </Button>
        </HStack>
      </form>
    </Wrapper>
  )
}

export default PreferredCategoriesScreen
