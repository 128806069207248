import { Flex } from '@chakra-ui/react'
import React from 'react'
import PasswordField from './password-field'
import RecoverPasswordButton from './recover-password-button'

const PasswordFieldFlex = (props: { register: any }) => {
  return (
    <Flex flexDir="column" gap="1rem">
      <PasswordField register={props.register} />
      <RecoverPasswordButton />
    </Flex>
  )
}

export default PasswordFieldFlex
