import { Conversation } from '@twilio/conversations'
import { byCreationDate } from 'hooks/use-chat'
import useGetAllConversations from '../useGetAllConversations'

const useGetConversationByPage = page => {
  const query = useGetAllConversations()
  const [{ data: firstPage = [] }, { data: rest }] = query

  const rawConversations: Conversation[] = rest ? firstPage.concat(rest) : firstPage
  rawConversations.sort(byCreationDate)

  const conversations = rawConversations.filter(conversation => {
    const attributes = conversation.attributes as { type: 'eventChat' | 'communityChat' | 'userChat' }
    return attributes.type === page
  })

  return { conversations, query }
}

export default useGetConversationByPage
