import { InputGroup, Stack } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { I, IRE } from './input.style'
import { ReactComponent as CalendarIcon } from './../../../assets/calendar.svg'
import { InputComponentProps } from './input.component'

export const CalendarInput = (props: InputComponentProps) => {
  const inputRef = useRef<HTMLInputElement>()

  const openPicker = () => {
    inputRef.current?.showPicker()
  }

  return (
    <InputGroup size="sm">
      <I ref={inputRef} {...props} focusBorderColor="#FF7436" variant="filled" />
      <IRE
        children={
          !props.disabled && (
            <Stack
              align="center"
              justify="center"
              borderRadius="10px"
              width="60px"
              height="100%"
              minH="40px"
              maxH="50px"
              backgroundColor="brand.500"
              mr="-1">
              <CalendarIcon onClick={openPicker} color="white" />
            </Stack>
          )
        }
      />
    </InputGroup>
  )
}
