import { Box, Flex } from '@chakra-ui/react'
import { SYSTEM_AUTHOR_NAME } from 'costants'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Logo from '../../../assets/logo-lightning-white.svg'
import { getAuthorNameFromUser } from '../hooks/use-get-user-from-message'
import MessageAvatar from '../message-avatar'

function ChatBoxHeader({ msg, user, isMine, isSameAuthorAsNext }) {
  if (msg.author === 'system') {
    return <ChatBoxHeaderSystem user={user} isMine={isMine} isSameAuthorAsNext={isSameAuthorAsNext} />
  }

  return <ChatBoxHeaderInner user={user} isMine={isMine} isSameAuthorAsNext={isSameAuthorAsNext} />
}

function ChatBoxHeaderSystem({ user, isMine, isSameAuthorAsNext }) {
  return (
    <Flex alignItems="center" cursor="pointer" gap=".5rem">
      <img
        src={Logo}
        alt="system-avatar"
        style={{ width: '1.5rem', height: '1.5rem', padding: '.25rem', borderRadius: '1rem', backgroundColor: '#FF7436' }}
      />

      <Box fontWeight="600" color="#011936">
        {SYSTEM_AUTHOR_NAME}
      </Box>
    </Flex>
  )
}

function ChatBoxHeaderInner({ user, isMine, isSameAuthorAsNext }) {
  const navigate = useNavigate()

  const author = getAuthorNameFromUser(user)

  const identity = user?.state?.identity

  const handleOnClick = () => {
    navigate(`/profile/${identity}`)
  }

  if (!isMine && !isSameAuthorAsNext) {
    return (
      <Flex alignItems="center" onClick={handleOnClick} cursor="pointer" gap=".5rem">
        <MessageAvatar user={user} />

        <Box fontWeight="600" color="#011936">
          {author}
        </Box>
      </Flex>
    )
  }

  return <></>
}

export default ChatBoxHeader
