import { Flex } from '@chakra-ui/react'
import React from 'react'
import { ReactComponent as Logo } from '../../assets/logo.svg'

const AccessLogo = () => {
  return (
    <Flex flexDir="column" alignItems="center" mt="4rem">
      <Logo width="9rem" height="10rem" />
    </Flex>
  )
}

export default AccessLogo
