import { Box, Button, useToast, Text, useDisclosure, AvatarBadge, HStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import { useAppDispatch } from 'features'
import Api from 'services/api/api.service'
import { User } from 'typings/database.d'
import InputField from 'components/shared/input/input.component'
import UserAvatar from 'components/shared/user-avatar/user-avatar'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import SelectField from 'components/shared/select/select.component'
import UserAvatarEditor from 'components/shared/user-avatar-editor/user-avatar-editor'
import resizeFile from 'utils/resize-file'
import { logoutAction, refreshUser } from 'features/auth/auth.actions'
import { useSelector } from 'react-redux'
import { selectorAuth, setPreviousUser, setSkipNumberConfirmation, setCanModifyPhoneNumber } from 'features/auth/auth.slice'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { updateEmail } from 'firebase/auth'
import { Confirm } from 'components/shared/confirm/confirm'
import { Capacitor } from '@capacitor/core'
import { getFirebaseAuth } from 'services/firebase.service'
import { UploadIcon } from 'components/shared/icons/icons'
import { genderDictionary } from 'costants/genders'

const SettingsScreen = () => {
  const navigate = useNavigate()
  const { user } = useSelector(selectorAuth)
  const toast = useToast()
  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { ...user },
  })
  const { register, handleSubmit, watch, control } = form
  const dispatch = useAppDispatch()

  const deleteAccount = async () => {
    if (Capacitor.getPlatform() !== 'ios') return window.open('https://sbam.social/delete-account', '_blank')
    if (await !Confirm({ title: 'Elimina account', message: 'Sei sicuro di voler eliminare il tuo account?' })) return
    try {
      await Api.deleteProfile()
      logoutAction()
      toast({
        title: 'Account eliminato con successo',
        status: 'success',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      })
      navigate('/login', { replace: true })
    } catch (e: any) {
      if (e?.response?.data?.message) {
        toast({
          title: e.response.data.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
      if (typeof e?.response?.data === 'string') {
        toast({
          title: e.response.data,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
    }
  }

  const onSubmit = async (data: User) => {
    const body = {
      firstName: data.firstName,
      lastName: data.lastName,
      bioDescription: data.bioDescription,
      gender: data.gender,
    }
    try {
      const result = await Api.editUser(body)
      const auth = getFirebaseAuth()
      if (data.email !== user.email) {
        await updateEmail(auth.currentUser!, data.email)
      }
      if (result && result.status === 200) {
        dispatch<any>(refreshUser())
        toast({
          title: 'Profilo modificato con successo',
          status: 'success',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
        navigate('/profile', { replace: true })
      }
    } catch (e: any) {
      if (e?.response?.data?.message) {
        toast({
          title: e.response.data.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
      if (typeof e?.response?.data === 'string') {
        toast({
          title: e.response.data,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
    }
  }

  const setPhoneNumber = () => {
    dispatch(setPreviousUser(user))
    dispatch(setSkipNumberConfirmation(false))
    dispatch(setCanModifyPhoneNumber(true))
    dispatch<any>(refreshUser())
  }

  const firstName = watch('firstName')
  const lastName = watch('lastName')

  const { isOpen, onOpen, onClose } = useDisclosure()
  const handleAvatarUpload = async (file: any) => {
    const body = new FormData()
    body.set('avatar', (await resizeFile(file)) as any)
    try {
      await Api.editAvatar(body)
      onClose()
      toast({
        title: 'Foto profilo modificata con successo',
        status: 'success',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
      dispatch<any>(refreshUser())
    } catch (e: any) {
      if (e?.response?.data?.message && typeof e?.response?.data?.message === 'string') {
        toast({
          title: e.response.data.message || 'Error',
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
    }
  }

  return (
    <Wrapper>
      <FormProvider {...form}>
        <form style={{ display: 'contents' }} onSubmit={handleSubmit(onSubmit)}>
          <Box alignSelf="center" mb="30px">
            <UserAvatarEditor {...{ isOpen, onOpen, onClose }} onUpload={handleAvatarUpload} />
            <UserAvatar size="2xl" name={`${firstName.charAt(0)} ${lastName.charAt(0)}`} src={user.avatar} onClick={onOpen}>
              <AvatarBadge bg="brand.500" boxSize="0.9em" borderColor="brand.500" borderWidth={1}>
                <UploadIcon boxSize={5} />
              </AvatarBadge>
            </UserAvatar>
          </Box>
          <Box mb="30px">
            <InputField placeholder="Nome" required {...register('firstName', { required: true })} />
          </Box>
          <Box mb="30px">
            <InputField placeholder="Cognome" required {...register('lastName', { required: true })} />
          </Box>
          <Box mb="30px">
            <InputField
              type="textarea"
              label="Biografia"
              placeholder="Racconta qualcosa di unico su di te"
              maxLength={150}
              {...register('bioDescription', { required: true })}
            />
          </Box>
          <Box mb="30px">
            <Text pb="5px" fontWeight="semibold" color="#465362">
              Genere
            </Text>
            <Text ml="2">{genderDictionary[user.gender]}</Text>
          </Box>
          <Box mb="30px">
            <Text pb="5px" fontWeight="semibold" color="#465362">
              Data di nascita
            </Text>
            <Text ml="2">{user.birthdate ? moment(user.birthdate).format('DD MMMM YYYY') : ''}</Text>
          </Box>
          <Box mb="30px">
            <Text pb="5px" fontWeight="semibold" color="#465362">
              Numero di telefono
            </Text>
            {user.phone ? (
              <HStack pl={5} pr={5}>
                <Text width="50%">{user.phone}</Text>
                <Button width="50%" onClick={setPhoneNumber}>
                  Cambia
                </Button>
              </HStack>
            ) : (
              <Button width="100%" onClick={setPhoneNumber}>
                Imposta
              </Button>
            )}
          </Box>
          <Box mb="30px">
            <Text pb="5px" fontWeight="semibold" color="#465362">
              Email
            </Text>
            <Text ml="2">{user.email ? user.email : ''}</Text>
          </Box>
          <Button width="90%" alignSelf="center" type="submit" mb="30px">
            Salva
          </Button>
          <Button variant="link" fontSize={12} alignSelf="center" onClick={deleteAccount} mt="30px" mb="30px">
            Elimina account
          </Button>
        </form>
      </FormProvider>
    </Wrapper>
  )
}

export default SettingsScreen
