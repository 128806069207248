import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// see https://www.smashingmagazine.com/2020/08/redux-real-world-application/

interface AppState {
  isMenuOpen: boolean
  isFirstLogin: boolean
  isSearchGlobal: boolean
}

const initialState: AppState = {
  isMenuOpen: false,
  isFirstLogin: false,
  isSearchGlobal: false,
}

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMenuOpen(state, action: PayloadAction<boolean>) {
      state.isMenuOpen = action.payload
    },
    setisFirstLogin(state, action: PayloadAction<boolean>) {
      state.isFirstLogin = action.payload
    },
    setSearchToGlobal(state) {
      state.isSearchGlobal = true
    },
    setSearchToNear(state) {
      state.isSearchGlobal = false
    },
  },
})

export const selectorApp = (state: { app: AppState }) => state.app
export const { setMenuOpen, setisFirstLogin, setSearchToGlobal, setSearchToNear } = app.actions
export default app.reducer
