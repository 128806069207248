import { Box, Text } from '@chakra-ui/react'
import InputField from '@components/shared/input/input.component'
import React from 'react'

const PasswordField = (props: { register: any }) => {
  return (
    <Box>
      <Text textColor="#465362" fontWeight="600" fontSize="0.9rem">
        La tua password{' '}
        <Text color="#FF7436" display="inline">
          *
        </Text>
      </Text>
      <InputField
        type="password"
        {...props.register('password', { required: 'Devi inserire la tua password' })}
        placeholder="Se non la ricordi, recuperala qui sotto"
      />
    </Box>
  )
}

export default PasswordField
