export const genderMap = [
    ['male', 'Uomo', 'blue'],
    ['female', 'Donna', '#b041a9'],
    ['other', 'Altrə', 'black'],
  ]

export const genderDictionary = {
  male: 'Uomo',
  female: 'Donna',
  other: 'altro'
}