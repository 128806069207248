import { useQuery } from 'react-query'
import { firebaseApp } from '@src/services/firebase.service'
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config'

const remoteConfig = getRemoteConfig(firebaseApp)

const useFetchAndActivateRemoteConfig = () => {
  return useQuery('fetch-and-activate-remote-config', () => {
    fetchAndActivate(remoteConfig)
  })
}

export default useFetchAndActivateRemoteConfig
