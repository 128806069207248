import { Box, Flex } from '@chakra-ui/react'
import { AttendeesIcon } from 'components/shared/icons/icons'
import React from 'react'
import { FaArrowLeft } from 'react-icons/fa6'
import { useNavigate, useParams } from 'react-router-dom'
import history from 'utils/history'
import { default as useGetConversationQueryBySid } from '../hooks/use-get-conversation-query'

function Header() {
  const { sid } = useParams()

  const { data } = useGetConversationQueryBySid(sid)
  const conversation = data?.conversation
  const bgImage = conversation?.attributes.data?.coverUrl ? `url('${conversation?.attributes.data.coverUrl}')` : ''

  return (
    <>
      <Flex
        alignItems="center"
        bgImage={bgImage}
        bgPos="center"
        bgSize="cover"
        borderBottomRadius="15px"
        boxShadow="inset 0 0 0 2000px rgba(1, 25, 54, .5)"
        color="#FFFFFF"
        flexShrink="0"
        fontSize="18px"
        fontWeight="600"
        height="60px"
        justifyContent="space-between"
        left="0"
        position="absolute"
        px="20px"
        top="0"
        width="100%"
        zIndex="50">
        <Flex alignItems="center">
          <Box cursor="pointer" marginRight="20px" onClick={history.back}>
            <FaArrowLeft />
          </Box>

          <Name />
        </Flex>

        <Flex alignItems="center">
          <AttendeesIcon height="18px" marginRight="5px" /> {conversation?.participantsCount}
        </Flex>
      </Flex>

      <Box h="60px"></Box>
    </>
  )
}

function Name() {
  const navigate = useNavigate()
  const { sid } = useParams()

  const { data } = useGetConversationQueryBySid(sid)
  const conversation = data?.conversation

  const handleOnOpenEvent = () => {
    navigate('/event/' + conversation?.attributes.data?.id)
  }

  if (conversation?.attributes.type === 'eventChat') {
    return (
      <Box cursor="pointer" onClick={handleOnOpenEvent}>
        {conversation?.name}
      </Box>
    )
  }

  return <span>{conversation?.name}</span>
}

export default Header
