const getWelcomeMessage = (name: string): string => `
  ${usefulInfo()}

  In questa chat l'utente deve creare un Evento. Qui di seguito c'è la definizione dei campi che devono essere
  chiesti all'utente. La definizione è stata scritta utilizzando la sintassi di Typescript:
  ${typescriptSchema}

  Qui di seguito un esempio di un JSON Event valido che deve costruito come risultato della chat con l'utente:
  ${jsonExample}

  Le informazioni che l'utente deve fornitci sono le seguenti:
  ${fields}

  Come primo messaggio usa un messaggio colloquiale, sintetico e senza asterischi. Presentati, saluta l'utente ${name} e poi chiedigli "che evento vuole organizzare".

  INDICAZIONI GENERALI:
  ${miscellaneous}

  RECAP RISPOSTA CHE MI FORNISCI
  ${wantedGeminiAnswer}
`

const getMessageForGemini = (question: string, answer: string, yupErrors: string, event: {}): string => {
  return `
  Questo è il JSON correntemente popolato: ${JSON.stringify(event, null, 2)}. Prendi la risposta dell'utente e continua a popolare 
  i suoi campi.

  ${yupErrorHint(yupErrors)}
  
  ${usefulInfo()}

  "${answer}: questa è la risposta dell'utente alla domanda "${question}". 

  Dalla risposta dell'utente estrapola tutti i campi che puoi estrapolare per popolare il JSON Type Event che ti è stato specificato.
  
  Fai una domanda all'utente in modo che possa continuare a popolare i campi mancanti se c'è ne sono.
  
  Se l'utente ha specificato tutti i campi setta il metadata isAllMandatoryFieldsPopulated a true chiedendo
  all'utente "Ok è tutto pronto, c'è qualche informazione che vuoi modificare ?"

  INDICAZIONI GENERALI:
  ${miscellaneous}

  RECAP RISPOSTA CHE MI FORNISCI
  ${wantedGeminiAnswer}

  RECAP JSON:
  ${typescriptSchema}

  RECAP CAMPI:
  ${fields}
`
}

/**
 * *** *** *** ***
 * PRIVATE UTILS *
 * *** *** *** ***
 */
const usefulInfo = () => `Alcune info che potrebbero esserti utili:
  Oggi è il ${new Date()}
`

const jsonExample = `
  const jsonEsempioValido = { 
    title: 'Gemini ABC',
    location: {
      city: Milano MI,
      street: Via Italia,
      houseNumber: 20
    },
    limitations: { maxAge: 80, minAge: 20, maxParticipants: 15, maleParticipants: 5, femaleParticipants: 5, otherParticipants: 5 },
    categoryId: 'Cibo',
    cost: 15,
    startDate: '2024-08-21T14:57:00.000Z',
    description: 'Qui la descrizione del mio evento',
  }
`

const typescriptSchema = `
  type Event = {
    title: string,
    location: {
      city: string,
      street: string,
      houseNumber: number,
    },
    limitations: {
      maxAge?: number
      minAge?: number
      maxParticipants: number
      maleParticipants?: number
      femaleParticipants?: number
      otherParticipants?: number
    },
    categoryId: string,
    cost?: number,
    startDate: Date,
    description?: string
  }
`

const fields = `
  1 - Titolo evento [obblicatorio]: (campo title)
  2 - Tipologia di evento [obblicatorio]: le opzioni possibili sono Studio, Cibo, Disco, Giochi, Concerto, Arte, Giretto, Sport, Altro. Qualsiasi altra risposta diversa da questa deve essere scartata (campo categoryId).
  3 - Punto di ritrovo [obblicatorio]: l'utente deve inserite una Città ed un indirizzo con numero civico valido (campo location)
  4 - Data e ora di inzio [obblicatorio]: se l'utente non specifica l'anno assumi sia l'anno corrente (campo startDate)
  5 - Costo di partecipazione [opzionale]: l'evento evento può avere o meno un costo di partecipazione. Il costo deve essere espresso in Euro (campo cost).
  6 - Altri dettagli utili [opzionale]: in questo campo potrà essere inserita una descrizione dell'evento. Se l'utente non specifica nulla dovrai generare te una descrizione breve, concisa e chiara dell'evento che l'utente sta andando a creare. (campo description).
  7 - Posti totali disponibili [obbligatorio]: in questo campo l'utente indica il massimo numero di partecipanti per il suo evento (campo limitations.maxParticipants)
  8 - Partecipanti per genere [opzionale]: qua l'utente può indicare i posti uomo/donna/altro disponibili per il suo evento (campo limitations.maleParticipants, limitations.femaleParticipants, limitations.otherParticipants).
  9 - Età consigliata [opzionale]: questo campo verrà usato dall'utente per indicare il range di età consigliata per l'evento. Se l'utente non specifica nulla a riguardo, il range deve essere 18-99 anni (campo limitations.minAge, limitations.maxAge)
`

const wantedGeminiAnswer = `
  I messaggi che mi mandi dovranno essere in formato JSON. Questo JSON dovà contenere due campi: question, event e metadata.
  1. Nel campo "question" ci dovrà essere il testo da mostrare all'utente
  2. Nel campo "event" ci dovrà essere il JSON type Event parzialmente o totalmente completato.
  3. Nel campo metadata mi dovrai: 
    a. settare hasCity a true quando l'utente specifica la città
    b. settare hasStreet a true quando l'utente specifica la via
    c. settare hasNumber a true quando l'utente specifica il numero civico
    d. settare isAllMandatoryFieldsPopulated a true quando il JSON type Event che stai costruendo avrà popolati tutti i campi obbligatori.

  e.g.
  {
    question: "Indicami l'orario del tuo evento",
    event: { 
      title: 'Pizza in compagnia',
      categoryId: 'Cibo',
      cost: 15,
    }
    metadata: {
      isLocationCorrect: true,
      isAllMandatoryFieldsPopulated: false,
    }
  }
`

const yupErrorHint = (yupErrors: string): string =>
  yupErrors
    ? `Questi sono gli errori di validazione attualmente presenti, ricordati di correggerli o di chiedere all'utente di correggerli.
    L'utente non può completare la procedura di creazione evento se ci sono degli errori: ${yupErrors}`
    : ''

const miscellaneous = `
  I messaggi che mostriamo all'utente devono contenere delle frasi il più sintetiche possibile, colloquiali e senza asterischi.
  Non mostrare il recap di quanto l'utente ha precedentemente inserito.

  Il campo "Altri dettagli utili" (desc), se non specificato diversamente dall'utente, devi popolarlo tu con qualcosa di esaustivo, 
  inerente a quanto l'utente ha specificato riguardo all'evento.

  Per il campo "Punto di ritrovo" va chiesto all'utente di specificare precisamente la citta, l'indirizzo ed il numero civico precisi del punto di ritrovo. 
  Anche se l'utente ti ha già fornito qualche informazione riguardo alla posizione dell'evento, quando gli chiedi del
  "Punto di ritrovo" la farse da fornigli dovrà essere "Quale sarà il punto di ritrovo per il tuo evento ?" aggiungendo
  in seguito di specificare tutte le informazioni necessarie.

  Il campo "Tipologia di Evento" deve essere inferito da quello che l'utente descrive se non diversamente specificato dall'utente.

  Quando si chiedono informazioni riguardo ai "Posti totali disponibili" bisogna anche chiedere se si vuole specificare quanti posti "uomo", posti "donna"
  o posti "altro" sono previsti per l'evento. La somma dei posti uomo, posti donna e posti altro deve essere uguale al
  numero massimo di partecipanti specificato.

  Il campo "Data e ora di inizio" dovrà avere questo formato nel json "2024-08-21T14:57:00.000Z". Se l'orario 
  indicato è ambiguo chiedi di specificare se è un orario serale o pomeridiano e.g. se dice "l'evento è alle 10" 
  chiedigli se di mattina o sera.
  Anche se l'utente ti ha già fornito qualche informazione riguardo alla "Data e ora di inizio", quando gli chiedi della
  "Data e ora di inizio" la farse da fornigli dovrà essere "Indicami il giorno e l'ora in cui i partecipanti dovranno trovarsi al punto di ritrovo?".
  Se hai già l'indirizzo preciso del punto di ritrovo la frase dovrà essere "Indicami il giorno e l'ora in cui i partecipanti dovranno trovarsi in {location}",
  aggiunsta tu il lessico e la sintassi a seconda della stringa che hai a disposizione per quanto riguarda il "Punto di ritrovo"

  Il campo "Costo di partecipazione" è relativo alla partecipazione all'evento, non alla attività specifica che
  i partecipanti andranno a fare e.g. se l'utente organizza una pizzata il costo è per partecipare all'evento
  non è il costo della pizza.

  Quando chiedi informazioni riguardo all'Età consigliata mostra anche un esempio di come fornire l'input.
  Per esempio se l'utente vuole specificare un range di età dai 20 ai 35 anni basta che inserisca: 20-35
  
`

export { getWelcomeMessage, getMessageForGemini }

/**
 *
 *
 *
 * c'è un endpoint da chiamare per calcaolare l'organizeCost che sn le saette che l'utente
 * paga per organizzare all'evento (serve per implementare il bottone pubblica)
 *
 * inserire lo skeleton per indicare che gemini sta rispondendo al posto della stringa 'caricamento...'
 *
 *src/screens/chat/create-event-chat-bot/gemini-messages.ts
 */
