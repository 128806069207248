import { Box, Img } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'

export const AdvBanner = () => {
  // Download https://docs.google.com/spreadsheets/d/e/2PACX-1vQC1JDDRuTTyPgMTAsdj_lOwdLymtCIkBkxzEXKeRjxxb-9QF9-0ZYPVwuKcLLRHk0WqoJ18jotsmJ2/pub?output=csv
  // using useQuery and axios
  // parse the csv and show the banner
  const { isLoading: isLoadingBanner, data: dataBanner } = useQuery(
    'banner',
    () =>
      axios.get(
        'https://docs.google.com/spreadsheets/d/e/2PACX-1vQC1JDDRuTTyPgMTAsdj_lOwdLymtCIkBkxzEXKeRjxxb-9QF9-0ZYPVwuKcLLRHk0WqoJ18jotsmJ2/pub?output=csv',
      ),
    {
      retry: false,
      // enabled: !!position,
    },
  ) as {
    error: any
    isLoading: boolean
    data: any
  }

  // Parse dataBanner csv as array of objects
  // columns are NOME BRAND,ORIGINE FILE,LINK DESTINAZIONE,PROVINCIA,SCADENZA,COPY
  const bannerData = useMemo(
    () =>
      dataBanner?.data
        .split('\n')
        .map((row: string) => {
          const columns = row.split(',')
          return {
            nomeBrand: columns[0],
            origineFile: columns[1],
            linkDestinazione: columns[2],
            provincia: columns[3],
            scadenza: columns[4],
            copy: columns[5],
          }
        })
        .splice(1),
    [dataBanner],
  )

  return (
    <>
      {/* Adv banner */}
      {!isLoadingBanner && bannerData && bannerData.length > 0 && bannerData[0].origineFile && (
        <Box position="relative" mr="-5" ml="-5">
          <Box w="100%" left="0" h="140px" position="absolute" overflow="hidden">
            <a href={bannerData[0].linkDestinazione} target="_blank" rel="noreferrer">
              <Box pos="relative" maxW={540} m="auto">
                <Img src={bannerData[0].origineFile} alt={bannerData[0].nomeBrand} />
                {/* "Adv" in top right */}
                <Box position="absolute" right="0" top="0" bg="black" color="white" p="5px" fontSize="10px">
                  Adv
                </Box>
              </Box>
            </a>
          </Box>
          <Box h="140px"></Box>
        </Box>
      )}
    </>
  )
}
