import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ThemeProvider,
  useDisclosure,
} from '@chakra-ui/react'
import React, { FC, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { customTheme } from 'theme'

enum AlertType {
  ALERT,
  CONFIRM,
}

export interface IAlertProps {
  message: string
  title: string
  type: AlertType
  confirmText?: string
  declineText?: string
  hasCloseIcon?: boolean
}

let returnResponse: (value: boolean) => void

const AlertRoot: FC<IAlertProps> = props => {
  const { title, message, type, confirmText, declineText, hasCloseIcon = false } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  useEffect(() => {
    onOpen()
  }, [onOpen])

  const confirm = () => {
    if (type === AlertType.CONFIRM) {
      returnResponse(true)
    }
    onClose()
  }

  const cancel = () => {
    if (type === AlertType.CONFIRM) {
      returnResponse(false)
    }
    onClose()
  }

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
          <AlertDialogOverlay bg="blackAlpha.400" backdropFilter="blur(3px)">
            <AlertDialogContent mx="10px">
              <AlertDialogHeader>{title}</AlertDialogHeader>
              <AlertDialogCloseButton size="lg" />
              {hasCloseIcon && <AlertDialogCloseButton />}
              <AlertDialogBody>{message}</AlertDialogBody>
              <AlertDialogFooter>
                {type === AlertType.CONFIRM && (
                  <Button ref={cancelRef} variant="ghost" onClick={cancel}>
                    {declineText || 'Annulla'}
                  </Button>
                )}

                <Button ml={3} onClick={confirm}>
                  {confirmText || 'Conferma'}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </ThemeProvider>
    </>
  )
}

// pass in alert type
function Create(message: string, title: string, confirmText?: string, declineText?: string, type: AlertType = AlertType.ALERT) {
  const rootID = 'temp'
  let div = document.getElementById(rootID)
  if (!div) {
    div = document.createElement('div')
    div.id = rootID
    document.body.appendChild(div)
  }
  ReactDOM.render(<AlertRoot {...{ message, title, confirmText, declineText, type }} />, div)
  if (div) {
    div.remove()
  }
}

export function Confirm({
  message,
  title = 'Confirm',
  confirmText,
  declineText,
}: {
  message: string
  title: string
  confirmText?: string
  declineText?: string
}) {
  Create(message, title, confirmText, declineText, AlertType.CONFIRM)

  return new Promise<boolean>(resolve => {
    returnResponse = resolve
  })
}

export function Alert({
  message,
  title = 'Alert',
  confirmText,
  declineText,
}: {
  message: string
  title: string
  confirmText?: string
  declineText?: string
}) {
  // pass in type
  Create(message, title, confirmText, declineText, AlertType.ALERT)
}
