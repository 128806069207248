import { Box, CloseButton, Flex } from '@chakra-ui/react'
import React from 'react'
import { FaReply } from 'react-icons/fa'
import { useChatContext } from './context/chat-context'

function ReplyBox() {
  const { reply, setReply } = useChatContext()

  const handleOnRemoveReply = () => {
    setReply(undefined)
  }

  if (!reply) {
    return <></>
  }

  return (
    <Flex
      className="reply-box"
      direction="row"
      margin=".25rem"
      alignItems="center"
      gap="1rem"
      justifyContent="space-between"
      px="15px"
      borderWidth="1px"
      borderRadius="5px"
      width="100%">
      <Flex direction="row" gap="1rem" alignItems="center" width="80%">
        <Box color="brand.500">
          <FaReply />
        </Box>

        <Flex direction="column" width="100%">
          <Box textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" width="100%">
            {'Rispondi a '}
            <Box as="span" fontWeight={600}>
              {reply.author}
            </Box>
          </Box>

          <Box fontStyle="italic" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" width="100%">
            {reply.body}
          </Box>
        </Flex>
      </Flex>

      <Box color="brand.500">
        <CloseButton as="span" onClick={handleOnRemoveReply} />
      </Box>
    </Flex>
  )
}

export default ReplyBox
