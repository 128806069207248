import { Button, Flex, Spinner, useDisclosure } from '@chakra-ui/react'
import { CommunityChatOnboardingModal } from '@shared/chat-onboarding-modals/community-chat-onboarding-modal'
import { EventChatOnboardingModal } from '@shared/chat-onboarding-modals/event-chat-onboarding-modal'
import { UserChatOnboardingModal } from '@shared/chat-onboarding-modals/user-chat-onboarding-modal'
import React from 'react'
import { useParams } from 'react-router-dom'
import Chat from '../chat'
import { ChatPageTab } from '../types'
import EmptyMessage from './empty-message'
import Tabs from './tabs'
import useGetConversationByPage from './use-get-conversation-by-page'

const OnboardingButton = () => {
  const { isOpen: isCommunityChatOnboardingOpen, onOpen: onCommunityChatOnboardingOpen, onClose: onCommunityChatOnboardingClose } = useDisclosure()
  const { isOpen: isEventChatOnboardingOpen, onOpen: onEventChatOnboardingOpen, onClose: onEventChatOnboardingClose } = useDisclosure()
  const { isOpen: isUserChatOnboardingOpen, onOpen: onUserChatOnboardingOpen, onClose: onUserChatOnboardingClose } = useDisclosure()
  const { page } = useParams<{ page: ChatPageTab }>()

  const handleOpenOnboarding = () => {
    switch (page) {
      case 'communityChat':
        onCommunityChatOnboardingOpen()
        break
      case 'eventChat':
        onEventChatOnboardingOpen()
        break
      case 'userChat':
        onUserChatOnboardingOpen()
        break
      default:
        break
    }
  }

  return (
    <>
      <Button
        position="absolute"
        right="50%"
        left="50%"
        bottom="72px"
        transform="translate(-50%, -50%)"
        width="fit-content"
        onClick={handleOpenOnboarding}>
        Come funziona?
      </Button>
      <CommunityChatOnboardingModal isOpen={isCommunityChatOnboardingOpen} onClose={onCommunityChatOnboardingClose} />
      <EventChatOnboardingModal isOpen={isEventChatOnboardingOpen} onClose={onEventChatOnboardingClose} />
      <UserChatOnboardingModal isOpen={isUserChatOnboardingOpen} onClose={onUserChatOnboardingClose} />
    </>
  )
}

const ChatListScreen = () => {
  const { page } = useParams()

  const {
    conversations,
    query: [firstPage, rest],
  } = useGetConversationByPage(page)
  const { isIdle, isLoading, isSuccess } = firstPage
  const { isIdle: isRestIdle, isLoading: isRestLoading } = rest

  if (isIdle || isRestIdle || isLoading || isRestLoading) {
    return (
      <Flex className="chat-list-screen" height="calc(100vh - 125px)" direction="row" justifyContent="center" alignItems="center">
        <Spinner size="sm" mr="10px" /> {isIdle ? 'Attesa' : 'Caricamento'} lista chat
      </Flex>
    )
  }

  if (!isSuccess) {
    return <></>
  }

  return (
    <Flex className="chat-list-screen" height="calc(100vh - 125px)" direction="column" ml="auto" mr="auto" maxWidth="500px">
      <Tabs />

      <Flex direction="column" width="100%" height="100%" px="20px" overflowY="scroll">
        <EmptyMessage />

        {conversations?.map(chat => {
          return <Chat key={chat.sid} chat={chat} />
        })}
      </Flex>

      <OnboardingButton />
    </Flex>
  )
}

export default ChatListScreen
