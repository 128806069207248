import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { Button } from '@chakra-ui/button'
import { InputProps } from '@chakra-ui/input'
import { Box, Flex, Text } from '@chakra-ui/react'
import React, { Ref, useState } from 'react'
import { UploadIcon } from '../icons/icons'
import { I } from './input.style'
import { ReactComponent as PencilIcon } from './../../../assets/pencil.svg'

const dataURLToFile = dataURL => {
  const tmpArray = dataURL.split(',')
  const mime = tmpArray[0].match(/:(.*?);/)?.[1]
  const base64string = atob(tmpArray[1])
  let index = base64string.length
  const u8Array = new Uint8Array(index)

  while (index--) {
    u8Array[index] = base64string.charCodeAt(index)
  }

  return new File([u8Array], 'file', { type: mime })
}

export default React.forwardRef(function FileInput(
  props: InputProps & { disabled?: boolean; onFileSelected?: (file: File) => Promise<void>; previewstring: string; onsetcover: (string) => void },
  ref: Ref<any>,
) {
  const openFile = async () => {
    try {
      const image = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
        webUseInput: true,
      })

      if (!image.dataUrl) {
        return
      }

      const file = dataURLToFile(image.dataUrl)
      const url = URL.createObjectURL(file)
      props.onsetcover(url)

      await props.onFileSelected?.(file)
    } catch (e) {
      console.error('Error with image ', e)
      // Do nothing
    }
  }

  return (
    <Box
      height="150px"
      justifyContent="space-between"
      alignItems="center"
      bg="#EEEEEE"
      bgPosition="center"
      borderRadius="15px"
      bgSize="cover"
      bgImage={`url(${props.previewstring})`}
      onClick={!props.disabled ? openFile : () => {}}
      position="relative">
      <I
        disabled={props.disabled}
        cursor="pointer"
        value={props.previewstring}
        onChange={() => {}}
        onClick={openFile}
        focusBorderColor="#FF7436"
        variant="filled"
        placeholder={props.placeholder}
        type="text"
        hidden
      />
      <Box position="absolute" bottom="3" right="3">
        <PencilIcon />
      </Box>
    </Box>
  )
})
