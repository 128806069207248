import { Flex } from '@chakra-ui/react'
import React from 'react'
import NameSurnameFields from './name-surname-fields'
import BirthdayField from './birthday-field'
import GenderRadioGroup from './gender-radio-group'

function UserDetailsFields(props: { errors: any; register: any; gender: string | undefined; setValue: any; birthdate: string }) {
  return (
    <Flex flexDir="column" gap="2rem" mx="1.25rem">
      <NameSurnameFields errors={props.errors} register={props.register} />
      <BirthdayField errors={props.errors} register={props.register} setValue={props.setValue} birthdate={props.birthdate} />
      <GenderRadioGroup errors={props.errors} register={props.register} gender={props.gender} />
    </Flex>
  )
}

export default UserDetailsFields
