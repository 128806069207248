import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { Input } from 'components/shared/input/input.component'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { confirmPasswordReset } from 'firebase/auth'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { auth } from 'services/firebase.service'

export const PasswordReset = () => {
  const [message, setMessage] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('oobCode')
  const id = urlParams.get('id') || '0'
  const resetPassword = async () => {
    try {
      if (!token) {
        return alert('Token non valido')
      }
      if (password === '') {
        return alert('La password non è valida')
      }
      if (password !== passwordRepeat) {
        return alert('Le password non coincidono')
      }
      // const auth = getFirebaseAuth()
      await confirmPasswordReset(auth, token, password)
      // await Api.resetPassword(password, token, id)
      setMessage('Password cambiata con successo. Torna indietro per effettuare il login.')
    } catch (e) {
      setMessage("C'é stato un problema nel cambio password, verifica che il link sia valido o contatta l'assistenza.")
    }
  }
  return (
    <Wrapper justifyContent="center" minH="full">
      <Flex direction="column">
        <Box mb="30px" textAlign="center">
          <Heading mb={4}>Benvenuto in Sbam!</Heading>
          <Text fontWeight="600">Resetta password</Text>
        </Box>
      </Flex>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" maxWidth={800} height="100%" pb="40px">
        {message && (
          <Text alignSelf="flex-start" fontSize="14px" mb="24px">
            {message}
          </Text>
        )}
        {!message && (
          <>
            <Box mb="30px" w="100%">
              <Input type="password" onChange={e => setPassword(e.target.value)} value={password} placeholder="Password" />
            </Box>
            <Box mb="30px" w="100%">
              <Input type="password" onChange={e => setPasswordRepeat(e.target.value)} value={passwordRepeat} placeholder={'Ripeti password'} />
            </Box>
            <Box mb="30px" w="100%" textAlign="center">
              <Button variant="solid" size="md" onClick={resetPassword}>
                Cambia password
              </Button>
            </Box>
          </>
        )}
      </Flex>
      <Box textAlign="center">
        <Text>
          <RouterLink to="/">Torna al login</RouterLink>
        </Text>
      </Box>
    </Wrapper>
  )
}
