import React from "react"
import { Box } from "@chakra-ui/react"

export const WhiteBG = () => {
  return <Box
    position="fixed"
    top="0"
    left="0"
    width="100%"
    style={{ paddingBottom: '0 !important' }}
    height="env(safe-area-inset-top)"
    backgroundColor="#ffffff"
    zIndex="100"></Box>
}