import { useQuery } from 'react-query'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { useAppDispatch } from 'features'
import { refreshUser } from 'features/auth/auth.actions'
import Api from 'services/api/api.service'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectorAuth } from 'features/auth/auth.slice'
import { useCallback, useEffect, useState } from 'react'
import { getClient } from 'utils/get-client.utils'
import { checkNewMessages } from 'utils/check-new-messages'
import * as Sentry from '@sentry/capacitor'
import { Client as ConversationsClient } from '@twilio/conversations'
import { useToast } from '@chakra-ui/react'
import { Browser } from '@capacitor/browser'

export const useFCM = (): {
  client: ConversationsClient | undefined
  conversationsWithUnreadMessages: string[]
  setConversationsWithUnreadMessages: React.Dispatch<React.SetStateAction<string[]>>
} => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isLoading, isAuthenticated, isRegistered, user } = useSelector(selectorAuth)
  const [conversationsWithUnreadMessages, setConversationsWithUnreadMessages] = useState<string[]>([])
  const toast = useToast()
  const { data: client, refetch } = useQuery(
    ['twilio-client', user?._id ?? ''],
    async () => {
      const { data } = await Api.getChatToken()
      const client = await getClient(data.token)
      return client
    },
    {
      enabled: !isLoading && isAuthenticated && isRegistered && !!user?._id,
      onError: error => {
        toast({
          title: 'Impossibile inizializzare la chat',
          position: 'bottom',
          status: 'error',
          duration: 2500,
          isClosable: true,
        })
        Sentry.captureException(error)
      },
    },
  )

  const clearTwilioEvents = useCallback(() => {
    if (client) {
      client.removeAllListeners('tokenAboutToExpire')
      client.removeAllListeners('tokenExpired')
      client.removeAllListeners('messageAdded')
    }
  }, [client])

  const refreshUnreadMessages = useCallback(async () => {
    if (client) {
      setConversationsWithUnreadMessages(await checkNewMessages(client))
    }
  }, [client])

  useEffect(() => {
    if (client && !isLoading && isAuthenticated) {
      refreshUnreadMessages()

      client.on('tokenAboutToExpire', async () => {
        await refetch()
      })

      client.on('tokenExpired', async () => {
        await refetch()
      })

      client.on('messageAdded', message => {
        if (message.author === client.user.identity) {
          return
        }

        setConversationsWithUnreadMessages(prev => {
          if (!prev.includes(message.conversation.sid)) {
            return [...prev, message.conversation.sid]
          } else {
            return prev
          }
        })
      })
    } else {
      clearTwilioEvents()
    }

    return () => {
      clearTwilioEvents()
    }
  }, [client, isLoading, isAuthenticated])

  useQuery(
    ['fcm', user?._id ?? ''],
    async () => {
      let notificationEnabled = false
      const requestPermissions = async () => {
        const { receive: hasPermission } = await FirebaseMessaging.checkPermissions()
        if (hasPermission === 'denied') {
          return false
        } else if (hasPermission !== 'granted') {
          const { receive: hasPermission } = await FirebaseMessaging.requestPermissions()
          return hasPermission === 'granted'
        }

        return true
      }

      try {
        notificationEnabled = await requestPermissions()
      } catch {
        notificationEnabled = false
      }

      if (notificationEnabled) {
        const { token } = await FirebaseMessaging.getToken({
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        })
        if (!user.notificationTokens || !(user.notificationTokens as string[]).includes(token)) {
          await Api.setNotificationToken(token)
          dispatch<any>(refreshUser())
        }

        FirebaseMessaging.addListener('notificationActionPerformed', event => {
          if (event.actionId === 'tap') {
            const data = event.notification.data as { referenceId: string; notificationType: string; url?: string }
            // TODO: porta ad una pagina bianca se si aprono altre notifiche
            switch (data.notificationType) {
              case 'onMessageAdded': {
                return navigate(`/event/${data.referenceId}/chat`)
              }

              case 'NewAttendance':
              case 'EventCanceled':
              case 'EventCreated': {
                return navigate(`/event/${data.referenceId}`)
              }

              case 'AmbassadorCodeRegistered':
              case 'UserCodeRegistered': {
                return navigate(`/profile/${data.referenceId}`)
              }

              case 'Marketing': {
                if (data.url) {
                  if (data.url.startsWith('https://app.sbam.social')) {
                    return navigate(data.url.replace('https://app.sbam.social', ''))
                  }

                  Browser.open({ url: data.url })
                  return
                }

                console.error('NotificatoionType Marketing: missing url')
                return
              }

              case 'Others':
              default: {
                return navigate('/')
              }
            }
          }
        })

        FirebaseMessaging.addListener('notificationReceived', event => {
          const data = event.notification.data as { referenceId: string; notificationType: string }

          if (!conversationsWithUnreadMessages.includes(data.referenceId) && data.notificationType === 'onMessageAdded') {
            setConversationsWithUnreadMessages(prev => [...prev, data.referenceId])
          }
        })
      }

      return true
    },
    { enabled: !isLoading && isAuthenticated && !!user },
  )

  return { client, conversationsWithUnreadMessages, setConversationsWithUnreadMessages }
}
