import { useSelector } from 'react-redux'
import { selectorAuth } from 'features/auth/auth.slice'

const useAmIAdminOrSupport = (): boolean => {
  const {
    user: { isAdmin, role },
  } = useSelector(selectorAuth)

  return isAdmin || role?.toLowerCase() === 'support'
}

export default useAmIAdminOrSupport
