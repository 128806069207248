import {
  Avatar,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Button,
  AvatarProps,
} from '@chakra-ui/react'
import React, { ComponentProps, useEffect, useRef, useState } from 'react'
import ProfilePicture from '@dsalvagni/react-profile-picture'
import '@dsalvagni/react-profile-picture/build/ProfilePicture.css'
import dataURLtoFile from 'utils/data-url-to-file'

const messages = {
  DEFAULT: 'Seleziona o trascina foto',
  DRAGOVER: 'Rilascia foto',
  INVALID_FILE_TYPE: 'File non valido',
  INVALID_IMAGE_SIZE: 'Immagine troppo grande o troppo piccola',
}

interface UserAvatarEditorProps extends AvatarProps {
  isOpen: boolean
  onClose: () => void
  onUpload: (file: File) => void
}

const UserAvatarEditor = (props: UserAvatarEditorProps) => {
  const profileUploader = useRef<any>()
  const [hasValue, setHasValue] = useState(false)
  const handleUpload = () => {
    if (profileUploader.current) {
      props.onUpload(dataURLtoFile(profileUploader.current.getImageAsDataUrl()))
    }
  }
  const handleStatus = (s: string) => {
    if (s === 'loaded') {
      setHasValue(true)
    } else {
      setHasValue(false)
    }
  }
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onClose={() => {
          setHasValue(false)
          props.onClose()
        }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cambia foto profilo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ProfilePicture
              onImageLoaded={() => handleStatus('loaded')}
              onImageRemoved={() => handleStatus('removed')}
              frameFormat={'circle'}
              frameSize={500}
              messages={messages}
              ref={profileUploader}
              useHelper={true}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              onClick={() => {
                setHasValue(false)
                props.onClose()
              }}>
              Annulla
            </Button>
            <Button isDisabled={!hasValue} colorScheme="blue" mr={3} onClick={handleUpload}>
              Carica
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UserAvatarEditor
