import { Flex } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import useGetConversationByPage from './use-get-conversation-by-page'

function EmptyMessage() {
  const { page } = useParams()
  const { conversations } = useGetConversationByPage(page)

  if (conversations?.length) {
    return <></>
  }

  switch (page) {
    case 'communityChat': {
      return <EmptyCommunityChatMessage />
    }

    case 'eventChat': {
      return <EmptyEventsChatMessage />
    }

    case 'userChat': {
      return <EmptyUserChatMessage />
    }

    default:
      return <></>
  }
}

function EmptyCommunityChatMessage() {
  return (
    <Flex alignItems="center" justifyContent="center" maxWidth="500px">
      Non ci sono community vicino alla tua città
    </Flex>
  )
}

function EmptyEventsChatMessage() {
  return (
    <Flex alignItems="center" justifyContent="center" maxWidth="500px">
      Partecipa ad un evento per visualizzarne la relativa chat
    </Flex>
  )
}

function EmptyUserChatMessage() {
  return (
    <Flex alignItems="center" justifyContent="center" maxWidth="500px">
      Non hai ancora nessun match
    </Flex>
  )
}

export default EmptyMessage
