import { Box, FormControl, Text } from '@chakra-ui/react'
import InputField from '@components/shared/input/input.component'
import moment from 'moment'
import React from 'react'

function BirthdayField(props: { errors: any; register: any; setValue: any; birthdate: string }) {
  return (
    <Box width="100%">
      <Text textColor="#465362" fontWeight="600" fontSize="0.9rem">
        Quando sei natə?{' '}
        <Text color="#FF7436" display="inline">
          *
        </Text>
      </Text>
      <FormControl isInvalid={!!props.errors.birthdate}>
        <InputField
          max={moment().subtract(18, 'years').format('YYYY-MM-DD')}
          type="date"
          width="100%"
          defaultValue={props.birthdate}
          {...props.register('birthdate', {
            required: 'Devi inserire la data di nascita',
            validate: v => (!!v && moment().subtract(18, 'years').isSameOrAfter(v)) || 'Devi essere maggiorenne per unirti a Sbam!',
          })}
          onChange={({ target: { value } }) => {
            props.setValue('birthdate', value)
          }}
        />
      </FormControl>
    </Box>
  )
}

export default BirthdayField
