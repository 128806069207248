import { Flex, useToast } from '@chakra-ui/react'
import EmailField from './email-field'
import useQueryParams from 'hooks/use-query-params'
import React, { useEffect } from 'react'
import PasswordFields from './password-fields'

function AccountDetailsFields(props: { errors: any; register: any; password: any; queryParams: URLSearchParams }) {
  const email: string | null = props.queryParams.get('email') ?? null
  const isSSO: string = props.queryParams.get('isSSO') ?? 'false'

  return (
    <Flex flexDir="column" gap="2rem" mx="1.25rem">
      <EmailField label="Email dell'account" placeholder="marcorossi@gmail.com" register={props.register} email={email} errors={props.errors} />
      {isSSO === 'false' && <PasswordFields errors={props.errors} register={props.register} password={props.password} />}
    </Flex>
  )
}

export default AccountDetailsFields
