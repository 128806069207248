import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectorAuth } from '../features/auth/auth.slice'

export function useIsNumberConfirmed() {
  const { user } = useSelector(selectorAuth)
  const [isNumberConfirmed, setIsNumberConfirmed] = useState(false)

  useEffect(() => {
    setIsNumberConfirmed(true)
  }, [user])

  return isNumberConfirmed
}
