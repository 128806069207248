import { createIcon } from '@chakra-ui/react'
import React from 'react'

export const CoinIcon = createIcon({
  displayName: 'CoinIcon',
  viewBox: '0 0 28.284 28.284',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g transform="translate(3689.683 -1118.643) rotate(45)">
      <circle cx="10" cy="10" r="10" transform="translate(-1808 3390)" fill="#ccc" />
      <circle cx="8" cy="8" r="8" transform="translate(-1806 3392)" fill="#bbb" />
      <path d="M4,18V2A9.994,9.994,0,0,1,6,.832V19.168A9.993,9.993,0,0,1,4,18Z" transform="translate(-1808 3390)" fill="#aaa" />
      <path
        d="M0,17.168v-16A9.993,9.993,0,0,1,2,0V18.336A9.994,9.994,0,0,1,0,17.168Z"
        transform="translate(-1792 3409.168) rotate(180)"
        fill="#aaa"
      />
    </g>
  ),
})

export const TicketIcon = createIcon({
  displayName: 'TicketIcon',
  viewBox: '0 0 24 24',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      d="M528.18,172.78,526.4,171l-6.764,6.764,1.309,1.309-.872.872-1.309-1.309L512,185.4l1.78,1.78a4.93,4.93,0,0,1,6.04,6.04L521.6,195l6.764-6.764-1.309-1.309.872-.872,1.309,1.309L536,180.6l-1.78-1.78a4.93,4.93,0,0,1-6.04-6.04Zm-2.435,11.093-.872.872-2.618-2.618.872-.872Z"
      transform="translate(-512 -171)"
      fill="#0071bc"
    />
  ),
})

export const PositionIcon = createIcon({
  displayName: 'PositionIcon',
  viewBox: '0 0 18 26',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      d="M3426-3197h0a59.719,59.719,0,0,1-4.5-5.927,37.634,37.634,0,0,1-3.094-5.522A14.222,14.222,0,0,1,3417-3214a9.01,9.01,0,0,1,9-9,9.01,9.01,0,0,1,9,9,14.225,14.225,0,0,1-1.406,5.552,37.634,37.634,0,0,1-3.094,5.522A59.786,59.786,0,0,1,3426-3197Zm0-21a4,4,0,0,0-4,4,4,4,0,0,0,4,4,4,4,0,0,0,4-4A4,4,0,0,0,3426-3218Z"
      transform="translate(-3417 3223)"
      fill="#fff"
    />
  ),
})

export const AttendeesIcon = createIcon({
  displayName: 'AttendeesIcon',
  viewBox: '0 0 28 17',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g transform="translate(2548 -3488)">
      <path d="M16,17H0A8.014,8.014,0,0,1,5.577,9.375a5,5,0,1,1,4.845,0A8.014,8.014,0,0,1,16,17h0Z" transform="translate(-2542 3488)" fill="#fff" />
      <path
        d="M23,18H18a9.02,9.02,0,0,0-4.644-7.874A5.971,5.971,0,0,0,15,6c0-.152-.006-.3-.017-.456a4,4,0,0,1,4.143,6.844A6.013,6.013,0,0,1,23,18Z"
        transform="translate(-2543 3487)"
        fill="#fff"
      />
      <path
        d="M5,18H0a6.011,6.011,0,0,1,3.874-5.611A4,4,0,0,1,8.017,5.545C8.006,5.693,8,5.846,8,6a5.972,5.972,0,0,0,1.643,4.126A9.018,9.018,0,0,0,5,18Z"
        transform="translate(-2548 3487)"
        fill="#fff"
      />
    </g>
  ),
})

export const AttendeesIconNeg = createIcon({
  displayName: 'AttendeesIconNeg',
  viewBox: '0 0 28 17',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g transform="translate(2548 -3488)">
      <path d="M16,17H0A8.014,8.014,0,0,1,5.577,9.375a5,5,0,1,1,4.845,0A8.014,8.014,0,0,1,16,17h0Z" transform="translate(-2542 3488)" />
      <path
        d="M23,18H18a9.02,9.02,0,0,0-4.644-7.874A5.971,5.971,0,0,0,15,6c0-.152-.006-.3-.017-.456a4,4,0,0,1,4.143,6.844A6.013,6.013,0,0,1,23,18Z"
        transform="translate(-2543 3487)"
      />
      <path
        d="M5,18H0a6.011,6.011,0,0,1,3.874-5.611A4,4,0,0,1,8.017,5.545C8.006,5.693,8,5.846,8,6a5.972,5.972,0,0,0,1.643,4.126A9.018,9.018,0,0,0,5,18Z"
        transform="translate(-2548 3487)"
      />
    </g>
  ),
})

export const SearchIcon = createIcon({
  displayName: 'SearchIcon',
  viewBox: '0 0 24.167 24.167',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      fill="currentColor"
      id="Tracciato_76"
      data-name="Tracciato 76"
      d="M14.083,2A12.083,12.083,0,1,0,26.167,14.083,12.088,12.088,0,0,0,14.083,2Zm0,21.75a9.667,9.667,0,1,1,9.667-9.667A9.68,9.68,0,0,1,14.083,23.75ZM7.438,20.729l9.075-4.217,4.217-9.075-9.075,4.217Zm6.646-7.975a1.329,1.329,0,1,1-1.329,1.329A1.325,1.325,0,0,1,14.083,12.754Z"
      transform="translate(-2 -2)"
    />
  ),
})
export const CreateEventIcon = createIcon({
  displayName: 'CreateEventIcon',
  viewBox: '0 0 26 26',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <circle cx="13" cy="13" r="11.75" fill="none" stroke="currentColor" strokeWidth="2.5" />
      <path d="M11.834 19.084h2.417V14.25h4.833v-2.416H14.25V7h-2.416v4.833H7v2.417h4.833z" fill="currentColor" />
    </>
  ),
})
export const HypeIcon = createIcon({
  displayName: 'HypeIcon',
  viewBox: '0 0 19.333 25.675',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      d="M9.663 25.675A9.666 9.666 0 0 1 0 16.107 16.639 16.639 0 0 1 3.9 5.365a5.8 5.8 0 0 0 4.021 3.876C12.556 6.265 11.479 0 11.479 0a20.678 20.678 0 0 1 7.854 16.107 9.667 9.667 0 0 1-9.568 9.568zM2.4 16.091a7.269 7.269 0 0 0 7.264 7.185h.077a7.266 7.266 0 0 0 7.192-7.176 18.386 18.386 0 0 0-1.849-7.89 18.213 18.213 0 0 0-1.637-2.722 10.059 10.059 0 0 1-4.229 5.773 2.4 2.4 0 0 1-1.783.33 7.865 7.865 0 0 1-3.526-1.9 14.252 14.252 0 0 0-1.509 6.4zm7.228 5.983a3.78 3.78 0 0 1-3.743-3.742 8.09 8.09 0 0 1 3.073-6.3s-.421 2.451 1.392 3.614a2.269 2.269 0 0 0 1.573-1.516 6.51 6.51 0 0 1 1.526 4.2 3.782 3.782 0 0 1-3.779 3.742z"
      fill="currentColor"
    />
  ),
})
export const AgendaIcon = createIcon({
  displayName: 'AgendaIcon',
  viewBox: '0 0 21.75 24.167',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      fill="currentColor"
      id="Tracciato_84"
      data-name="Tracciato 84"
      d="M22.333,3.417H21.125V1H18.708V3.417H9.042V1H6.625V3.417H5.417a2.406,2.406,0,0,0-2.4,2.417L3,22.75a2.416,2.416,0,0,0,2.417,2.417H22.333A2.424,2.424,0,0,0,24.75,22.75V5.833A2.424,2.424,0,0,0,22.333,3.417Zm0,19.333H5.417V10.667H22.333Zm0-14.5H5.417V5.833H22.333Zm-2.417,6.042H13.875v6.042h6.042Z"
      transform="translate(-3 -1)"
    />
  ),
})
export const EventsIcon = createIcon({
  displayName: 'EventsIcon',
  viewBox: '0 0 24.996 25',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g data-name="Raggruppa 141" transform="translate(7911 -4059.5)">
      <circle data-name="Ellisse 103" cx="2.5" cy="2.5" r="2.5" transform="translate(-7911 4059.5)" fill="#fff" />
      <circle data-name="Ellisse 104" cx="2.5" cy="2.5" r="2.5" transform="translate(-7911 4068.5)" fill="#fff" />
      <circle data-name="Ellisse 105" cx="2.5" cy="2.5" r="2.5" transform="translate(-7911 4077.5)" fill="#fff" />
      <rect data-name="Rettangolo 143" width="17" height="4" rx="1" transform="translate(-7903 4078)" fill="#fff" />
      <rect data-name="Rettangolo 144" width="17" height="4" rx="1" transform="translate(-7903 4069)" fill="#fff" />
      <rect data-name="Rettangolo 145" width="17" height="4" rx="1" transform="translate(-7903 4060)" fill="#fff" />
    </g>
  ),
})

export const ChatIcon = createIcon({
  displayName: 'ChatIcon',
  viewBox: '0 0 30 30',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g data-name="Raggruppa 9" transform="translate(-286 -15)">
      <rect data-name="Rettangolo 37" width="30" height="30" transform="translate(286 15)" fill="#fff" opacity="0" />
      <g data-name="Unione 2" transform="translate(314 42) rotate(180)" fill="#fff">
        <path
          d="M 12.99954986572266 23.25024223327637 C 6.245130062103271 23.25024223327637 0.75 18.20366287231445 0.75 12.00057220458984 C 0.75 9.618182182312012 1.550830006599426 7.341691970825195 3.065920114517212 5.417201995849609 L 3.360219955444336 5.043392181396484 L 3.147449970245361 4.617862224578857 L 1.454714775085449 1.23238480091095 L 5.824130058288574 2.577032089233398 L 6.139279842376709 2.67402195930481 L 6.423799991607666 2.507362127304077 C 8.386549949645996 1.357692122459412 10.66040992736816 0.7500020861625671 12.99954986572266 0.7500020861625671 C 19.75447082519531 0.7500020861625671 25.25 5.796982288360596 25.25 12.00057220458984 C 25.25 18.20366287231445 19.75447082519531 23.25024223327637 12.99954986572266 23.25024223327637 Z"
          stroke="none"
        />
        <path
          d="M 12.99954986572266 1.500001907348633 C 10.79353046417236 1.500001907348633 8.650749206542969 2.072122573852539 6.80286979675293 3.154521942138672 L 6.233829498291016 3.487833023071289 L 5.603540420532227 3.293861389160156 L 2.909433364868164 2.464775085449219 L 3.818269729614258 4.282451629638672 L 4.243799209594727 5.133512496948242 L 3.655210494995117 5.881141662597656 C 2.245260238647461 7.67207145690918 1.5 9.788131713867188 1.5 12.00057220458984 C 1.5 17.79011154174805 6.658679962158203 22.50024223327637 12.99954986572266 22.50024223327637 C 19.34091949462891 22.50024223327637 24.5 17.79011154174805 24.5 12.00057220458984 C 24.5 6.210542678833008 19.34091949462891 1.500001907348633 12.99954986572266 1.500001907348633 M 0 1.9073486328125e-06 L 6.044729232788086 1.860212326049805 C 8.055940628051758 0.6821517944335938 10.44141006469727 1.9073486328125e-06 12.99954986572266 1.9073486328125e-06 C 20.17971992492676 1.9073486328125e-06 26 5.372991561889648 26 12.00057220458984 C 26 18.62725257873535 20.17971992492676 24.00024223327637 12.99954986572266 24.00024223327637 C 5.820280075073242 24.00024223327637 0 18.62725257873535 0 12.00057220458984 C 0 9.367172241210938 0.9186992645263672 6.932161331176758 2.476629257202148 4.953271865844727 L 0 1.9073486328125e-06 Z"
          stroke="none"
          fill="#FF7436"
        />
      </g>
    </g>
  ),
})
export const UploadIcon = createIcon({
  displayName: 'UploadIcon',
  viewBox: '0 0 21.176 17.585',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      d="M3423-2438a1,1,0,0,1-1-1v-5h14v5a1,1,0,0,1-1,1Zm-3.586-6a1,1,0,0,1-.707-1.707l9.586-9.586a1,1,0,0,1,1.414,0l9.587,9.586a1,1,0,0,1-.708,1.707Z"
      transform="translate(-3418.412 2455.586)"
      fill="#fff"
    />
  ),
})

export const BackArrow = createIcon({
  displayName: 'PinIcon',
  viewBox: '0 0 10.828 18.828',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g id="Raggruppa_20" data-name="Raggruppa 20" transform="translate(-13.634 1.317)">
      <line
        id="Linea_7"
        data-name="Linea 7"
        x1="8"
        y1="8"
        transform="translate(15.049 0.097)"
        fill="none"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Linea_8"
        data-name="Linea 8"
        y1="8"
        x2="8"
        transform="translate(15.049 8.097)"
        fill="none"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  ),
})

export const NotificationArrow = createIcon({
  displayName: 'PinIcon',
  viewBox: '0 0 10.828 18.828',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g id="Raggruppa_20" data-name="Raggruppa 20" transform="translate(-13.634 1.317)">
      <line
        id="Linea_7"
        data-name="Linea 7"
        x1="8"
        y1="8"
        transform="translate(15.049 0.097)"
        fill="none"
        stroke="#011936"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Linea_8"
        data-name="Linea 8"
        y1="8"
        x2="8"
        transform="translate(15.049 8.097)"
        fill="none"
        stroke="#011936"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  ),
})
export const SbamCoinArrow = createIcon({
  displayName: 'PinIcon',
  viewBox: '0 0 15.23 22',
  path: (
    <path
      id="Tracciato_20"
      data-name="Tracciato 20"
      d="M324.242,144.54l-7.719-3.771s-9.86,5.429-6.987,15.028c0,0,2.423-2.057,8-1.829,0,0-4.61,2.1-4,8.8,0,0,2.535-5.029,7.55-6.914L322.1,149.9s-6.029-1.984-10.592,2.416c0,0,2.648-5.829,12.733-7.771"
      transform="translate(-309.012 -140.769)"
      fill="#fff"
    />
  ),
})

export const CheckboxIcon = createIcon({
  displayName: 'CheckboxIcon',
  viewBox: '0 0 14.243 10.243',
  path: (
    <g id="Raggruppa_238" data-name="Raggruppa 238" transform="translate(10.469 -11.209) rotate(90)">
      <line
        id="Linea_7"
        data-name="Linea 7"
        x1="6"
        y1="6"
        transform="translate(13.33 -1.653)"
        fill="none"
        stroke="#ff7436"
        strokeLinecap="round"
        strokeWidth="3"
      />
      <line
        id="Linea_8"
        data-name="Linea 8"
        y1="4"
        x2="4"
        transform="translate(15.33 4.347)"
        fill="none"
        stroke="#ff7436"
        strokeLinecap="round"
        strokeWidth="3"
      />
    </g>
  ),
})

export const MenuIcon = createIcon({
  displayName: 'MenuIcon',
  viewBox: '0 0 24 24',
  path: <path d="M12,7a2,2,0,1,0-2-2A2,2,0,0,0,12,7Zm0,10a2,2,0,1,0,2,2A2,2,0,0,0,12,17Zm0-7a2,2,0,1,0,2,2A2,2,0,0,0,12,10Z" fill="#FFF"></path>,
})

export const SbamIcon = createIcon({
  displayName: 'SbamIcon',
  path: (
    <path
      id="Tracciato_20"
      data-name="Tracciato 20"
      d="M322.858,144.2l-7.017-3.429s-8.964,4.935-6.351,13.662c0,0,2.2-1.87,7.273-1.662,0,0-4.191,1.906-3.637,8a14.243,14.243,0,0,1,6.864-6.286l.922-5.417s-5.481-1.8-9.63,2.2c0,0,2.407-5.3,11.576-7.065"
      transform="translate(-309.012 -140.769)"
      fill="#ff7436"
    />
  ),
})

export const EuroIcon = createIcon({
  displayName: 'EuroIcon',
  path: (
    <svg width="24" height="45" viewBox="0 0 24 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3252 29.34C14.4652 29.34 15.4652 29.09 16.3252 28.59C17.1852 28.09 17.8652 27.36 18.3652 26.4H23.1652C22.4652 28.54 21.2352 30.21 19.4752 31.41C17.7352 32.59 15.6952 33.18 13.3552 33.18C10.9552 33.18 8.84516 32.54 7.02516 31.26C5.22516 29.96 3.97516 28.23 3.27516 26.07H0.725156V23.58H2.79516C2.75516 23.1 2.73516 22.74 2.73516 22.5C2.73516 22.22 2.75516 21.84 2.79516 21.36H0.725156V18.87H3.30516C4.00516 16.71 5.25516 14.99 7.05516 13.71C8.87516 12.43 10.9752 11.79 13.3552 11.79C15.6952 11.79 17.7352 12.39 19.4752 13.59C21.2352 14.79 22.4652 16.45 23.1652 18.57H18.3652C17.8652 17.61 17.1852 16.89 16.3252 16.41C15.4652 15.91 14.4652 15.66 13.3252 15.66C12.1052 15.66 11.0152 15.94 10.0552 16.5C9.11516 17.06 8.38516 17.85 7.86516 18.87H13.6252V21.36H7.11516C7.07516 21.84 7.05516 22.22 7.05516 22.5C7.05516 22.74 7.07516 23.1 7.11516 23.58H13.6252V26.07H7.83516C8.35516 27.09 9.09516 27.89 10.0552 28.47C11.0152 29.05 12.1052 29.34 13.3252 29.34Z"
        fill="white"
      />
    </svg>
  ),
})
