import React from 'react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { IoMdShare } from 'react-icons/io'
import { Share } from '@capacitor/share'
import { Capacitor } from '@capacitor/core'
import { Clipboard } from '@capacitor/clipboard'

function EventCreatedModal(props) {
  const toast = useToast()

  return (
    <Modal isCentered closeOnOverlayClick={false} isOpen={props.isOpen} onClose={props.onClose} size="sm">
      <ModalOverlay bg="blackAlpha.400" backdropFilter="blur(3px)" />
      <ModalContent>
        <ModalHeader>Yeah, pubblicato!</ModalHeader>
        <ModalCloseButton size="lg" />
        <ModalBody>
          <Text>Condividi l'evento come storia sul tuo Instagram taggando @sbam.social per aumentarne i partecipanti.</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            width="100%"
            onClick={async () => {
              if (!Capacitor.isNativePlatform()) {
                await Clipboard.write({ url: `${process.env.REACT_APP_APP_URL}/event/${props.eventId}` })
                toast({
                  title: 'URL evento copiato in clipboard',
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                  position: 'bottom',
                })
              } else {
                await Share.share({
                  dialogTitle: 'Condividi evento sui social',
                  title: props.eventTitle,
                  text: props.eventDesc,
                  url: `${process.env.REACT_APP_APP_URL}/event/${props.eventId}`,
                })
              }
              props.onClose()
            }}>
            <Box mr="10px">
              <IoMdShare />
            </Box>
            Spargi la voce
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EventCreatedModal
