import { CheckIcon } from '@chakra-ui/icons'
import { Box, Flex, IconButton, Progress } from '@chakra-ui/react'
import { Message } from '@twilio/conversations'
import { AppContext } from 'app.context'
import React, { MouseEventHandler, TouchEventHandler, useCallback, useContext, useState } from 'react'
import { useMessageContext } from '../../context/message-context'
import { Poll, PollMessageAttributes, PollOption } from '../../hooks/use-send-poll-mutation'
import useUpdatePollMessageMutation from '../../hooks/use-update-poll-message-mutation'
import PollHeader from './poll-header'
import ShowVotesModal from './show-votes-modal'

const removePrevVote = (options: PollOption[], currentOption: PollOption, prevVote: string) => {
  const maybePrevVote = options.find(({ votes }) => votes.find(({ identity }) => identity === prevVote))

  if (maybePrevVote && maybePrevVote !== currentOption) {
    const indexOfVoteToRemove = maybePrevVote.votes.reduce((acc, { identity }, index) => (identity === prevVote ? index : acc), 0)
    maybePrevVote.votes.splice(indexOfVoteToRemove, 1)
  }
}

const addVote = (currentOption: PollOption, identity: string) => {
  const exist = currentOption.votes.find(v => v.identity === identity)

  if (exist) {
    const indexOf = currentOption.votes.indexOf(exist)

    currentOption.votes.splice(indexOf, 1)
  } else {
    currentOption.votes.push({ identity, timestamp: Date.now() })
  }
}

const blockDrawerOpeningOnMouseDown: MouseEventHandler<HTMLDivElement> = e => {
  e.stopPropagation()
  e.preventDefault()
}

const blockDrawerOpeningOnTouchStart: TouchEventHandler<HTMLDivElement> = e => {
  e.stopPropagation()
  e.preventDefault()
}

function PollMessage({ msg }: { msg: Message }) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const { setHideMessage } = useMessageContext()
  const attributes: PollMessageAttributes = msg.attributes as PollMessageAttributes

  const { client: twilioClient } = useContext(AppContext)
  const identity = twilioClient!.user.identity

  const updatePollMessage = useUpdatePollMessageMutation(msg.sid)

  const handleOnOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleOnCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  try {
    const poll: Poll = attributes.poll
    const total = poll.options.reduce((acc, { votes }) => acc + votes.length, 0)
    const singleAnswers = !poll.multipleAnswers

    return (
      <Flex className="poll-message" color="#465362" lineHeight="1.2rem" direction="column" gap="2rem">
        <Flex direction="column" gap=".25rem">
          <Box fontWeight="600">{poll.question}</Box>
          {poll.multipleAnswers && <Box>Rispondi ad una o più opzioni</Box>}
        </Flex>

        <Flex direction="column" gap="1.5rem">
          {poll.options.map((option: PollOption) => {
            const votes = option.votes
            const progressBarValue = votes.length ? Math.round((votes.length / total) * 100) : 0

            const handleOnClick = () => {
              if (singleAnswers) {
                removePrevVote(poll.options, option, identity)
                addVote(option, identity)
              } else {
                addVote(option, identity)
              }

              updatePollMessage.mutate(msg)
            }

            return (
              <Flex key={option.key} direction="row" gap="1rem" width="100%" onClick={handleOnClick}>
                <VotedIcon option={option} />

                <Flex key={option.key} direction="column" gap=".75rem" cursor="pointer" width="100%">
                  <PollHeader option={option} />

                  <Progress size="sm" colorScheme="orange" borderRadius="1rem" value={progressBarValue} />
                </Flex>
              </Flex>
            )
          })}
        </Flex>

        <Flex
          color="brand.500"
          cursor="pointer"
          justifyContent="center"
          onClick={handleOnOpenModal}
          onMouseDown={blockDrawerOpeningOnMouseDown}
          onTouchStart={blockDrawerOpeningOnTouchStart}>
          Mostra voti
          <ShowVotesModal poll={poll} isOpen={isModalOpen} onClose={handleOnCloseModal} />
        </Flex>
      </Flex>
    )
  } catch (e) {
    console.error('BROKEN MESSAGE: ', e)
    setHideMessage(true)

    return <></>
  }
}

function VotedIcon({ option }: { option: PollOption }) {
  const votes = option.votes

  const { client: twilioClient } = useContext(AppContext)
  const identity = twilioClient!.user.identity

  const voted = votes.find(o => o.identity === identity)

  if (voted) {
    return <IconButton isRound={true} variant="solid" aria-label="Voted" fontSize="10px" size="xs" icon={<CheckIcon />} />
  }

  return <IconButton isRound={true} borderColor="lightGray" variant="outline" aria-label="NotVoted" fontSize="10px" size="xs" />
}

export default PollMessage
