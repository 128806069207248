import { Flex } from '@chakra-ui/react'
import React from 'react'
import LoginScreenFormSubmit from './login-screen-form-submit'
import HelpButton from './help-button'

const LoginScreenFormFooter = (props: { isLoading: boolean }) => {
  return (
    <Flex flexDir="column" gap="2rem" position="absolute" bottom="2rem" left="0" width="100%" alignItems="center">
      <HelpButton />
      <LoginScreenFormSubmit isLoading={props.isLoading} />
    </Flex>
  )
}

export default LoginScreenFormFooter
