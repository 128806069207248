import { useGetFirstConversationPage, useGetOptimizedRestConversations } from 'hooks/use-chat'

const useGetAllConversations = () => {
  const firstPage = useGetFirstConversationPage()
  const rest = useGetOptimizedRestConversations(firstPage.data)

  return [{ ...firstPage, data: firstPage?.data?.items }, rest]
}

export default useGetAllConversations
