import Logo from '@assets/logo-lightning-white.svg'
import { Box, Flex } from '@chakra-ui/react'
import { SYSTEM_AUTHOR_NAME_AI } from 'costants'
import React from 'react'

function ChatBoxHeader({ msg }) {
  if (msg.author === 'system') {
    return <ChatBoxHeaderSystem />
  }

  return <></>
}

function ChatBoxHeaderSystem() {
  return (
    <Flex alignItems="center" cursor="pointer" gap=".5rem">
      <img
        src={Logo}
        alt="system-avatar"
        style={{ width: '1.5rem', height: '1.5rem', padding: '.25rem', borderRadius: '1rem', backgroundColor: '#FF7436' }}
      />

      <Box fontWeight="600" color="#011936">
        {SYSTEM_AUTHOR_NAME_AI}
      </Box>
    </Flex>
  )
}

export default ChatBoxHeader
