import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import UserAvatar from 'components/shared/user-avatar/user-avatar'
import React from 'react'

const EventParticipantContainer = ({ children }) => (
  <Box height="fit-content" width="100%">
    <HStack spacing="10px" overflowX="auto" height="fit-content" width="100%">
      {children}
    </HStack>
  </Box>
)

const EventParticipants = props => {
  const goToProfile = (userId: string) => {
    props.navigate(`/profile/${userId}`)
  }

  if (!props.isOrganizer && !props.attendance) {
    const organizerAttendance = props.event.attendances.find(a => a.user?._id === props.event.organizer?._id)

    if (!organizerAttendance) {
      return <></>
    }

    return (
      <EventParticipantContainer>
        {
          <VStack spacing="4px" onClick={() => goToProfile(organizerAttendance.user?._id)}>
            <UserAvatar
              size="lg"
              borderWidth="3px"
              borderColor="#D9D9D9"
              src={organizerAttendance.user?.avatar}
              key={organizerAttendance.user?._id}
            />

            <Text fontFamily="Poppins" fontWeight="300" fontSize="12px" lineHeight="18px" textAlign="center" textColor="#787B80">
              {organizerAttendance.user?.firstName}
            </Text>
          </VStack>
        }
      </EventParticipantContainer>
    )
  }

  return (
    <EventParticipantContainer>
      {props.event.attendances.map(e => {
        return (
          <VStack spacing="4px" onClick={() => goToProfile(e.user?._id)}>
            <UserAvatar size="lg" borderWidth="3px" borderColor="#D9D9D9" src={e.user?.avatar} key={e.user?._id} />

            <Text fontFamily="Poppins" fontWeight="300" fontSize="12px" lineHeight="18px" textAlign="center" textColor="#787B80">
              {e.user?.firstName}
            </Text>
          </VStack>
        )
      })}
    </EventParticipantContainer>
  )
}

export default EventParticipants
