import React from 'react'
import getTimeFromMessage from '@screens/chat/hooks/get-time-from-message'
import { Box } from '@chakra-ui/react'

function ChatBoxFooter({ msg }) {
  const time = getTimeFromMessage(msg)

  return (
    <Box color="#011936" alignSelf="flex-end" fontWeight="400" fontSize="12px" pt=".5rem">
      {time}
    </Box>
  )
}

export default ChatBoxFooter
