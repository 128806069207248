import { User } from '@twilio/conversations'
import { AppContext } from 'app.context'
import { useContext } from 'react'
import { useQuery } from 'react-query'

const useGetUserFromMessageQuery = (message, options = {}) => {
  const { client: twilioClient } = useContext(AppContext)

  return useQuery(
    `message_user_${message?.sid}`,
    async (): Promise<User | undefined> => {
      if (message.author === 'system') {
        return undefined
      }

      const user: User | undefined = message.author ? await twilioClient!.getUser(message.author) : undefined

      return user
    },
    {
      enabled: !!twilioClient && !!message,
      ...options,
    },
  )
}

export const getAuthorNameFromUser = (user: User | undefined) => user?.friendlyName ?? user?.identity ?? 'Sconosciuto'

export default useGetUserFromMessageQuery
