import { useMutation } from 'react-query'
import { Message } from '@twilio/conversations'

const useUpdatePollMessageMutation = (sid: string, options = {}) => {
  return useMutation(
    `update-poll-message-${sid}`,
    async (message: Message) => {
      const removedMessage = await message.updateAttributes({ ...(message.attributes as object) })

      return removedMessage
    },
    {
      ...options,
    },
  )
}

export default useUpdatePollMessageMutation
