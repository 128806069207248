import { Button, Flex, Text, Box, useToast, Grid, GridItem, Heading } from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { selectorAuth, setAuthState, setUser } from 'features/auth/auth.slice'
import Api from 'services/api/api.service'
import { Notification } from 'typings/database.d'

import Wrapper from 'components/shared/wrapper/wrapper.component'
import NotificationRow from 'components/shared/notification-row/notification-row.component'

const ShopScreen = () => {
  const toast = useRef(useToast())
  // const { isLoading, error, data, refetch } = useQuery('notifications', () => Api.notifications()) as {
  //   error: String
  //   isLoading: boolean
  //   data: any
  //   refetch: any
  // }
  // useEffect(() => {
  //   if (error) {
  //     toast.current({
  //       title: error,
  //       status: 'error',
  //       duration: 2500,
  //       isClosable: true,
  //       position: 'top',
  //     })
  //   }
  // }, [error])
  // const throttle = useRef<any>()
  // const notifications = data?.data || []
  const sorry = () => {
    toast.current({
      title: (
        <span>
          Stiamo costruendo lo store, nel frattempo se vuoi ricevere altre saette{' '}
          <a style={{ textDecoration: 'underline' }} href="https://sbam.social/supporto">
            contattaci qui
          </a>
        </span>
      ),
      status: 'info',
      isClosable: true,
      position: 'bottom',
    })
  }
  return (
    <Wrapper>
      <Flex direction="column">
        <Box pt="20px" pb="20px" fontSize="22px" fontWeight="700">
          Gestisci saette
        </Box>
      </Flex>
      <Flex flex={1} direction="column">
        {/*onClick={sorry}*/}
        <Text mb={6}>
          Se hai bisogno di altre saette{' '}
          <a href="https://sbam.social/supporto" target="_blank" rel="noreferrer">
            <Button variant="link" fontSize="16px">
              contatta l'assistenza
            </Button>
          </a>
          .
        </Text>
        <Text color="gray.400">
          Usa le saette per partecipare e organizzare gli eventi. Chi organizza può anche richiedere un contributo economico indicato in ciascun
          evento come contributo di partecipazione.
        </Text>
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 338 634">
          <defs>
            <filter id="Rettangolo_654" x="0" y="0" width="338" height="113" filterUnits="userSpaceOnUse">
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="3" result="blur" />
              <feFlood floodOpacity="0.161" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
            <filter id="Rettangolo_654-2" x="0" y="105" width="338" height="113" filterUnits="userSpaceOnUse">
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="3" result="blur-2" />
              <feFlood floodOpacity="0.161" />
              <feComposite operator="in" in2="blur-2" />
              <feComposite in="SourceGraphic" />
            </filter>
            <filter id="Rettangolo_724" x="0" y="220" width="118" height="118" filterUnits="userSpaceOnUse">
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="3" result="blur-3" />
              <feFlood floodColor="#00192f" floodOpacity="0.161" />
              <feComposite operator="in" in2="blur-3" />
              <feComposite in="SourceGraphic" />
            </filter>
            <clipPath id="clip-path">
              <rect id="Rettangolo_737" data-name="Rettangolo 737" width="100" height="100" rx="15" transform="translate(28 557)" fill="#f7f7f7" />
            </clipPath>
            <filter id="Rettangolo_635" x="0" y="0" width="338" height="113" filterUnits="userSpaceOnUse">
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="3" result="blur-4" />
              <feFlood floodOpacity="0.161" />
              <feComposite operator="in" in2="blur-4" />
              <feComposite in="SourceGraphic" />
            </filter>
            <clipPath id="clip-path-2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rettangolo_654)">
                <rect id="Rettangolo_654-3" data-name="Rettangolo 654" width="320" height="95" rx="15" transform="translate(28 211)" fill="#ff7436" />
              </g>
            </clipPath>
            <filter id="Rettangolo_635-2" x="0" y="105" width="338" height="113" filterUnits="userSpaceOnUse">
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="3" result="blur-5" />
              <feFlood floodOpacity="0.161" />
              <feComposite operator="in" in2="blur-5" />
              <feComposite in="SourceGraphic" />
            </filter>
            <clipPath id="clip-path-3">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rettangolo_654-2)">
                <rect
                  id="Rettangolo_654-4"
                  data-name="Rettangolo 654"
                  width="320"
                  height="95"
                  rx="15"
                  transform="translate(-6880 9564)"
                  fill="#ff7436"
                />
              </g>
            </clipPath>
            <filter id="Rettangolo_733" x="220" y="330" width="118" height="118" filterUnits="userSpaceOnUse">
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="3" result="blur-6" />
              <feFlood floodColor="#00192f" floodOpacity="0.161" />
              <feComposite operator="in" in2="blur-6" />
              <feComposite in="SourceGraphic" />
            </filter>
            <filter id="Rettangolo_734" x="220" y="220" width="118" height="118" filterUnits="userSpaceOnUse">
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="3" result="blur-7" />
              <feFlood floodColor="#00192f" floodOpacity="0.161" />
              <feComposite operator="in" in2="blur-7" />
              <feComposite in="SourceGraphic" />
            </filter>
            <filter id="Rettangolo_727" x="110" y="330" width="118" height="118" filterUnits="userSpaceOnUse">
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="3" result="blur-8" />
              <feFlood floodColor="#00192f" floodOpacity="0.161" />
              <feComposite operator="in" in2="blur-8" />
              <feComposite in="SourceGraphic" />
            </filter>
            <filter id="Rettangolo_728" x="110" y="220" width="118" height="118" filterUnits="userSpaceOnUse">
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="3" result="blur-9" />
              <feFlood floodColor="#00192f" floodOpacity="0.161" />
              <feComposite operator="in" in2="blur-9" />
              <feComposite in="SourceGraphic" />
            </filter>
            <filter id="Rettangolo_729" x="0" y="330" width="118" height="118" filterUnits="userSpaceOnUse">
              <feOffset dy="3" />
              <feGaussianBlur stdDeviation="3" result="blur-10" />
              <feFlood floodColor="#00192f" floodOpacity="0.161" />
              <feComposite operator="in" in2="blur-10" />
              <feComposite in="SourceGraphic" />
            </filter>
            <clipPath id="clip-path-5">
              <rect id="Rettangolo_736" data-name="Rettangolo 736" width="100" height="100" rx="15" transform="translate(28 557)" fill="#fff" />
            </clipPath>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rettangolo_724)">
            <rect id="Rettangolo_724-2" data-name="Rettangolo 724" width="100" height="100" rx="15" transform="translate(9 226)" fill="#fff" />
          </g>
          <g id="Gruppo_di_maschere_60" data-name="Gruppo di maschere 60" transform="translate(-19 -331)" clipPath="url(#clip-path)">
            <path
              id="Tracciato_194"
              data-name="Tracciato 194"
              d="M378.242,157.912l-35.087-17.143S298.336,165.444,311.4,209.08c0,0,11.012-9.351,36.367-8.312,0,0-20.954,9.532-18.184,40,0,0,11.525-22.857,34.319-31.429l4.61-27.085s-27.4-9.018-48.148,10.982c0,0,12.037-26.494,57.88-35.325"
              transform="matrix(0.966, 0.259, -0.259, 0.966, -174.158, 323.795)"
              fill="#f7f7f7"
            />
          </g>
          <g id="Raggruppa_264" data-name="Raggruppa 264" transform="translate(-19 -140)">
            <g transform="matrix(1, 0, 0, 1, 19, 140)" filter="url(#Rettangolo_635)">
              <rect id="Rettangolo_635-3" data-name="Rettangolo 635" width="320" height="95" rx="15" transform="translate(9 6)" fill="#ff7436" />
            </g>
            <g id="Gruppo_di_maschere_27" data-name="Gruppo di maschere 27" transform="translate(0 -65)" opacity="0.501" clipPath="url(#clip-path-2)">
              <path
                id="Sottrazione_17"
                data-name="Sottrazione 17"
                d="M7840.751-9018.539a2.22,2.22,0,0,1-2.142-1.642l-1.146-4.28a2.2,2.2,0,0,1,.221-1.678,2.2,2.2,0,0,1,1.345-1.034l55.628-14.905a2.222,2.222,0,0,1,.571-.075,2.218,2.218,0,0,1,2.141,1.641l1.148,4.28a2.22,2.22,0,0,1-1.566,2.712l-55.631,14.905A2.146,2.146,0,0,1,7840.751-9018.539Zm-.73-13.486h0l-16.577-23.192a6.644,6.644,0,0,1-1,.384,6.716,6.716,0,0,1-1.724.229,6.654,6.654,0,0,1-6.417-4.929,6.592,6.592,0,0,1,.671-5.043,6.612,6.612,0,0,1,4.041-3.1,6.638,6.638,0,0,1,1.723-.228,6.654,6.654,0,0,1,6.417,4.925,6.672,6.672,0,0,1,.121,2.806l11.224,3.212a4.39,4.39,0,0,0,1.216.172,4.43,4.43,0,0,0,4.278-3.306l5.789-21.989a6.627,6.627,0,0,1-3.693-4.312,6.655,6.655,0,0,1,4.7-8.141,6.633,6.633,0,0,1,1.722-.228,6.653,6.653,0,0,1,6.416,4.928,6.62,6.62,0,0,1-1.041,5.581l16.008,16.147a4.394,4.394,0,0,0,3.146,1.316,4.393,4.393,0,0,0,3.18-1.349l8.128-8.4a6.5,6.5,0,0,1-1.3-2.49,6.6,6.6,0,0,1,.664-5.042,6.6,6.6,0,0,1,4.034-3.1,6.673,6.673,0,0,1,1.724-.229,6.649,6.649,0,0,1,6.414,4.926,6.655,6.655,0,0,1-4.7,8.141,6.5,6.5,0,0,1-1.059.166l-2.76,28.374-51.349,13.76h0Zm23.436-18.77v0a6.888,6.888,0,0,0-1.676,2.417,9.5,9.5,0,0,0,.079,7.2.067.067,0,0,1,0-.016,16.606,16.606,0,0,1,1.126-3.156,14.307,14.307,0,0,1,4.423-5.536l-.566-6.072s-.083,0-.242,0a12.311,12.311,0,0,0-4.08.746,10.172,10.172,0,0,0-5.373,4.379s0,0,0-.01a11.2,11.2,0,0,1,1.7-3.743,21.233,21.233,0,0,1,8.687-7.146l-8.507-1.66a18.042,18.042,0,0,0-3.433,5.009,13.027,13.027,0,0,0-1.241,5.259,11.452,11.452,0,0,0,1.788,6.2l0,0a7.336,7.336,0,0,1,1.6-1.469A15.743,15.743,0,0,1,7863.457-9050.795Z"
                transform="translate(-7557.458 9338.887)"
                fill="#fff"
              />
              <path
                id="Icon_awesome-star"
                data-name="Icon awesome-star"
                d="M9,.671,6.54,5.662l-5.507.8A1.207,1.207,0,0,0,.366,8.523L4.35,12.405,3.407,17.89a1.205,1.205,0,0,0,1.749,1.27l4.926-2.59,4.926,2.59a1.206,1.206,0,0,0,1.749-1.27l-.942-5.484L19.8,8.523a1.207,1.207,0,0,0-.667-2.058l-5.507-.8L11.165.671A1.207,1.207,0,0,0,9,.671Z"
                transform="translate(328.431 193.397) rotate(-13)"
                fill="#fff"
                opacity="0.4"
              />
              <path
                id="Icon_awesome-star-2"
                data-name="Icon awesome-star"
                d="M5.78.431,4.2,3.636.663,4.152A.775.775,0,0,0,.235,5.473L2.793,7.966l-.605,3.522a.774.774,0,0,0,1.123.816l3.164-1.663L9.638,12.3a.775.775,0,0,0,1.123-.816l-.605-3.522,2.558-2.493a.775.775,0,0,0-.428-1.322L8.75,3.636,7.17.431A.775.775,0,0,0,5.78.431Z"
                transform="matrix(0.998, 0.07, -0.07, 0.998, 289.7, 205.57)"
                fill="#fff"
                opacity="0.601"
              />
              <path
                id="Icon_awesome-star-3"
                data-name="Icon awesome-star"
                d="M13.818,1.031,10.039,8.692,1.585,9.924A1.853,1.853,0,0,0,.561,13.084l6.116,5.96L5.231,27.463a1.851,1.851,0,0,0,2.685,1.95l7.563-3.975,7.563,3.975a1.852,1.852,0,0,0,2.685-1.95l-1.447-8.419,6.116-5.96a1.853,1.853,0,0,0-1.024-3.159L20.918,8.692,17.139,1.031a1.853,1.853,0,0,0-3.321,0Z"
                transform="translate(302.361 215.852) rotate(-9)"
                fill="#fff"
                opacity="0.803"
              />
            </g>
            <text id="S-Manager" transform="translate(188 180)" fill="#fff" fontSize="18" fontFamily="Poppins-ExtraBold, Poppins" fontWeight="800">
              <tspan x="-53.091" y="0">
                S-Manager
              </tspan>
            </text>
            <text
              id="Saette_illimitate_per_organizzare_eventi_e_altre_funzioni_esclusive_"
              data-name="Saette illimitate per organizzare eventi e altre funzioni esclusive!"
              transform="translate(188, 188)"
              fill="#fff"
              fontSize="14"
              fontFamily="Poppins-Medium, Poppins"
              fontWeight="500">
              <tspan x="-135.03" y="15">
                Saette illimitate per organizzare eventi{' '}
              </tspan>
              <tspan x="-87.78" y="33">
                e altre funzioni esclusive!
              </tspan>
            </text>
          </g>
          <rect id="Rettangolo_652" data-name="Rettangolo 652" width="320" height="173" rx="15" transform="translate(9 461)" fill="#fff" />
          <text
            id="Le_saette_non_hanno_una_scadenza_una_volta_comprate_saranno_tue_finché_non_le_spenderai."
            data-name="Le saette non hanno una scadenza, una volta comprate, saranno tue finché non le spenderai."
            transform="translate(29 476)"
            fill="#ff7436"
            fontSize="14"
            fontFamily="Poppins-SemiBold, Poppins"
            fontWeight="600">
            <tspan x="0" y="15">
              Le saette non hanno una scadenza,{' '}
            </tspan>
            <tspan x="0" y="36">
              una volta comprate, saranno tue{' '}
            </tspan>
            <tspan x="0" y="57">
              finché non le spenderai.
            </tspan>
          </text>
          <text
            id="Lorem_ipsum_dolor_sit_amet_consetetur_sadipscing_elitr_sed_diam_nonumy_eirmod_tempor_invidunt_ut_labore_et_dolore_magna_aliquyam_erat_sed_diam_voluptua._At_vero_eos."
            data-name="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos."
            transform="translate(29 548)"
            fill="#465362"
            fontSize="12"
            fontFamily="Poppins-Light, Poppins"
            fontWeight="300"
            opacity="0.503">
            <tspan x="0" y="13">
              Le Saette sono necessarie per registrarsi o{' '}
            </tspan>
            <tspan x="0" y="31">
              creare un evento, ma non costituiscono il{' '}
            </tspan>
            <tspan x="0" y="49">
              pagamento del medesimo che invece è riportato{' '}
            </tspan>
            <tspan x="0" y="67">
              nella voce "contributo" in ogni singolo evento.
            </tspan>
          </text>
          <g id="Raggruppa_273" data-name="Raggruppa 273" transform="translate(6889 -9453)">
            <g transform="matrix(1, 0, 0, 1, -6889, 9453)" filter="url(#Rettangolo_635-2)">
              <rect id="Rettangolo_635-4" data-name="Rettangolo 635" width="320" height="95" rx="15" transform="translate(9 111)" fill="#fff" />
            </g>
            <text id="S-Pass" transform="translate(-6720 9598)" fill="#ff7436" fontSize="18" fontFamily="Poppins-ExtraBold, Poppins" fontWeight="800">
              <tspan x="-32.589" y="0">
                S-Pass
              </tspan>
            </text>
            <g id="Gruppo_di_maschere_38" data-name="Gruppo di maschere 38" opacity="0.35" clipPath="url(#clip-path-3)">
              <g id="Raggruppa_272" data-name="Raggruppa 272">
                <path
                  id="Icon_awesome-star-4"
                  data-name="Icon awesome-star"
                  d="M9,.671,6.54,5.662l-5.507.8A1.207,1.207,0,0,0,.366,8.523L4.35,12.405,3.407,17.89a1.205,1.205,0,0,0,1.749,1.27l4.926-2.59,4.926,2.59a1.206,1.206,0,0,0,1.749-1.27l-.942-5.484L19.8,8.523a1.207,1.207,0,0,0-.667-2.058l-5.507-.8L11.165.671A1.207,1.207,0,0,0,9,.671Z"
                  transform="translate(-6579.569 9546.396) rotate(-13)"
                  fill="#ff7436"
                  opacity="0.4"
                />
                <path
                  id="Icon_awesome-star-5"
                  data-name="Icon awesome-star"
                  d="M5.78.431,4.2,3.636.663,4.152A.775.775,0,0,0,.235,5.473L2.793,7.966l-.605,3.522a.774.774,0,0,0,1.123.816l3.164-1.663L9.638,12.3a.775.775,0,0,0,1.123-.816l-.605-3.522,2.558-2.493a.775.775,0,0,0-.428-1.322L8.75,3.636,7.17.431A.775.775,0,0,0,5.78.431Z"
                  transform="matrix(0.998, 0.07, -0.07, 0.998, -6618.3, 9558.569)"
                  fill="#ff7436"
                  opacity="0.601"
                />
                <path
                  id="Icon_awesome-star-6"
                  data-name="Icon awesome-star"
                  d="M13.818,1.031,10.039,8.692,1.585,9.924A1.853,1.853,0,0,0,.561,13.084l6.116,5.96L5.231,27.463a1.851,1.851,0,0,0,2.685,1.95l7.563-3.975,7.563,3.975a1.852,1.852,0,0,0,2.685-1.95l-1.447-8.419,6.116-5.96a1.853,1.853,0,0,0-1.024-3.159L20.918,8.692,17.139,1.031a1.853,1.853,0,0,0-3.321,0Z"
                  transform="translate(-6605.639 9568.853) rotate(-9)"
                  fill="#ff7436"
                  opacity="0.803"
                />
                <path
                  id="Tracciato_177"
                  data-name="Tracciato 177"
                  d="M25,56H12.981A6.5,6.5,0,0,0,7,50.019V37.981A6.5,6.5,0,0,0,7,25.019V12.981A6.5,6.5,0,0,0,12.981,7H25v4h2V7H74.019A6.5,6.5,0,0,0,80,12.981V25.019a6.5,6.5,0,0,0,0,12.962V50.019A6.5,6.5,0,0,0,74.019,56H27V52H25v4Zm0-16v7h2V40Zm0-12v7h2V28Zm0-12v7h2V16Z"
                  transform="matrix(0.891, 0.454, -0.454, 0.891, -6629.336, 9586.645)"
                  fill="#ff7436"
                />
                <path
                  id="Tracciato_176"
                  data-name="Tracciato 176"
                  d="M324.38,144.574l-7.789-3.805s-9.949,5.477-7.05,15.164c0,0,2.445-2.076,8.073-1.845,0,0-4.651,2.116-4.036,8.879,0,0,2.558-5.074,7.618-6.977l1.023-6.012s-6.083-2-10.688,2.438c0,0,2.672-5.881,12.848-7.841"
                  transform="matrix(0.891, 0.454, -0.454, 0.891, -6810.091, 9359.454)"
                  fill="#fff"
                />
              </g>
            </g>
            <text
              id="Saette_illimitate_per_partecipare_agli_eventi_e_altre_funzioni_esclusive_"
              data-name="Saette illimitate per partecipare agli eventi e altre funzioni esclusive!"
              transform="translate(-6721 9606)"
              fill="#ff7436"
              fontSize="14"
              fontFamily="Poppins-Medium, Poppins"
              fontWeight="500">
              <tspan x="-126.77" y="15">
                Saette illimitate per partecipare agli{' '}
              </tspan>
              <tspan x="-111.265" y="33">
                eventi e altre funzioni esclusive!
              </tspan>
            </text>
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rettangolo_733)">
            <rect id="Rettangolo_733-2" data-name="Rettangolo 733" width="100" height="100" rx="15" transform="translate(229 336)" fill="#fff" />
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rettangolo_734)">
            <rect id="Rettangolo_734-2" data-name="Rettangolo 734" width="100" height="100" rx="15" transform="translate(229 226)" fill="#ff7436" />
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rettangolo_727)">
            <rect id="Rettangolo_727-2" data-name="Rettangolo 727" width="100" height="100" rx="15" transform="translate(119 336)" fill="#fff" />
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rettangolo_728)">
            <rect id="Rettangolo_728-2" data-name="Rettangolo 728" width="100" height="100" rx="15" transform="translate(119 226)" fill="#fff" />
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rettangolo_729)">
            <rect id="Rettangolo_729-2" data-name="Rettangolo 729" width="100" height="100" rx="15" transform="translate(9 336)" fill="#fff" />
          </g>
          <g id="Gruppo_di_maschere_58" data-name="Gruppo di maschere 58" transform="translate(201 -221)" clipPath="url(#clip-path)">
            <path
              id="Tracciato_185"
              data-name="Tracciato 185"
              d="M378.242,157.912l-35.087-17.143S298.336,165.444,311.4,209.08c0,0,11.012-9.351,36.367-8.312,0,0-20.954,9.532-18.184,40,0,0,11.525-22.857,34.319-31.429l4.61-27.085s-27.4-9.018-48.148,10.982c0,0,12.037-26.494,57.88-35.325"
              transform="matrix(0.966, 0.259, -0.259, 0.966, -174.158, 323.795)"
              fill="#f7f7f7"
            />
          </g>
          <g
            id="Gruppo_di_maschere_59"
            data-name="Gruppo di maschere 59"
            transform="translate(201 -331)"
            opacity="0.148"
            clipPath="url(#clip-path-5)">
            <path
              id="Tracciato_186"
              data-name="Tracciato 186"
              d="M378.242,157.912l-35.087-17.143S298.336,165.444,311.4,209.08c0,0,11.012-9.351,36.367-8.312,0,0-20.954,9.532-18.184,40,0,0,11.525-22.857,34.319-31.429l4.61-27.085s-27.4-9.018-48.148,10.982c0,0,12.037-26.494,57.88-35.325"
              transform="matrix(0.966, 0.259, -0.259, 0.966, -174.158, 323.795)"
              fill="#fff"
            />
          </g>
          <g id="Gruppo_di_maschere_55" data-name="Gruppo di maschere 55" transform="translate(91 -221)" clipPath="url(#clip-path)">
            <path
              id="Tracciato_179"
              data-name="Tracciato 179"
              d="M378.242,157.912l-35.087-17.143S298.336,165.444,311.4,209.08c0,0,11.012-9.351,36.367-8.312,0,0-20.954,9.532-18.184,40,0,0,11.525-22.857,34.319-31.429l4.61-27.085s-27.4-9.018-48.148,10.982c0,0,12.037-26.494,57.88-35.325"
              transform="matrix(0.966, 0.259, -0.259, 0.966, -174.158, 323.795)"
              fill="#f7f7f7"
            />
          </g>
          <g id="Gruppo_di_maschere_56" data-name="Gruppo di maschere 56" transform="translate(91 -331)" clipPath="url(#clip-path)">
            <path
              id="Tracciato_180"
              data-name="Tracciato 180"
              d="M378.242,157.912l-35.087-17.143S298.336,165.444,311.4,209.08c0,0,11.012-9.351,36.367-8.312,0,0-20.954,9.532-18.184,40,0,0,11.525-22.857,34.319-31.429l4.61-27.085s-27.4-9.018-48.148,10.982c0,0,12.037-26.494,57.88-35.325"
              transform="matrix(0.966, 0.259, -0.259, 0.966, -174.158, 323.795)"
              fill="#f7f7f7"
            />
          </g>
          <g id="Gruppo_di_maschere_57" data-name="Gruppo di maschere 57" transform="translate(-19 -221)" clipPath="url(#clip-path)">
            <path
              id="Tracciato_181"
              data-name="Tracciato 181"
              d="M378.242,157.912l-35.087-17.143S298.336,165.444,311.4,209.08c0,0,11.012-9.351,36.367-8.312,0,0-20.954,9.532-18.184,40,0,0,11.525-22.857,34.319-31.429l4.61-27.085s-27.4-9.018-48.148,10.982c0,0,12.037-26.494,57.88-35.325"
              transform="matrix(0.966, 0.259, -0.259, 0.966, -174.158, 323.795)"
              fill="#f7f7f7"
            />
          </g>
          <text
            id="_9_90_"
            data-name="9,90€"
            transform="translate(279 305)"
            fill="#fff"
            fontSize="18"
            fontFamily="Poppins-Bold, Poppins"
            fontWeight="700">
            <tspan x="-25.965" y="0">
              9,90€
            </tspan>
          </text>
          <text
            id="_14_90_"
            data-name="14,90€"
            transform="translate(59 415)"
            fill="#011936"
            fontSize="18"
            fontFamily="Poppins-Bold, Poppins"
            fontWeight="700">
            <tspan x="-29.808" y="0">
              14,90€
            </tspan>
          </text>
          <text
            id="_64_90_"
            data-name="64,90€"
            transform="translate(169 415)"
            fill="#011936"
            fontSize="18"
            fontFamily="Poppins-Bold, Poppins"
            fontWeight="700">
            <tspan x="-32.256" y="0">
              64,90€
            </tspan>
          </text>
          <text
            id="_99_90_"
            data-name="99,90€"
            transform="translate(279 415)"
            fill="#011936"
            fontSize="18"
            fontFamily="Poppins-Bold, Poppins"
            fontWeight="700">
            <tspan x="-31.374" y="0">
              99,90€
            </tspan>
          </text>
          <text
            id="_2_50_"
            data-name="2,50€"
            transform="translate(169 305)"
            fill="#011936"
            fontSize="18"
            fontFamily="Poppins-Bold, Poppins"
            fontWeight="700">
            <tspan x="-26.028" y="0">
              2,50€
            </tspan>
          </text>
          <text id="Gratis" transform="translate(59 305)" fill="#011936" fontSize="18" fontFamily="Poppins-Bold, Poppins" fontWeight="700">
            <tspan x="-32.4" y="0">
              GRATIS
            </tspan>
          </text>
          <text
            id="_12_40_"
            data-name="12,40€"
            transform="translate(279 284)"
            fill="#fff"
            fontSize="12"
            fontFamily="Poppins-SemiBold, Poppins"
            fontWeight="600"
            opacity="0.498">
            <tspan x="-19.218" y="0">
              12,40€
            </tspan>
          </text>
          <text
            id="_12_90_"
            data-name="19,90€"
            transform="translate(59 394)"
            fill="#978f90"
            fontSize="12"
            fontFamily="Poppins-SemiBold, Poppins"
            fontWeight="600"
            opacity="0.498">
            <tspan x="-18.93" y="0">
              19,90€
            </tspan>
          </text>
          <text
            id="_74_90_"
            data-name="74,90€"
            transform="translate(169 394)"
            fill="#978f90"
            fontSize="12"
            fontFamily="Poppins-SemiBold, Poppins"
            fontWeight="600"
            opacity="0.498">
            <tspan x="-20.514" y="0">
              74,90€
            </tspan>
          </text>
          <text
            id="_129_90_"
            data-name="129,90€"
            transform="translate(279 394)"
            fill="#978f90"
            fontSize="12"
            fontFamily="Poppins-SemiBold, Poppins"
            fontWeight="600"
            opacity="0.498">
            <tspan x="-22.554" y="0">
              129,90€
            </tspan>
          </text>
          <text
            id="_10"
            data-name="10"
            transform="translate(161.04 266)"
            fill="#465362"
            fontSize="24"
            fontFamily="Poppins-SemiBold, Poppins"
            fontWeight="600">
            <tspan x="-11.544" y="0">
              10
            </tspan>
          </text>
          <text
            id="_50"
            data-name="50"
            transform="translate(271.04 266)"
            fill="#fff"
            fontSize="24"
            fontFamily="Poppins-SemiBold, Poppins"
            fontWeight="600">
            <tspan x="-15.18" y="0">
              50
            </tspan>
          </text>
          <text
            id="_100"
            data-name="100"
            transform="translate(51.04 376)"
            fill="#465362"
            fontSize="24"
            fontFamily="Poppins-SemiBold, Poppins"
            fontWeight="600">
            <tspan x="-19.128" y="0">
              100
            </tspan>
          </text>
          <text
            id="_500"
            data-name="500"
            transform="translate(161.04 376)"
            fill="#465362"
            fontSize="24"
            fontFamily="Poppins-SemiBold, Poppins"
            fontWeight="600">
            <tspan x="-22.764" y="0">
              500
            </tspan>
          </text>
          <text
            id="_1000"
            data-name="1000"
            transform="translate(271.04 376)"
            fill="#465362"
            fontSize="24"
            fontFamily="Poppins-SemiBold, Poppins"
            fontWeight="600">
            <tspan x="-26.712" y="0">
              1000
            </tspan>
          </text>
          <path
            id="Tracciato_183"
            data-name="Tracciato 183"
            d="M321.932,144.026l-6.548-3.257s-8.364,4.688-5.927,12.979c0,0,2.055-1.777,6.787-1.579,0,0-3.91,1.811-3.394,7.6a13.409,13.409,0,0,1,6.4-5.971l.86-5.146s-5.114-1.713-8.986,2.086c0,0,2.246-5.034,10.8-6.712"
            transform="translate(-132.972 107.231)"
            fill="#ff7436"
          />
          <path
            id="Tracciato_189"
            data-name="Tracciato 189"
            d="M321.932,144.026l-6.548-3.257s-8.364,4.688-5.927,12.979c0,0,2.055-1.777,6.787-1.579,0,0-3.91,1.811-3.394,7.6a13.409,13.409,0,0,1,6.4-5.971l.86-5.146s-5.114-1.713-8.986,2.086c0,0,2.246-5.034,10.8-6.712"
            transform="translate(-19.972 107.231)"
            fill="#fff"
          />
          <path
            id="Tracciato_191"
            data-name="Tracciato 191"
            d="M321.932,144.026l-6.548-3.257s-8.364,4.688-5.927,12.979c0,0,2.055-1.777,6.787-1.579,0,0-3.91,1.811-3.394,7.6a13.409,13.409,0,0,1,6.4-5.971l.86-5.146s-5.114-1.713-8.986,2.086c0,0,2.246-5.034,10.8-6.712"
            transform="translate(-234.972 217.231)"
            fill="#ff7436"
          />
          <path
            id="Tracciato_192"
            data-name="Tracciato 192"
            d="M321.932,144.026l-6.548-3.257s-8.364,4.688-5.927,12.979c0,0,2.055-1.777,6.787-1.579,0,0-3.91,1.811-3.394,7.6a13.409,13.409,0,0,1,6.4-5.971l.86-5.146s-5.114-1.713-8.986,2.086c0,0,2.246-5.034,10.8-6.712"
            transform="translate(-121.972 217.231)"
            fill="#ff7436"
          />
          <path
            id="Tracciato_193"
            data-name="Tracciato 193"
            d="M321.932,144.026l-6.548-3.257s-8.364,4.688-5.927,12.979c0,0,2.055-1.777,6.787-1.579,0,0-3.91,1.811-3.394,7.6a13.409,13.409,0,0,1,6.4-5.971l.86-5.146s-5.114-1.713-8.986,2.086c0,0,2.246-5.034,10.8-6.712"
            transform="translate(-6.972 217.231)"
            fill="#ff7436"
          />
          <rect
            id="Rettangolo_725"
            data-name="Rettangolo 725"
            width="60"
            height="7"
            rx="3.5"
            transform="translate(29 276)"
            fill="#ff7436"
            opacity="0.248"
          />
          <rect id="Rettangolo_726" data-name="Rettangolo 726" width="45" height="7" rx="3.5" transform="translate(29 276)" fill="#ff7436" />
          <path
            id="Tracciato_178"
            data-name="Tracciato 178"
            d="M331.452,146.426l-11.373-5.657s-14.527,8.143-10.294,22.543c0,0,3.57-3.086,11.788-2.743,0,0-6.792,3.145-5.894,13.2,0,0,3.736-7.543,11.124-10.371l1.494-8.938s-8.882-2.976-15.607,3.624c0,0,3.9-8.743,18.761-11.657"
            transform="translate(-261.232 100.231)"
            fill="#ff7436"
          />
        </svg> */}
      </Flex>
    </Wrapper>
  )
}

export default ShopScreen
