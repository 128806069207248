import * as yup from 'yup'

const requiredFieldErrorMsg = field => `Il "${field}" è un campo obbligatorio. Chiedi all'utente di compilare questo campo.`
const inferredFieldErrorMsg = field => `Il "${field}" è un campo obbligatorio. Cerca di inferirlo te dalle rispote che ti ha dato l'utente.`

export const CategoriesEnum = ['Studio', 'Cibo', 'Disco', 'Giochi', 'Concerto', 'Arte', 'Giretto', 'Sport', 'Altro']

const schema = yup.object({
  title: yup.string().max(50).required(requiredFieldErrorMsg('Titlo evento')),

  location: yup.object({
    city: yup.string().min(1).required(requiredFieldErrorMsg('Città per il Punto di ritrovo')),
    street: yup.string().min(1).required(requiredFieldErrorMsg('Via per il Punto di ritrovo')),
    houseNumber: yup.number().min(1).required(requiredFieldErrorMsg('Numero civico per il Punto di ritrovo')),
  }),

  categoryId: yup
    .mixed()
    .oneOf(CategoriesEnum)
    .required(requiredFieldErrorMsg(inferredFieldErrorMsg('categoryId'))),

  description: yup.string().required(requiredFieldErrorMsg(inferredFieldErrorMsg('description'))),

  startDate: yup
    .date()
    .required(requiredFieldErrorMsg('Data ed ora di inizio'))
    .min(new Date(), 'La "Data ed ora di inizio" (campo startDate) non può essere nel passato. Chiedi all\'utente di generarne una corretta'),

  limitations: yup.object({
    maxAge: yup
      .number()
      .min(18, "L'età massima consigliata deve essere maggiore o uguale a 18")
      .max(99, "L'età massima consigliata deve essere minore o uguale a 99")
      .test(
        'is-greater-or-equal-then-min-age',
        'Nella "Età consigliata" limitations.maxAge deve essere maggiore o uguale a limitations.minAge',
        (maxAge, { parent: { minAge } }) => {
          if (!maxAge) {
            return true
          }

          return maxAge && minAge && maxAge <= minAge
        },
      ),

    minAge: yup
      .number()
      .min(18, "L'età minima consigliata deve essere maggiore o uguale a 18")
      .max(99, "L'età minima consigliata deve essere minore o uguale a 99")
      .test(
        'is-lower-or-equal-then-max-age',
        'Nella "Età consigliata" limitations.minAge deve essere minuore o uguale a limitations.maxAge',
        (minAge, { parent: { maxAge } }) => {
          if (!minAge) {
            return true
          }

          return minAge && maxAge && minAge >= maxAge
        },
      ),

    maxParticipants: yup
      .number()
      .min(2, 'I "Posti totali disponibili" devono essere almeno 2')
      .required(requiredFieldErrorMsg('Posti totali disponibili'))
      .test('is-equal-to-the-sum', '', (maxPartecipants, { createError, parent: { maleParticipants, femaleParticipants, otherParticipants } }) => {
        if (
          maxPartecipants &&
          maleParticipants &&
          femaleParticipants &&
          otherParticipants &&
          maleParticipants + femaleParticipants + otherParticipants === maxPartecipants
        ) {
          return
        }

        createError({
          path: 'limitations.maxPartecipants',
          message: `limitations.maleParticipants è ${maleParticipants}.
            limitations.femaleParticipants è ${femaleParticipants}.
            limitations.otherParticipants è ${otherParticipants}.
            La loro somma fa ${maleParticipants + femaleParticipants + otherParticipants} ed invece dovrebbe essere ${maxPartecipants}.
            Se puoi inferire qualcuna di queste informazioni mancanti o errate fallo, altrimenti chiedi all'utente di correggere le informazioni.`,
        })
      }),
    maleParticipants: yup.number(),
    femaleParticipants: yup.number(),
    otherParticipants: yup.number(),
  }),

  cost: yup.number(),
})

export default schema
