import { Box, FormControl, FormErrorMessage, Text } from '@chakra-ui/react'
import InputField from '@components/shared/input/input.component'
import React from 'react'
import { validEmail } from 'utils/validate-email'

const EmailField = (props: { register: any; email: string | null; label: string; placeholder: string | null; errors: any }) => {
  return (
    <Box>
      <Text textColor="#465362" fontWeight="600" fontSize="0.9rem">
        {props.label}{' '}
        <Text color="#FF7436" display="inline">
          *
        </Text>
      </Text>
      <FormControl isInvalid={!!props.errors.email}>
        <InputField
          type="email"
          isReadOnly={props.email !== null}
          placeholder={props.placeholder}
          value={props.email}
          {...props.register('email', {
            required: 'Devi specificare una email',
            validate: val => validEmail(val) || 'Devi specificare una mail valida',
          })}
        />
      </FormControl>
    </Box>
  )
}

export default EmailField
