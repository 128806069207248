import { Box, Flex } from '@chakra-ui/react'
import { User } from '@twilio/conversations'
import { AppContext } from 'app.context'
import React, { useContext } from 'react'
import { FaArrowLeft } from 'react-icons/fa6'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import history from 'utils/history'
import useGetConversationQueryBySid from '../hooks/use-get-conversation-query'
import UserAvatar from 'components/shared/user-avatar/user-avatar'

function HeaderOneToOne() {
  const navigate = useNavigate()

  const { data } = useGetOtherPartecipant()

  const attributes = data?.attributes as { name: string; avatar: string }
  const identity = data?.identity

  const name = attributes?.name
  const avatar = attributes?.avatar

  const handleOnOpenProfile = () => {
    navigate('/profile/' + identity)
  }

  return (
    <>
      <Flex
        position="absolute"
        left="0"
        top="0"
        height="60px"
        flexShrink="0"
        width="100%"
        zIndex="50"
        backgroundColor="rgba(1, 25, 54, .75)"
        bgPos="center"
        bgSize="cover"
        borderBottomRadius="15px"
        alignItems="center"
        justifyContent="space-between"
        px="20px"
        fontSize="18px"
        color="#FFFFFF"
        fontWeight="600">
        <Flex alignItems="center">
          <Box cursor="pointer" marginRight="20px" onClick={history.back}>
            <FaArrowLeft />
          </Box>

          <Flex alignItems="center" gap={2} onClick={handleOnOpenProfile}>
            <UserAvatar src={avatar} size="sm" />

            <Box cursor="pointer">{name}</Box>
          </Flex>
        </Flex>

        <div />
      </Flex>

      <Box h="60px"></Box>
    </>
  )
}

const useGetOtherPartecipant = () => {
  const { client: twilioClient } = useContext(AppContext)
  const { sid } = useParams()

  const { data, isSuccess } = useGetConversationQueryBySid(sid)
  const self = data?.conversation?.self

  const identity = twilioClient?.user.identity

  return useQuery(
    `other-partecipant-${sid}`,
    async () => {
      const partecipants = await self!.getParticipants()
      const users = await Promise.all(partecipants.map(p => p.getUser()))
      const user: User | undefined = users.find(u => u.identity !== identity)

      return user
    },
    { enabled: isSuccess },
  )
}

export default HeaderOneToOne
