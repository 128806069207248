import { useToast } from '@chakra-ui/react'
import { MessageType, Reply } from '@screens/chat/types'
import { AppContext } from '@src/app.context'
import { JSONValue } from '@twilio/conversations'
import { useContext } from 'react'
import { useMutation } from 'react-query'

const useSendTextMessageMutation = (options = {}) => {
  const { client: twilioClient } = useContext(AppContext)

  const toast = useToast()

  return useMutation(
    'send-message',
    async ({ sid, message, reply }: { sid: string; message: string; reply?: Reply }) => {
      if (!message || !twilioClient || !sid) {
        return false
      }

      const conversation = await twilioClient.getConversationBySid(sid)
      const attributes = reply ? ({ type: MessageType.Text, reply } as JSONValue) : ({ type: MessageType.Text } as JSONValue)

      await conversation.prepareMessage().setBody(message).setAttributes(attributes).build().send()
    },
    {
      onError(error: any) {
        console.error(error)

        toast({
          title: error?.message || 'Qualcosa è andato storto',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        })
      },
      ...options,
    },
  )
}

export default useSendTextMessageMutation
