import { Flex } from '@chakra-ui/react'
import { AppContext } from 'app.context'
import React, { MouseEventHandler, TouchEventHandler, useCallback, useContext, useState } from 'react'
import { FaExpandArrowsAlt } from 'react-icons/fa'
import useGetUserFromMessageQuery from '../../hooks/use-get-user-from-message'
import DeletedMessageModal from './deleted-message-modal'

const blockDrawerOpeningOnMouseDown: MouseEventHandler<HTMLDivElement> = e => {
  e.stopPropagation()
  e.preventDefault()
}

const blockDrawerOpeningOnTouchStart: TouchEventHandler<HTMLDivElement> = e => {
  e.stopPropagation()
  e.preventDefault()
}

function DeletedMessage({ msg }) {
  const { client: twilioClient } = useContext(AppContext)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const { data: user } = useGetUserFromMessageQuery(msg)

  const isMine = user?.identity === twilioClient?.user.identity

  const handleOnClose = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleOnOpen = () => {
    setIsModalOpen(true)
  }

  const color = isMine ? 'brand.500' : 'gray'

  return (
    <>
      <Flex gap={1} alignItems="center" cursor="pointer" color={color} onClick={handleOnOpen} fontWeight="500" lineHeight="1.2rem">
        <FaExpandArrowsAlt />
        <div>Messaggio cancellato</div>
      </Flex>

      <div onMouseDown={blockDrawerOpeningOnMouseDown} onTouchStart={blockDrawerOpeningOnTouchStart}>
        <DeletedMessageModal msg={msg} isOpen={isModalOpen} onClose={handleOnClose} />
      </div>
    </>
  )
}

export default DeletedMessage
