import { Geolocation } from '@capacitor/geolocation'
import { Capacitor } from '@capacitor/core'

class LocalizationService {
  static baseURL = 'https://maps.googleapis.com/maps/api'
  static API_KEY = 'AIzaSyCoRWks-CxqPGhzrCoxgfQmzfYJBKlPN7Q'
  static getCurrentPosOptions = {
    enableHighAccuracy: false,
    timeout: 50000,
    maximumAge: 10000,
  }

  static searchByName = async input => {
    const res = await fetch(`${this.baseURL}/place/autocomplete/json?input=${input}&language=it&key=${this.API_KEY}`)
    return res.json()
  }

  static getCurrentPosition = async () => {
    if (Capacitor.isNativePlatform()) {
      try {
        const hasPermission = await Geolocation.checkPermissions()

        if (!hasPermission.location) {
          const permission = await Geolocation.requestPermissions()
          if (permission.location === 'granted') {
            return await Geolocation.getCurrentPosition()
          } else {
            return alert("Devi attivare la Geolocalizzazione per usare l'app")
          }
        } else {
          return await Geolocation.getCurrentPosition()
        }
      } catch (e) {
        console.error(e)
        throw new Error(e?.message || 'Qualcosa è andato storto')
      }
    } else {
      return new Promise(async (resolve, reject) => {
        window.navigator.geolocation.getCurrentPosition(resolve, reject, this.getCurrentPosOptions)
      })
    }
  }

  static getCurrentAddress = async () => {
    const {
      coords: { latitude, longitude },
    } = await this.getCurrentPosition()
    const res = await fetch(`${this.baseURL}/geocode/json?latlng=${latitude},${longitude}&language=it&key=${this.API_KEY}`)
    const data = await res.json()
    return data.results.map(result => result.formatted_address)
  }
}

export default LocalizationService
