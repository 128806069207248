import React from 'react'
import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { ModalProps } from '@components/types'
import { ReactComponent as CalendarIco } from 'assets/calendar-ico.svg'

export const CommunityChatOnboardingModal = ({ isOpen, onClose }: ModalProps) => {
  return (
    <Modal isCentered closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent borderRadius="15px" maxWidth="90vw">
        <ModalHeader>Come funziona la chat città?</ModalHeader>
        <ModalBody>
          <Text>
            Qui trovi le chat delle principali città a te più vicine. Usale per divertirti e capire con un sondaggio, chi ha voglia di fare qualcosa
            insieme.
            <br />
            <br />
            Se la tua proposta trova interessati, usa il pulsante “
            <Box display="inline-block" color="brand.500" cursor="pointer" fontSize="1rem" verticalAlign="middle">
              <CalendarIco />
            </Box>
            “ per creare un evento al volo e sbloccare la chat di gruppo solo coi partecipanti.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button alignSelf="center" size="sm" width="100%" borderRadius="10px" onClick={onClose}>
            Ok, tutto chiaro!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
