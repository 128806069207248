import Wrapper from '@components/shared/wrapper/wrapper.component'
import React from 'react'
import AccessButtons from './access-buttons'
import AccessLogo from './access-logo'
import BackgroundVideo from './background-video'
import TestingEnvText from './testing-env-text'
import { Flex } from '@chakra-ui/react'

function AccessScreen() {
  return (
    <>
      <BackgroundVideo />
      <Wrapper height="100%" minHeight="41rem" zIndex="0" position="relative" overflowY="hidden" overflowX="hidden">
        <Flex flexDir="column" alignItems="center" gap="2rem">
          <AccessLogo />
          {process.env.REACT_APP_IS_BETA === 'true' && <TestingEnvText />}
        </Flex>
        <AccessButtons />
      </Wrapper>
    </>
  )
}

export default AccessScreen
