import { Flex, FormControl } from '@chakra-ui/react'
import React from 'react'
import CodeField from './code-field'
import PrivacyTermsCheckboxGroup from './privacy-terms-checkbox-group'

function CodeTermsFields(props: {
  register: any
  userCode: string
  errors: any
  hasTermsAndConditionAccepted: boolean
  hasPrivacyPolicyAccepted: boolean
  hasMarketingCommunicationsAccepted: boolean
}) {
  return (
    <Flex flexDir="column" gap="2rem" mx="1.25rem">
      <CodeField register={props.register} userCode={props.userCode} />
      <PrivacyTermsCheckboxGroup
        errors={props.errors}
        register={props.register}
        hasTermsAndConditionAccepted={props.hasTermsAndConditionAccepted}
        hasPrivacyPolicyAccepted={props.hasPrivacyPolicyAccepted}
        hasMarketingCommunicationsAccepted={props.hasMarketingCommunicationsAccepted}
      />
    </Flex>
  )
}

export default CodeTermsFields
