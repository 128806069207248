import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from '@chakra-ui/react'
import { Message } from '@twilio/conversations'
import { AppContext } from 'app.context'
import useAmIAdminOrSupport from 'hooks/use-am-i-admin-or-support'
import React, { useContext, useState } from 'react'
import { FaReply, FaTrash } from 'react-icons/fa'
import { useChatContext } from '../context/chat-context'
import getTimeFromMessage from '../hooks/get-time-from-message'
import useDeleteMessageMutation from '../hooks/use-delete-message-mutation'
import useGetUserFromMessageQuery, { getAuthorNameFromUser } from '../hooks/use-get-user-from-message'

function MediaMessageActions({ msg }) {
  return (
    <Flex alignItems="flex-start" gap="1rem" direction="column">
      <Reply msg={msg} />

      <DeleteMessage msg={msg} />
    </Flex>
  )
}

function Reply({ msg }: { msg: Message }) {
  const { setMessageForDrawer, setReply, openKeyboard } = useChatContext()

  const { data: user } = useGetUserFromMessageQuery(msg)

  const time = getTimeFromMessage(msg)

  const handleOnSetReply = () => {
    setReply({ user, msg, body: 'Media del ' + time })
    setMessageForDrawer(undefined)

    openKeyboard()
  }

  return (
    <Flex width="100%" onClick={handleOnSetReply} alignItems="center" gap="1rem" justifyContent="flex-start">
      <Box color="brand.500">
        <FaReply />
      </Box>

      <div>Rispondi</div>
    </Flex>
  )
}

function DeleteMessage({ msg }: { msg: Message }) {
  const toast = useToast()
  const { client: twilioClient } = useContext(AppContext)
  const { setMessageForDrawer, setReply } = useChatContext()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const amIAdminOrSupport = useAmIAdminOrSupport()

  const { data: user } = useGetUserFromMessageQuery(msg)
  const isMine = user?.identity === twilioClient?.user.identity

  const deleteMessage = useDeleteMessageMutation(msg, {
    onSuccess: () => {
      setMessageForDrawer(undefined)
      setReply(r => (r?.sid === msg.sid ? undefined : r))

      toast({
        title: 'Messaggio cancellato',
        status: 'success',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      })
    },
  })

  const handleOnClose = () => {
    setIsModalOpen(false)
  }

  const handleOnOpen = () => {
    setIsModalOpen(true)
  }

  const handleOnDelete = () => {
    deleteMessage.mutate()
  }

  if (!amIAdminOrSupport && !isMine) {
    return <></>
  }

  if (deleteMessage.isLoading) {
    return (
      <Flex alignItems="center" gap="1rem" justifyContent="space-between">
        <Box color="red">
          <FaTrash />
        </Box>

        <Box color="red">
          <div>In cancellazione...</div>
        </Box>

        <Box color="red">
          <Spinner size="sm" mr="10px" />
        </Box>
      </Flex>
    )
  }

  return (
    <>
      <Flex width="100%" onClick={handleOnOpen} alignItems="center" gap="1rem" justifyContent="flex-start">
        <Box color="red">
          <FaTrash />
        </Box>

        <Box color="red">
          <div>Cancella messaggio</div>
        </Box>
      </Flex>

      <Modal isOpen={isModalOpen} onClose={handleOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody pb="40px">
            <ModalDeleteMessage msg={msg} />
          </ModalBody>
          <ModalFooter>
            <Button width="75%" alignSelf="center" variant="ghost" borderRadius="10px" onClick={handleOnClose}>
              Annulla
            </Button>

            <Button
              alignSelf="center"
              width="75%"
              borderRadius="10px"
              isDisabled={deleteMessage.isLoading}
              isLoading={deleteMessage.isLoading}
              onClick={handleOnDelete}>
              Cancella
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function ModalDeleteMessage({ msg }) {
  const { client: twilioClient } = useContext(AppContext)
  const { data: user } = useGetUserFromMessageQuery(msg)
  const isMine = user?.identity === twilioClient?.user.identity

  const author = getAuthorNameFromUser(user)
  const time = getTimeFromMessage(msg)

  if (isMine) {
    return (
      <>
        Sei sicuro di voler cancellare il <strong>tuo</strong> messaggio del <strong>{time}</strong> ?
      </>
    )
  }

  return (
    <>
      Sei sicuro di voler cancellare il messaggio di <strong>{author}</strong> del <strong>{time}</strong> ?
    </>
  )
}

export default MediaMessageActions
