import { Box, Flex, Text } from '@chakra-ui/react'
import { AnyAction } from '@reduxjs/toolkit'
import { AppContext } from 'app.context'
import { useAppDispatch } from 'features'
import { refreshUser } from 'features/auth/auth.actions'
import { selectorAuth } from 'features/auth/auth.slice'
import { Logo } from 'logo'
import React, { useContext, useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { Link, useMatch } from 'react-router-dom'
import Api from 'services/api/api.service'
import { isIos } from 'utils/platform'
import { ReactComponent as BoltsIcon } from '../../../assets/bolts.svg'
import { ReactComponent as ChatIcoFigma } from '../../../assets/chat-ico-figma.svg'

const Header = () => {
  useRefreshUser()

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        height="60px"
        px="25px"
        position="fixed"
        top="0"
        left="50%"
        transform="translate(-50%)"
        width="100%"
        maxWidth="500px"
        mx="auto"
        zIndex="100"
        borderBottom="1px solid #F7F7F7"
        backgroundColor="#fff"
        marginTop="env(safe-area-inset-top)">
        <Link to="/">
          <Box pos="relative">
            <Logo />
            {process.env.REACT_APP_IS_BETA === 'true' && (
              <Text fontSize="8px" pos="absolute" bottom="-15px" left="27px" fontWeight="bold">
                BETA
              </Text>
            )}
          </Box>
        </Link>

        <LightningAmount />

        <Flex alignItems="center" gap="1.25rem">
          <Notifications />

          <Chats />
        </Flex>
      </Flex>

      <Box height="60px" width="100%"></Box>
    </>
  )
}

function Notifications() {
  const isHighlighted = useMatch('/notifications')

  const { data } = useQuery('notifications', () => Api.notifications()) as { data: any }
  const notifications = data?.data || []
  const unreadNotifications = notifications.filter(({ readAt }) => !readAt).length

  return (
    <Link to="/notifications">
      <Box
        position="relative"
        _after={
          unreadNotifications
            ? {
                content: `'${unreadNotifications}'`,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bottom: '-8.5px',
                right: '-18.5px',
                width: '17px',
                height: '17px',
                border: '2px solid #FFFFFF',
                color: '#FFFFFF',
                borderRadius: '50%',
                backgroundColor: 'brand.500',
                fontSize: '10px',
                fontWeight: '700',
              }
            : {}
        }>
        <svg xmlns="http://www.w3.org/2000/svg" width="19.333" height="23.563" viewBox="0 0 19.333 23.563">
          <path
            id="Tracciato_85"
            data-name="Tracciato 85"
            d="M13.667,26.063a2.424,2.424,0,0,0,2.417-2.417H11.25A2.424,2.424,0,0,0,13.667,26.063Zm7.25-7.25V12.771c0-3.71-1.97-6.815-5.437-7.637V4.313a1.813,1.813,0,0,0-3.625,0v.822C8.4,5.956,6.417,9.049,6.417,12.771v6.042L4,21.229v1.208H23.333V21.229ZM18.5,20.021H8.833v-7.25c0-3,1.825-5.438,4.833-5.438S18.5,9.774,18.5,12.771Z"
            transform="translate(-4 -2.5)"
            fill={!!isHighlighted ? '#FF7436' : '#978F90'}
          />
        </svg>
      </Box>
    </Link>
  )
}

function LightningAmount() {
  const { user } = useSelector(selectorAuth)
  const hideShop = isIos()

  if (hideShop) {
    return (
      <Flex position="relative" bg="#FF7436" color="#FFF" height="30px" minWidth="60px" alignItems="center" borderRadius="15px" px="10px">
        <Text pr="5px" fontSize="14px" fontWeight="600" flex="1" textAlign="center">
          {user?.wallet?.lightningAmount}
        </Text>{' '}
        <Box position="absolute" right="-.2rem">
          <BoltsIcon />
        </Box>
        <Box as="div" width="1rem" />
      </Flex>
    )
  }

  return (
    <a href="https://sbam.social/store" target="_blank" rel="noreferrer">
      <Flex position="relative" bg="#FF7436" color="#FFF" height="30px" minWidth="60px" alignItems="center" borderRadius="15px" px="10px">
        <Text pr="5px" fontSize="14px" fontWeight="600" flex="1" textAlign="center">
          {user?.wallet?.lightningAmount}
        </Text>{' '}
        <Box position="absolute" right="-.2rem">
          <BoltsIcon />
        </Box>
        <Box as="div" width="1rem" />
      </Flex>
    </a>
  )
}
function Chats() {
  const isHighlighted = useMatch('/chats/*')

  const { conversationsWithUnreadMessages } = useContext(AppContext)

  return (
    <Link to="/chats/communityChat">
      <Box
        position="relative"
        _after={
          conversationsWithUnreadMessages.length
            ? {
                content: `'${conversationsWithUnreadMessages.length} '`,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bottom: '-8.5px',
                right: '-17.5px',
                width: '17px',
                height: '17px',
                border: '2px solid #FFFFFF',
                color: '#FFFFFF',
                borderRadius: '50%',
                backgroundColor: 'brand.500',
                fontSize: '10px',
                fontWeight: '700',
              }
            : {}
        }>
        <ChatIcoFigma fill={!!isHighlighted ? '#FF7436' : '#978F90'} />
      </Box>
    </Link>
  )
}

const useRefreshUser = () => {
  const { isAuthenticated, isLoading } = useSelector(selectorAuth)

  const dispatch = useAppDispatch()
  const timer = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      timer.current = setTimeout(() => dispatch(refreshUser() as unknown as AnyAction), 10 * 1000)
    } else {
      clearTimeout(timer.current)
    }

    return () => {
      clearTimeout(timer.current)
    }
  }, [isAuthenticated, isLoading, dispatch])
}

export default Header
