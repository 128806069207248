import React from 'react'
import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { ModalProps } from '@components/types'
import { ReactComponent as WarningIcon } from '@assets/warning-icon.svg'

export const UserChatOnboardingModal = ({ isOpen, onClose }: ModalProps) => {
  return (
    <Modal isCentered closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent borderRadius="15px" maxWidth="90vw">
        <ModalHeader>Come funziona la chat utenti?</ModalHeader>
        <ModalBody>
          <Text>
            Qui trovi le chat con gli utenti che tu segui e che ti seguono a vicenda. Usale per parlare con gli altri Sbammers in privato.
            <br />
            <br />
            P.S. Se un utente si comporta male o ti molesta in qualsiasi modo, contatta il supporto e interveniamo subito. Per farlo vai sul suo
            profilo e tocca “
            <Box display="inline-block" cursor="pointer" fontSize="1rem" verticalAlign="middle">
              <WarningIcon />
            </Box>
            “.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button alignSelf="center" size="sm" width="100%" borderRadius="10px" onClick={onClose}>
            Ok, tutto chiaro!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
