import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate, useParams, Navigate } from 'react-router-dom'

function Tabs() {
  const { page } = useParams()

  switch (page) {
    case 'communityChat': {
      return <CommunityChatTab />
    }

    case 'eventChat': {
      return <EventsChatTab />
    }

    case 'userChat': {
      return <UserChatTab />
    }

    default:
      return <NoOneSelectedTabs />
  }
}

function CommunityChatTab() {
  const navigate = useNavigate()

  const handleOnOpenEventsChat = () => {
    navigate('/chats/eventChat')
  }

  const handleOnOpenUserChat = () => {
    navigate('/chats/userChat')
  }

  return (
    <Flex justify="space-around" textAlign="center" py="20px">
      <Text fontSize="1.25rem" fontWeight="semibold">
        Città
      </Text>

      <Text fontSize="1.25rem" cursor="pointer" onClick={handleOnOpenEventsChat} opacity={0.5} fontWeight="semibold">
        Eventi
      </Text>

      <Text fontSize="1.25rem" cursor="pointer" onClick={handleOnOpenUserChat} opacity={0.5} fontWeight="semibold">
        Utenti
      </Text>
    </Flex>
  )
}

function EventsChatTab() {
  const navigate = useNavigate()

  const handleOnOpenCommunityChat = () => {
    navigate('/chats/communityChat')
  }

  const handleOnOpenUserChat = () => {
    navigate('/chats/userChat')
  }

  return (
    <Flex justify="space-around" textAlign="center" py="20px">
      <Text fontSize="1.25rem" cursor="pointer" onClick={handleOnOpenCommunityChat} opacity={0.5} fontWeight="semibold">
        Città
      </Text>

      <Text fontSize="1.25rem" fontWeight="semibold">
        Eventi
      </Text>

      <Text fontSize="1.25rem" cursor="pointer" onClick={handleOnOpenUserChat} opacity={0.5} fontWeight="semibold">
        Utenti
      </Text>
    </Flex>
  )
}

function UserChatTab() {
  const navigate = useNavigate()

  const handleOnOpenCommunityChat = () => {
    navigate('/chats/communityChat')
  }

  const handleOnOpenEventsChat = () => {
    navigate('/chats/eventChat')
  }

  return (
    <Flex justify="space-around" textAlign="center" py="20px">
      <Text fontSize="1.25rem" cursor="pointer" onClick={handleOnOpenCommunityChat} opacity={0.5} fontWeight="semibold">
        Città
      </Text>

      <Text fontSize="1.25rem" cursor="pointer" onClick={handleOnOpenEventsChat} opacity={0.5} fontWeight="semibold">
        Eventi
      </Text>

      <Text fontSize="1.25rem" fontWeight="semibold">
        Utenti
      </Text>
    </Flex>
  )
}

function NoOneSelectedTabs() {
  return <Navigate replace to="/chats/communityChat" />
}

export default Tabs
