import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Link as UILink,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { selectorAuth } from 'features/auth/auth.slice'
import React, { Fragment } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import Api from 'services/api/api.service'
import { Attendance, Event } from 'typings/database'
import { genderMap } from '../../../costants/genders'
import { AttendeesIcon } from '../icons/icons'
import InputField from '../input/input.component'
import SelectField from '../select/select.component'
import UserAvatar from '../user-avatar/user-avatar'
import Wrapper from '../wrapper/wrapper.component'

interface AttendanceRowProps {
  attendance: Attendance
  organizer?: boolean
  isComplainEnabled: boolean
}

interface AttendeesProps {
  count?: number | string
  max: number | string
  list?: Attendance[]
  limitations?: Event['limitations']
  counts?: Event['genderBalanceCounts']
  organizerAttendance?: Event['organizer']
  organizer?: Event['organizer']
  endDate: Event['endDate']
}

interface AttendeesCountProps {
  clickable?: boolean
  count?: number | string
  max: number | string
  onClick?: () => void
}

interface ComplainModalProps {
  isOpen: boolean
  onClose: () => void
  userId: string
  userName: string
}

const COMPLAIN_USER_AFTER_EVENT_MILLIS = 86400000

enum ComplainTypes {
  absence = 'absence',
  damage = 'damage',
  harassment = 'harassment',
  other = 'other',
}

interface ComplainForm {
  type: ComplainTypes
  description?: string
}

const complainTypeLabels = {
  [ComplainTypes.absence]: 'Assenza',
  [ComplainTypes.damage]: 'Danni',
  [ComplainTypes.harassment]: 'Molestie',
  [ComplainTypes.other]: 'Altro',
}

const complainDefaultValue = { type: ComplainTypes.absence, description: undefined }

const ComplainModal = ({ onClose, isOpen, userId, userName }: ComplainModalProps) => {
  const { id } = useParams()
  const toast = useToast()

  const form = useForm<ComplainForm>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { ...complainDefaultValue },
  })
  const { register, handleSubmit } = form

  const onSubmit = async (data: ComplainForm) => {
    const body = {
      ...data,
      userId,
    }
    try {
      if (!id) return

      const result = await Api.complainUserInEvent(id!, body)
      if (result && result.status === 200) {
        toast({
          title: 'Utente segnalato con successo',
          status: 'success',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
        onClose()
      }
    } catch (e: any) {
      if (e?.response?.data?.message) {
        toast({
          title: e.response.data.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
      if (typeof e?.response?.data === 'string') {
        toast({
          title: e.response.data,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }

      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Segnala {userName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="20px">
          <Wrapper>
            <FormProvider {...form}>
              <form style={{ display: 'contents' }} onSubmit={handleSubmit(onSubmit)}>
                <Box mb="30px">
                  <Text pl="18px" pb="5px" fontWeight="semibold">
                    Tipologia
                    <Text as="span" color="brand.500" ml="4px" mt="-2px" fontSize="26px" position="absolute">
                      *
                    </Text>
                  </Text>
                  <SelectField backgroundColor="#eeeeee" borderRadius="15px" {...register('type', { required: true })}>
                    {Object.entries(complainTypeLabels).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </SelectField>
                </Box>
                <Box mb="30px">
                  <InputField type="textarea" placeholder="Descrizione" maxLength={150} required {...register('description', { required: true })} />
                </Box>
                <Button width="90%" alignSelf="center" type="submit" mb="30px">
                  Invia
                </Button>
              </form>
            </FormProvider>
          </Wrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const AttendanceRow = ({ attendance, organizer, isComplainEnabled }: AttendanceRowProps) => {
  const { isOpen: isOpenComplain, onOpen: onOpenComplain, onClose: onCloseComplain } = useDisclosure()

  return (
    <>
      <Flex align="center" justify="space-between" width="100%">
        <Flex alignItems="center">
          <Link to={'/profile/' + attendance.user._id} key={attendance.user._id}>
            <Flex css={{ gap: '14px' }} align="center">
              <Box height="38px" width="38px">
                <UserAvatar src={attendance.user.avatar} size="full" />
              </Box>
              <Flex direction="column">
                <Flex alignItems="center">
                  <Text fontSize="16px" fontWeight="600">
                    {attendance.user.firstName} {organizer && '(organizzatore)'}
                  </Text>
                  {attendance.approvedAt && (
                    <Box ml="10px">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                        <circle id="Ellisse_143" data-name="Ellisse 143" cx="10.5" cy="10.5" r="10.5" fill="#45ff36" />
                        <path
                          id="Tracciato_197"
                          data-name="Tracciato 197"
                          d="M-2643.3,4807.736l3.59,3.59,7.09-7.09"
                          transform="translate(2648.166 -4797.226)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="2"
                        />
                      </svg>
                    </Box>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Link>
        </Flex>
        {isComplainEnabled && <UILink onClick={onOpenComplain}>Segnala</UILink>}
      </Flex>

      {isComplainEnabled && isOpenComplain && (
        <ComplainModal isOpen onClose={onCloseComplain} userId={attendance.user._id} userName={attendance.user.firstName} />
      )}
    </>
  )
}

const AttendeesCount = ({ clickable, count, max, onClick }: AttendeesCountProps) => {
  if (count) {
    return (
      <Button
        variant="outline"
        height="30px"
        fontFamily="Poppins"
        fontWeight="500"
        fontSize="15px"
        lineHeight="18px"
        textAlign="center"
        width="62px"
        pl="9"
        pr="9"
        onClick={onClick}>
        Lista
      </Button>
    )
  }

  return (
    <Box w="auto" cursor={clickable ? 'pointer' : undefined} onClick={onClick}>
      <Text>
        <AttendeesIcon boxSize={6} mb="3px" mr="8px" />
        {max}
      </Text>
    </Box>
  )
}

const Attendees = ({ count, max, list, limitations, counts, organizerAttendance, organizer, endDate }: AttendeesProps) => {
  const { isOpen: isOpenAttendances, onOpen: onOpenAttendances, onClose: onCloseAttendances } = useDisclosure()

  const { user } = useSelector(selectorAuth)

  if (!list) {
    return <AttendeesCount count={count} max={max} />
  }

  const isUserOrganizer = organizer._id === user._id
  const isUserInvolved = isUserOrganizer || (list || []).some(att => att.user._id === user._id)

  const now = Date.now()
  const endDateTime = new Date(endDate).getTime()
  const isComplainPeriod = now > endDateTime && now < endDateTime + COMPLAIN_USER_AFTER_EVENT_MILLIS

  return (
    <>
      <AttendeesCount clickable count={count} max={max} onClick={() => list && (isOpenAttendances ? onCloseAttendances() : onOpenAttendances())} />

      <Modal isCentered isOpen={isOpenAttendances} onClose={onCloseAttendances}>
        <ModalOverlay bg="blackAlpha.400" backdropFilter="blur(3px)" />
        <ModalContent mr="10px" ml="10px">
          <ModalHeader fontWeight="bold">Partecipanti</ModalHeader>
          <ModalCloseButton size="lg" />
          <ModalBody pb="20px">
            {limitations?.genderBalanceActive && (
              <Flex justifyContent="space-evenly" mb="20px">
                {genderMap
                  .filter(([slug]) => limitations[slug + 'Participants'] > 0)
                  .map(([slug, label]) => {
                    return (
                      <Box key={label}>
                        <Text textAlign="center">
                          {counts[slug]}/{limitations[slug + 'Participants']}
                        </Text>
                        <Text fontWeight="medium">{label}</Text>
                      </Box>
                    )
                  })}
              </Flex>
            )}
            <Flex direction="column" css={{ gap: '14px' }}>
              {organizerAttendance && (
                <AttendanceRow
                  attendance={organizerAttendance}
                  organizer
                  isComplainEnabled={isComplainPeriod && isUserInvolved && !isUserOrganizer}
                />
              )}
              {list.map((a, i) => (
                <Fragment key={a._id}>
                  <AttendanceRow attendance={a} isComplainEnabled={isComplainPeriod && isUserInvolved && a.user._id !== user._id} />
                  {i < list.length - 1 && <Divider />}
                </Fragment>
              ))}
              {list.length === 0 && 'Non ci sono partecipanti'}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Attendees
