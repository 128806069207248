import Map from 'components/shared/map/map.component'
import useOnLongPress from 'hooks/use-on-long-press'
import { default as React } from 'react'
import { Box } from '@chakra-ui/react'

function PositionMessage({ msg }) {
  const { getIsLongPressOngoing } = useOnLongPress({ namespace: 'chat' })

  const coords = msg?.attributes?.coords

  const handleOnClick = () => {
    const lon = coords?.[0]
    const lat = coords?.[1]

    if (getIsLongPressOngoing()) {
      return
    }

    window.open('https://maps.google.com/?q=' + lat + ',' + lon, '_blank')
  }

  return (
    <Box onClick={handleOnClick} color="#465362" fontWeight="500" lineHeight="1.2rem">
      <Map isFake={false} center={coords} />
    </Box>
  )
}

export default PositionMessage
