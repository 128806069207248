import { Flex } from '@chakra-ui/react'
import Wrapper from '@components/shared/wrapper/wrapper.component'
import { selectorAuth } from 'features/auth/auth.slice'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import LoginScreenForm from './login-screen-form'
import ScreenHeader from '@components/shared/screen-header/screen-header'

function LoginScreen() {
  const authState = useSelector(selectorAuth)

  if (authState.isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: '/',
        }}
      />
    )
  }

  return (
    <Wrapper height="100%" minHeight="40rem" pt="1rem">
      <Flex flexDir="column" gap="2.5rem" height="100%">
        <ScreenHeader title="Che bello rivederti!" mx="0" doLogout={true} />
        <LoginScreenForm />
      </Flex>
    </Wrapper>
  )
}

export default LoginScreen
