import React, { useState, useRef, useEffect } from 'react'
import { useUserMedia } from '../../../hooks/use-user-media'
import jsQR from 'jsqr'
import { Box } from '@chakra-ui/react'

const CAPTURE_OPTIONS = {
  video: { facingMode: 'environment' },
}

const QrReader = ({ onQrFound }) => {
  const [videoIsLoading, setVideoIsLoading] = useState(true)
  // const [qrResult, setQrResult] = useState({
  //   label: '',
  //   timestamp: '',
  // })
  const videoRef = useRef()
  const canvasRef = useRef()
  const mediaStream = useUserMedia(CAPTURE_OPTIONS)

  // const drawLine = (begin, end, color) => {
  //   const context = canvasRef.current.getContext('2d')
  //   context.beginPath()
  //   context.moveTo(begin.x, begin.y)
  //   context.lineTo(end.x, end.y)
  //   context.lineWidth = 4
  //   context.strokeStyle = color
  //   context.stroke()
  // }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tick = () => {
    if (videoRef.current && videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
      setVideoIsLoading(false)
      if (canvasRef.current) {
        const context = canvasRef.current?.getContext('2d')
        canvasRef.current.height = videoRef.current.videoHeight
        canvasRef.current.width = videoRef.current.videoWidth
        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height)
        const imageData = context.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height)
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert',
        })
        if (code) {
          // drawLine(code.location.topLeftCorner, code.location.topRightCorner, '#FF3B58')
          // drawLine(code.location.topRightCorner, code.location.bottomRightCorner, '#FF3B58')
          // drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, '#FF3B58')
          // drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, '#FF3B58')
          onQrFound(code.data)
          // setQrResult({
          //   ...qrResult,
          //   label: code.data,
          // })
        }
      }
    }
    requestAnimationFrame(tick)
  }

  useEffect(() => {
    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
      videoRef.current.srcObject = mediaStream
      requestAnimationFrame(tick)
    }
  }, [mediaStream, videoRef, tick, videoIsLoading])

  const handleCanPlay = () => {
    videoRef.current.play()
  }

  // useEffect(() => {
  //   return () => {
  //     mediaStream.getTracks().forEach(track => {
  //       track.stop()
  //     })
  //   }
  // }, [])

  return (
    <div id="container">
      <video id="player" ref={videoRef} onCanPlay={handleCanPlay} autoPlay playsInline muted />
      {!videoIsLoading && (
        <Box hidden>
          <canvas id="canvas" ref={canvasRef}></canvas>
        </Box>
      )}
    </div>
  )
}

export default QrReader
