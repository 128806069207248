import { Client as ConversationsClient } from "@twilio/conversations"

export const checkNewMessages = async (client: ConversationsClient) => {
    const { items: conversations } = await client.getSubscribedConversations()

    const conversationsWithNewMessages: string[] = []
    for (const conversation of conversations) {
      const unreadMessages = await conversation.getUnreadMessagesCount()
      if (unreadMessages && unreadMessages > 0) {
        if (!conversationsWithNewMessages.includes(conversation.sid)) {
            conversationsWithNewMessages.push(conversation.sid)
        }
      }
    }
    return conversationsWithNewMessages
  }