export type Reply = {
  author: string | null
  authorId: string | null | undefined
  body: string | null
  sid: string
  time: string
}

export enum MessageType {
  Location = 'location',
  Media = 'media',
  Poll = 'poll',
  Event = 'event',
  EventPreview = 'event-preview',
  Text = 'text',
}

export type MessageAttributes = {
  type: MessageType
  reply: Reply
  deleted?: number
}
