import { Clipboard } from '@capacitor/clipboard'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react'
import React, { useRef } from 'react'

const ShareEventDialog = props => {
  const cancelSocialRef = useRef(null)

  const copyLink = async () => {
    await Clipboard.write({
      url: `${process.env.REACT_APP_APP_URL}${window.location.pathname}`,
    })
    props.setCopied(true)
  }

  return (
    <AlertDialog isCentered isOpen={props.isSocialOpen} leastDestructiveRef={cancelSocialRef} onClose={props.onSocialClose}>
      <AlertDialogOverlay bg="blackAlpha.400" backdropFilter="blur(3px)">
        <AlertDialogContent mr="10px" ml="10px">
          <AlertDialogHeader fontWeight="bold">Condividi sui social</AlertDialogHeader>
          <ModalCloseButton size="lg" />
          <AlertDialogBody>
            {/* <FacebookShareButton translate url={window.location.href}>
                            <Button mb="10px">Facebook</Button>
                          </FacebookShareButton>
                          <br />
                          <WhatsappShareButton translate url={window.location.href}>
                            <Button mb="10px">Whatsapp</Button>
                          </WhatsappShareButton>
                          <br />
                          <TelegramShareButton translate url={window.location.href}>
                            <Button mb="10px">Telegram</Button>
                          </TelegramShareButton> */}
            <br />
            <Button
              fontWeight="600"
              fontFamily="Poppins"
              textAlign="center"
              height="40px"
              width="100%"
              fontSize="17px"
              onClick={copyLink}
              mb="10px"
              variant="outline">
              Copia link{' '}
              {props.copied && (
                <Text color="gray" fontSize="17px" ml="15px">
                  copiato
                </Text>
              )}
            </Button>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default ShareEventDialog
