import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { useToast } from '@chakra-ui/react'
import { MessageType, Reply } from '@screens/chat/types'
import { JSONValue } from '@twilio/conversations'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import dataURLtoFile from 'utils/data-url-to-file'
import useGetConversationQueryBySid from '../hooks/use-get-conversation-query'

const useSendMediaMessageMutation = (options = {}): any => {
  const { sid } = useParams()

  const { data } = useGetConversationQueryBySid(sid)
  const conversation = data?.conversation

  const toast = useToast()

  return useMutation(
    'send-media-message',
    async (reply?: Reply) => {
      const image = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
        webUseInput: true,
      })

      if (!image.dataUrl) {
        return
      }

      const file = dataURLtoFile(image.dataUrl)

      const fileData = new FormData()
      fileData.set(file.name, file, file.name)

      const attributes = reply ? ({ type: MessageType.Media, reply } as JSONValue) : ({ type: MessageType.Media } as JSONValue)

      await conversation?.self?.prepareMessage().addMedia(fileData).setAttributes(attributes).build().send()
    },
    {
      onError(error: any) {
        console.error(error)

        toast({
          title: error?.message || 'Qualcosa è andato storto',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        })
      },
      ...options,
    },
  )
}

export default useSendMediaMessageMutation
