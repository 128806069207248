import React, { useState } from 'react'
import { HStack, Text, useToast } from '@chakra-ui/react'
import { Flex, Button, Box } from '@chakra-ui/react'
import InputField from 'components/shared/input/input.component'
import { ReactComponent as BoltsIcon } from './../../assets/bolts.svg'
import { ReactComponent as BoltcostIcon } from './../../assets/boltcost.svg'
import { useSelector } from 'react-redux'
import { selectorAuth } from 'features/auth/auth.slice'

const MaxParticipantsInput = props => {
  const [selectedOption, setSelectedOption] = useState<number | string>(props.maxParticipants)
  const { user } = useSelector(selectorAuth)
  const initialLightningAmount =
    props.maxParticipants !== 'Altro' ? user?.wallet?.lightningAmount - props.maxParticipants : user?.wallet?.lightningAmount
  const [lightningAmount, setLightningAmount] = useState(initialLightningAmount)
  const toast = useToast()

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" mb="2">
        {[5, 10, 15, 20, 'Altro', 'bolts'].map((option, index) =>
          option !== 'bolts' ? (
            <Button
              key={index}
              pl="23px"
              pr="23px"
              fontSize="md"
              height="38px"
              width="35px"
              margin="0.8"
              size="md"
              color={option === selectedOption ? 'white' : '#737377'}
              variant={option === selectedOption ? 'solid_static' : 'ghost_border'}
              borderRadius={20}
              onClick={() => {
                if (option !== 'Altro') {
                  props.setValue(props.fieldName, option)
                  props.updateGendersDefault()
                  setLightningAmount(user?.wallet?.lightningAmount - Number(option))
                  if (user?.wallet?.lightningAmount < Number(option)) {
                    toast({
                      title: 'Non hai abbastanza saette',
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                      position: 'bottom',
                    })
                  }
                }
                setSelectedOption(option)
              }}>
              {option}
            </Button>
          ) : (
            <Box
              key={index}
              fontSize="md"
              height="38px"
              width="70px"
              padding="0"
              margin="0.8"
              borderColor="gray"
              borderRadius={20}
              position="relative"
              background="linear-gradient(107deg, rgba(255,116,54,1) 0%, rgba(255,163,54,1) 100%)">
              <Text position="absolute" left="0.8rem" top="0.4rem" color="white" fontWeight="semibold">
                {lightningAmount}
              </Text>
              <Box position="absolute" left="2.8rem" top="0.2rem">
                <BoltsIcon />
              </Box>
            </Box>
          ),
        )}
      </Flex>
      {selectedOption === 'Altro' && (
        <Box position="relative">
          <InputField
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLightningAmount(user?.wallet?.lightningAmount - Number(e.target.value))
              props.setValue(props.fieldName, e.target.value)
              if (user?.wallet?.lightningAmount < Number(e.target.value)) {
                toast({
                  title: 'Non hai abbastanza saette',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                  position: 'bottom',
                })
              }
              props.updateGendersDefault()
            }}
            min="2"
            max={user?.wallet?.lightningAmount}
            type="number"
            placeholder="Inserisci un altro limite"
          />
          <HStack spacing="1px" position="absolute" right="0" bottom="-5">
            <Text fontSize="0.7rem">1</Text>
            <BoltcostIcon />
          </HStack>
        </Box>
      )}
    </>
  )
}

export default MaxParticipantsInput
