import { User } from '@twilio/conversations'
import { AppContext } from 'app.context'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

const useGetUserByIdentityFromPartecipants = (identity: string, options = {}) => {
  const { sid } = useParams()
  const { client: twilioClient } = useContext(AppContext)

  return useQuery(
    `get-user-by-identiy-from-partecipants-${identity}`,
    async (): Promise<User | undefined> => {
      if (!twilioClient || !sid) {
        return undefined
      }

      const conversation = await twilioClient.getConversationBySid(sid)
      const partecipants = await conversation.getParticipants()
      const users = await Promise.all(partecipants.map(p => p.getUser()))

      const user: User | undefined = users.find(u => u.identity === identity)

      return user
    },
    {
      enabled: !!twilioClient && !!identity,
      ...options,
    },
  )
}

export default useGetUserByIdentityFromPartecipants
