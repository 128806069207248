import { AppContext } from 'app.context'
import React, { useContext } from 'react'
import ChatMessage from '../chat-messages'
import ChatMessageReplyBox from '../chat-messages/chat-message-reply-box'
import DeletedMessage from '../chat-messages/deleted-message'
import useGetUserFromMessageQuery from '../hooks/use-get-user-from-message'

function ChatBoxBody({ msg }) {
  const { client: twilioClient } = useContext(AppContext)

  const { data: user } = useGetUserFromMessageQuery(msg)

  const attributes = msg?.attributes
  const isMine = user?.identity === twilioClient?.user.identity

  if (attributes.deleted) {
    return <DeletedMessage msg={msg} />
  }

  return (
    <>
      {attributes?.reply && <ChatMessageReplyBox isMine={isMine} author={attributes?.reply.author} body={attributes?.reply.body} />}

      <ChatMessage msg={msg} />
    </>
  )
}
export default ChatBoxBody
