import { Message } from '@twilio/conversations'
import { useQuery } from 'react-query'

export type MediaInfo = {
  url?: string | null
}

const useGetMediaFromMessageQuery = (message: Message, options = {}) => {
  return useQuery(
    `message_media_${message.sid}`,
    async (): Promise<MediaInfo | undefined> => {
      const media = await message.attachedMedia?.at(0)
      const url = await media?.getContentTemporaryUrl()

      return { url }
    },
    {
      enabled: !!message,
      ...options,
    },
  )
}

export default useGetMediaFromMessageQuery
