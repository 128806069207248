import { Button } from '@chakra-ui/react'
import React from 'react'

const EmailAccessButton = (props: { authProvider: string; setEmailAccess: any }) => {
  const showEmailField = () => {
    if (!props.authProvider) {
      props.setEmailAccess(true)
    }
  }

  return (
    <Button
      width="90%"
      disabled={!!props.authProvider}
      variant="outline"
      borderColor="white"
      color="white"
      onClick={showEmailField}
      _hover={{ color: 'black', bgColor: 'white' }}>
      Accedi con email
    </Button>
  )
}

export default EmailAccessButton
