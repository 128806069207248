import { useCallback, useEffect, useState } from 'react'

type Arguments = {
  namespace: string
  callback?: any
  millis?: number
}

type Response = {
  getIsLongPressOngoing: () => boolean
  onLongPressStart: () => void
  onLongPressEnd: () => void
  abortOnLongPress: () => void
  abortOnLongPressWithCustomEvents: (payload?: any) => void
}

const timeoutID = {}
const isLongPressOngoing = {}

const useOnLongPress = ({ namespace, callback, millis = 500 }: Arguments): Response => {
  const [startLongPress, setStartLongPress] = useState<boolean>(false)

  const onLongPressStart = useCallback(() => {
    isLongPressOngoing[namespace] = false
    setStartLongPress(() => true)
  }, [namespace])

  const onLongPressEnd = useCallback(() => {
    setStartLongPress(() => false)
  }, [])

  // *** ABORT ***
  const abortOnLongPress = useCallback(() => {
    clearTimeout(timeoutID[namespace])
  }, [namespace])

  const abortOnLongPressWithCustomEvents = useCallback(payload => {
    window.dispatchEvent(new CustomEvent(ABORT_ON_LONG_PRESS_EVENT, payload))
  }, [])

  useEffect(() => {
    window.addEventListener(ABORT_ON_LONG_PRESS_EVENT, e => {
      clearTimeout(timeoutID[namespace])
    })
  }, [namespace])
  // *** END ABORT ***

  const getIsLongPressOngoing = () => isLongPressOngoing[namespace]

  useEffect(() => {
    if (startLongPress) {
      timeoutID[namespace] = setTimeout(() => {
        isLongPressOngoing[namespace] = true

        if (callback) {
          callback()
        }

        setStartLongPress(() => false)
      }, millis)
    } else {
      clearTimeout(timeoutID[namespace])
    }

    return () => {
      clearTimeout(timeoutID[namespace])
    }
  }, [callback, millis, namespace, startLongPress])

  return { getIsLongPressOngoing, onLongPressStart, onLongPressEnd, abortOnLongPress, abortOnLongPressWithCustomEvents }
}

export const ABORT_ON_LONG_PRESS_EVENT = 'abort-on-long-press-event'
export default useOnLongPress
