import React, { useMemo } from 'react'
import { Box } from '@chakra-ui/react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'

const Map = ({
  center = [0, 0],
  isFake,
  onLoad,
  onCenterChange,
  options,
  ...rest
}: {
  center: [number, number]
  isFake: boolean
  onLoad?: (map: google.maps.Map) => void
  onCenterChange?: () => void
  onDragEnd?: () => void
  options?: google.maps.MapOptions
}) => {
  const googleMapOptionsOverride = options || {}

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAUfWBJncTuBeMgKQL4iHSwNf62Aaa8XkI',
  })

  const coords = useMemo(() => ({ lat: center[1], lng: center[0] }), [center])

  const handleOnLoad = map => {
    onLoad?.(map)
  }

  const handleOnCenterChanged = () => {
    onCenterChange?.()
  }

  if (!isLoaded) {
    return null
  }

  return (
    <Box position="relative">
      {isFake && (
        <>
          <Box position="absolute" zIndex="10" top="50%" left="50%" transform="translate(-50%,-50%)">
            <Box bg="#FF7436" opacity=".5" height="100px" width="100px" borderRadius="100%"></Box>
          </Box>
          <Box position="absolute" zIndex="10" top="50%" left="50%" transform="translate(-50%,-50%)">
            <Box bg="#FF7436" opacity=".5" height="80px" width="80px" borderRadius="100%"></Box>
          </Box>
          <Box position="absolute" zIndex="10" top="50%" left="50%" transform="translate(-50%,-50%)">
            <Box bg="#FF7436" opacity=".5" height="60px" width="60px" borderRadius="100%"></Box>
          </Box>
        </>
      )}

      {!isFake && (
        <>
          <Box position="absolute" zIndex="10" top="50%" left="50%" transform="translate(-50%,-50%)">
            <Box bg="#FF7436" opacity=".8" height="30px" width="30px" borderRadius="100%"></Box>
          </Box>
        </>
      )}

      <GoogleMap
        options={{
          disableDefaultUI: true,
          disableDoubleClickZoom: true,
          draggable: false,
          scrollwheel: false,
          panControl: false,
          ...googleMapOptionsOverride,
        }}
        center={coords}
        zoom={isFake ? 12 : 13}
        onLoad={handleOnLoad}
        onCenterChanged={handleOnCenterChanged}
        mapContainerStyle={{ width: '100%', height: '25vh' }}
        {...rest}></GoogleMap>
    </Box>
  )
}

export default Map
