import { Flex } from '@chakra-ui/react'
import React from 'react'
import EmailField from './email-field'
import PasswordFieldFlex from './password-field-flex'
import useQueryParams from 'hooks/use-query-params'
import { useNavigate } from 'react-router'

const LoginScreenFields = (props: { register: any }) => {
  const queryParams = useQueryParams()
  const email = queryParams.get('email')
  const navigate = useNavigate()

  if (email === null) {
    navigate('/')
  }

  return (
    <Flex flexDir="column" gap="2rem">
      <EmailField register={props.register} email={email} label="Email dell'account" placeholder="marcorossi@gmail.com" />
      <PasswordFieldFlex register={props.register} />
    </Flex>
  )
}

export default LoginScreenFields
