import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import OnVisible from 'react-on-visible'
import { Link } from 'react-router-dom'
import { Notification } from 'typings/database'
import { NotificationArrow } from '../icons/icons'

const NotificationRow = (props: Notification) => {
  const renderReadDot = () => {
    if (!props.readAt) {
      return (
        <Box
          position="absolute"
          zIndex="1"
          top="0"
          left="0"
          fontSize="10px"
          textTransform="uppercase"
          color="#FF7436"
          borderRadius="100%"
          fontWeight="normal">
          Nuova
        </Box>
      )
    }
    return null
  }
  const renderNotification = () => {
    switch (props.notificationType) {
      case 'Events':
        return (
          <Link key={props._id} to={`/event/` + props._id}>
            <Flex py="12px" alignItems="center" justify="space-between">
              <Text fontSize="14px" fontWeight="normal">
                {props.content}
              </Text>
              <NotificationArrow />
            </Flex>
          </Link>
        )

      case 'NewAttendance':
        return (
          <Link key={props._id} to={`/event/` + props.referenceId}>
            <Flex py="12px" alignItems="center" justify="space-between">
              <Text fontSize="14px" fontWeight="normal">
                {props.content}
              </Text>
              <NotificationArrow />
            </Flex>
          </Link>
        )
      case 'NewFollowerRequest':
        return (
          <Link key={props._id} to={`/profile/` + props.referenceId}>
            <Flex py="12px" alignItems="center" justify="space-between">
              <Text fontSize="14px" fontWeight="normal">
                {props.content}
              </Text>
              <NotificationArrow />
            </Flex>
          </Link>
        )
      default:
      case 'Others':
        return (
          <Flex py="12px" alignItems="center" justify="space-between">
            <Text fontSize="14px" fontWeight="normal">
              {props.content}
            </Text>
          </Flex>
        )
    }
  }

  return (
    <Box position="relative" overflow="hidden" lineHeight="1.2" mb="10px" borderBottom="1px solid #F7F7F7">
      {/* <Avatar size="sm" /> */}
      <OnVisible
        onChange={v => {
          if (!props.readAt && v) {
            props.onChange && props.onChange(props._id)
          }
        }}>
        {renderNotification()}
        {renderReadDot()}
      </OnVisible>
    </Box>
  )
}

export default NotificationRow
