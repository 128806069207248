import {
  Text,
  Flex,
  Heading,
  Spacer,
  CloseButton,
  OrderedList,
  ListItem,
  Card,
  CardBody,
  Icon,
  VStack,
  Button,
  useToast,
  Link,
} from '@chakra-ui/react'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as MonitionCard } from '../../assets/admonition-card.svg'
import { ReactComponent as LogoWhite } from '../../assets/logo-lightning-white.svg'

import React from 'react'

import { refreshUser } from 'features/auth/auth.actions'
import useCurrentUserStatus from 'hooks/use-user-status'

import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import Api from 'services/api/api.service'
import { WarningTypeModel } from 'typings/database.d'

const MonitionScreen = () => {
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { activeWarning, currentUserStatus } = useCurrentUserStatus()
  const toast = useToast()
  const queryClient = useQueryClient()

  const onResolve = async () => {
    if (!activeWarning._id) return

    try {
      const result = await Api.resolveWarning(activeWarning._id)
      if (result && result.status === 200) {
        toast({
          title: 'Ammonizione risolta con successo',
          status: 'success',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
      dispatch<any>(refreshUser())
      await queryClient.invalidateQueries('events')
      navigate('/')
    } catch (e: any) {
      if (e?.response?.data?.message) {
        toast({
          title: e.response.data.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
      if (typeof e?.response?.data === 'string') {
        toast({
          title: e.response.data,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
    }
  }

  return (
    <Wrapper height="100%" pb="90px" pl="10px" pr="10px" pt="20px">
      <Flex>
        <Heading as="h1" size="lg">
          Ammonitə!
        </Heading>
        <Spacer />
        <CloseButton
          size="lg"
          onClick={() => {
            navigate(-1)
          }}
        />
      </Flex>
      <Heading as="h2" size="md" mt="30px">
        Come si viene ammonitə?
      </Heading>
      <OrderedList mt="10px">
        <ListItem>Non ti sei presentato ad un evento a cui hai partecipato</ListItem>
        <ListItem>Sei stato segnalato per comportamenti scorretti</ListItem>
      </OrderedList>
      <Heading as="h2" size="md" mt="10px">
        Cosa comporta?
      </Heading>
      <Card mt="20px" borderRadius="15px" variant="outline">
        <CardBody>
          <Heading as="h3" size="sm">
            <Icon boxSize="6">
              <MonitionCard />
            </Icon>
            Ammonitə
          </Heading>
          <Text mt="5px">Costo di partecipazione saette aumentato per i prossimi 4 eventi, annullabile pagando una penale in saette.</Text>
        </CardBody>
      </Card>
      <Text size="lg" mt="20px" textAlign="center" textDecoration="underline">
        Rischi il ban per 30 giorni
      </Text>
      <Text size="lg" textAlign="center" textDecoration="underline">
        in caso di ulteriore ammonizione!
      </Text>
      <VStack mt="20px" spacing="20px">
        <Button
          width="100%"
          height="40px"
          onClick={onResolve}
          rightIcon={
            <Icon color="white" boxSize="10" viewBox="0 -10 48 48">
              <LogoWhite />
            </Icon>
          }>
          Paga {activeWarning.cost}
        </Button>
        <Link width="100%" href="https://sbam.social/supporto" isExternal>
          <Button width="100%" height="40px" variant="outline">
            Vai al supporto
          </Button>
        </Link>
      </VStack>
    </Wrapper>
  )
}

export default MonitionScreen
