import React from 'react'
import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { ModalProps } from '@components/types'
import { GiHamburgerMenu } from 'react-icons/gi'

export const EventChatOnboardingModal = ({ isOpen, onClose }: ModalProps) => {
  return (
    <Modal isCentered closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent borderRadius="15px" maxWidth="90vw">
        <ModalHeader>Come funziona la chat eventi?</ModalHeader>
        <ModalBody>
          <Text>
            Qui trovi le chat degli eventi a cui partecipi. Usale per parlare con gli altri partecipanti e rompere il ghiaccio prima di incontrarsi
            dal vivo.
            <br />
            <br />
            P.S. Se non puoi più presentarti, ricorda di avvisare in chat e annullare la tua partecipazione o rischi il ban. Per farlo vai su “
            <Box display="inline-block" cursor="pointer" fontSize="1rem" verticalAlign="middle">
              <GiHamburgerMenu />
            </Box>
            “ dentro l’evento.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button alignSelf="center" size="sm" width="100%" borderRadius="10px" onClick={onClose}>
            Ok, tutto chiaro!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
