import { Box, Flex, FormControl, Text } from '@chakra-ui/react'
import InputField from '@components/shared/input/input.component'
import React from 'react'

function NameSurnameFields(props: { errors: any; register: any }) {
  return (
    <Box>
      <Text textColor="#465362" fontWeight="600" fontSize="0.9rem">
        Come ti chiami?{' '}
        <Text color="#FF7436" display="inline">
          *
        </Text>
      </Text>
      <Flex flexDir="row" gap="1rem">
        <FormControl isInvalid={!!props.errors.firstName}>
          <InputField
            placeholder="Il tuo nome"
            {...props.register('firstName', {
              required: 'Devi specificare un nome',
            })}
          />
        </FormControl>
        <FormControl isInvalid={!!props.errors.lastName}>
          <InputField
            placeholder="Il tuo cognome"
            {...props.register('lastName', {
              required: 'Devi specificare un cognome',
            })}
          />
        </FormControl>
      </Flex>
    </Box>
  )
}

export default NameSurnameFields
