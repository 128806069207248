import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React from 'react'

function MediaMessageModal({ url, isOpen, onClose }: { url: string; isOpen: boolean; onClose: () => void }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader></ModalHeader>
        <ModalBody pb="40px">
          <img src={url} alt="chat-media" />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default MediaMessageModal
