import { Message, Paginator } from '@twilio/conversations'
import { useRef } from 'react'
import { useMutation } from 'react-query'
import useGetConversationQueryBySid from './use-get-conversation-query'
import { useParams } from 'react-router-dom'

const useGetToNextPageMutation = (options = {}) => {
  const { sid } = useParams()

  const currentPaginator = useRef<Paginator<Message>>()
  const { data, isSuccess } = useGetConversationQueryBySid(sid)

  return useMutation(
    'get-next-page',
    async () => {
      if (!isSuccess) {
        return
      }

      if (!currentPaginator.current) {
        const paginator = data!.paginator
        currentPaginator.current = paginator
      }

      const newPaginator = await currentPaginator.current.prevPage()
      currentPaginator.current = newPaginator

      return { messages: newPaginator.items, paginator: newPaginator }
    },
    {
      ...options,
    },
  )
}

export default useGetToNextPageMutation
