import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { FaReply } from 'react-icons/fa'

function ChatMessageReplyBox({ author, body, isMine }) {
  return (
    <Flex
      alignItems="center"
      borderBottomWidth="1px"
      borderColor={isMine ? 'rgba(255, 116, 54, .5)' : undefined}
      className="chat-message-reply-box"
      color="#465362"
      direction="row"
      fontWeight="500"
      gap="1rem"
      justifyContent="space-between"
      lineHeight="1.2rem"
      marginBottom=".5rem"
      padding=".25rem"
      px="1rem"
      width="100%">
      <Flex direction="row" gap="1rem" alignItems="center" width="100%">
        <Box color="brand.500">
          <FaReply />
        </Box>

        <Flex direction="column" grow={1} overflowX="hidden">
          <Box textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
            {'Risponde a '}
            <Box as="span" fontWeight={600}>
              {author}
            </Box>
          </Box>

          <Box textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" fontStyle="italic">
            {body}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ChatMessageReplyBox
