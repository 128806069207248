import { Box, useToast } from '@chakra-ui/react'
import { useAppDispatch } from 'features'
import { loginAction } from 'features/auth/auth.actions'
import { signInWithEmailAndPassword } from 'firebase/auth'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { getFirebaseAuth } from 'services/firebase.service'
import LoginScreenFields from './login-screen-fields'
import LoginScreenFormFooter from './login-screen-form-footer'
import { useNavigate, createSearchParams } from 'react-router-dom'

const LoginScreenForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { register, handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const showErrors = errors => {
    if (errors.email) {
      toast({
        title: 'Inserisci email',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      })
    }
    if (errors.password) {
      toast({
        title: 'Inserisci password',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      })
    }
  }

  const onSubmit = async (data: any) => {
    
    const auth = getFirebaseAuth()
    try {
      setIsLoading(true)
      const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password)
      if(userCredential){
        console.log('something went wrong')
      }

      const { claims } = await userCredential.user.getIdTokenResult(true)
      if (claims.sbamRegistered) {
        dispatch<any>(loginAction())
      } else {
        navigate(`/signup?${createSearchParams({ email: userCredential.user.email + '', isSSO: 'true' })}`)
        
      }
    } catch (err) {
      console.log(err)
      toast({
        title: 'Credenziali non corrette',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
      setIsLoading(false)
    }
  }

  return (
    <form style={{ display: 'contents' }} onSubmit={handleSubmit(onSubmit, showErrors)}>
      <Box position="relative" height="100%">
        <LoginScreenFields register={register} />
        <LoginScreenFormFooter isLoading={isLoading} />
      </Box>
    </form>
  )
}

export default LoginScreenForm
