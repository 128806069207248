import React, { createContext, useContext, useMemo, useState } from 'react'

interface IMessageContext {
  hideMessage: boolean
  setHideMessage: React.Dispatch<React.SetStateAction<boolean>>
}

const messageContext = createContext<IMessageContext | undefined>(undefined)

function MessageContextProvider({ children }) {
  const [hideMessage, setHideMessage] = useState<boolean>(false)

  const value = useMemo(
    () => ({
      hideMessage,
      setHideMessage,
    }),
    [hideMessage],
  )

  return <messageContext.Provider value={value}>{children}</messageContext.Provider>
}

function useMessageContext() {
  const context = useContext(messageContext)

  if (!context) {
    throw new Error('useChatContext must be used inside the ChatContextProvider')
  }

  return context
}

export default MessageContextProvider
export { useMessageContext }
