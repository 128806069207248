import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { Message, User } from '@twilio/conversations'
import { Reply } from '../types'
import { getAuthorNameFromUser } from '../hooks/use-get-user-from-message'
import getTimeFromMessage from '../hooks/get-time-from-message'
import { SYSTEM_AUTHOR_NAME } from 'costants'

type SetReply = { user: User; msg: Message; body: string | undefined } | undefined

interface IChatContext {
  messageForDrawer?: Message
  setMessageForDrawer: React.Dispatch<React.SetStateAction<Message | undefined>>
  reply?: Reply
  setReply: (SetReply) => void
  inputRef: React.RefObject<HTMLInputElement>
  chatRef: React.RefObject<HTMLInputElement>
  openKeyboard: () => void
}

const chatContext = createContext<IChatContext | undefined>(undefined)

function ChatContextProvider({ children }) {
  const chatRef = useRef<HTMLInputElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [reply, setReply] = useState<Reply | undefined>()
  const [messageForDrawer, setMessageForDrawer] = useState<Message | undefined>()

  const openKeyboard = useCallback(() => {
    // Open Keyboard: https://forum.ionicframework.com/t/key-board-cannot-set-focus-when-startup-the-app/177805/5
    setTimeout(() => {
      inputRef.current?.click()
      inputRef.current?.focus()
    }, 250)
  }, [])

  const handleOnSetReply = useCallback((arg: SetReply) => {
    if (!arg) {
      setReply(undefined)
      return
    }

    const { msg, user, body = null } = arg
    const sid = msg.sid

    const time = getTimeFromMessage(msg)

    const author = msg.author === 'system' ? SYSTEM_AUTHOR_NAME : getAuthorNameFromUser(user)
    const authorId = msg.author === 'system' ? SYSTEM_AUTHOR_NAME : user?.identity

    setReply({ author, authorId, body, time, sid })
  }, [])

  const value = useMemo(
    () => ({
      messageForDrawer,
      setMessageForDrawer,
      reply,
      setReply: handleOnSetReply,
      inputRef,
      chatRef,
      openKeyboard,
    }),
    [handleOnSetReply, messageForDrawer, openKeyboard, reply],
  )

  return <chatContext.Provider value={value}>{children}</chatContext.Provider>
}

function useChatContext() {
  const context = useContext(chatContext)

  if (!context) {
    throw new Error('useChatContext must be used inside the ChatContextProvider')
  }

  return context
}

export default ChatContextProvider
export { useChatContext }
