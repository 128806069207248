function dataURLtoFile(dataurl: String) {
  const arr = dataurl.split(',')
  const mime = (arr[0].match(/:(.*?);/) as string[])[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], 'image', { type: mime })
}

export default dataURLtoFile
