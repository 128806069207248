import { Box, Divider, Flex, Text, useDisclosure, useToast } from '@chakra-ui/react'
import CompleteProfileModal from 'components/shared/complete-profile-modal/complete-profile-modal'
import { Alert, Confirm } from 'components/shared/confirm/confirm'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { selectorAuth } from 'features/auth/auth.slice'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Api from 'services/api/api.service'
import { Attendance, Event } from 'typings/database'
import { genderMap } from '../../costants/genders'
import EventButtons from './event-buttons'
import EventCost from './event-cost'
import EventCover from './event-cover'
import EventDate from './event-date'
import EventLocation from './event-location'
import EventParticipants from './event-participants'
import EventParticipantsInfo from './event-participants-info'
import EventScreenHeader from './event-screen-header'

const EventScreen = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()

  const { user } = useSelector(selectorAuth)

  const { isLoading, error, data, refetch } = useQuery<
    any,
    any,
    {
      retry: false
      error: String
      isLoading: boolean
      data: Event
      refetch: () => {}
    }
  >('getEvent' + id, () => Api.event(id as string), {})

  const event = data?.data as Event

  useEffect(() => {
    if (error && typeof error == 'string') {
      toast({
        title: error,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    } else if (error && typeof error?.message == 'string') {
      toast({
        title: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    }
  }, [error, toast])

  const { isOpen: isCompleteProfileOpen, onOpen: onCompleteProfileOpen, onClose: onCompleteProfileClose } = useDisclosure()

  const createAttendanceMutation = useMutation({
    mutationFn: async () => {
      try {
        const createAttendancePromise = Api.createAttendance(id as string)
        return createAttendancePromise
      } catch (e: any) {
        return toast({
          title: e?.response?.data?.message || 'Error',
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
    },
    onSuccess: () => {
      refetch()
      navigate(`/event/${event.chatSid}/chat`)
    },
  })

  const createAttendance = async () => {
    if (!user) {
      await Alert({ title: 'Non registrato', message: `Effettua l'accesso o registrati per partecipare all'evento`, confirmText: 'Continua' })
      navigate('/login')
      return
    }
    if (!user?.avatar) {
      onCompleteProfileOpen()
      return
    }
    if (event.limitations.genderBalanceActive && event.limitations[user.gender + 'Participants'] - event.genderBalanceCounts[user.gender] <= 0) {
      const gender = genderMap.find(g => g[0] === user.gender) || ['', 'Sesso non specificato']
      await Alert({ title: 'Posti finiti', message: `I posti per ${gender[1].toLowerCase()} sono finiti`, confirmText: 'ok' })
      return
    }
    if (
      !(await Confirm({
        title: 'Conferma',
        message: `Conferma la tua partecipazione. In caso non potrai più partecipare, annullala per evitare ammonizioni.`,
      }))
    ) {
      return
    }

    createAttendanceMutation.mutate()
  }

  if (isLoading || !event.title) return null

  const { attendances } = event
  const attendance = attendances && attendances.find((e: Attendance) => e.user._id === user._id)
  const isApproved = !!attendance?.approvedAt
  const isOrganizer = user && event.organizer._id === user._id

  return (
    <>
      <Wrapper height="100%" overflowX="hidden">
        <EventScreenHeader
          isApproved={isApproved}
          event={event}
          isOrganizer={isOrganizer}
          attendance={attendance}
          navigate={navigate}
          id={id}
          refetch={refetch}
        />

        <EventCover event={event} attendance={attendance} navigate={navigate} createAttendance={createAttendance} isOrganizer={isOrganizer} />

        <Flex direction="column" gap="2rem">
          <Flex direction="column" gap="1rem">
            <EventLocation isOrganizer={isOrganizer} attendance={attendance} event={event} />
            <EventDate event={event} attendance={attendance} />
            {!!event.cost && <EventCost event={event} navigate={navigate} />}
          </Flex>

          {event.description && (
            <Text as="i" fontFamily="Poppins" fontWeight="400" fontSize="15px" textColor="rgb(70, 83, 98)">
              {event.description}
            </Text>
          )}

          <Divider borderColor="#465362" />

          <EventParticipantsInfo isOrganizer={isOrganizer} attendance={attendance} event={event} />

          <EventParticipants event={event} navigate={navigate} isOrganizer={isOrganizer} attendance={attendance} />

          <Box width="100%" height="2rem" />
          <EventButtons
            user={user}
            isOrganizer={isOrganizer}
            attendance={attendance}
            event={event}
            toast={toast}
            refetch={refetch}
            navigate={navigate}
            id={id}
            isApproved={isApproved}
            createAttendance={createAttendance}
          />
        </Flex>
      </Wrapper>

      <CompleteProfileModal isOpen={isCompleteProfileOpen} onClose={onCompleteProfileClose} />
    </>
  )
}

export default EventScreen
