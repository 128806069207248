import { Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { ReactComponent as WarningIcon } from '@assets/warning-icon.svg'

const HelpButton = () => {
  const openHelpPage = () => {
    window.open('https://sbam.social/supporto', '_blank')
  }

  return (
    <Flex flexDir="row" alignItems="center" gap="0.7rem" onClick={openHelpPage} _hover={{ color: 'brand.500' }}>
      <Icon as={WarningIcon} boxSize="1.2rem" />
      <Text fontWeight="500" textColor="#011936" fontSize="1rem">
        Chiedi assistenza
      </Text>
    </Flex>
  )
}

export default HelpButton
