import { Avatar, Box, CloseButton, Flex, HStack, InputGroup, Spacer, Text, useToast } from '@chakra-ui/react'
import { Confirm } from 'components/shared/confirm/confirm'
import { I, ILE } from 'components/shared/input/input.style'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { selectorAuth } from 'features/auth/auth.slice'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Api from 'services/api/api.service'

function FollowersScreen() {
  const navigate = useNavigate()
  const { user } = useSelector(selectorAuth)
  const toast = useToast()
  const { id } = useParams<{ id?: string }>()
  const { tab } = useParams<{ tab?: string }>()
  const profileId = id || user._id
  const isMe = profileId === user._id
  const page = tab ? tab : 'follower'
  const [name, setName] = useState('')

  const {
    isLoading: followersIsLoading,
    error: followersError,
    data: followersData,
    refetch: followersRefetch,
  } = useQuery('followers', isMe ? () => Api.currentFollowings() : () => Api.getUserFollowings(profileId)) as {
    error: String | any
    isLoading: boolean
    data: any
    refetch: Function
  }

  const error = followersError
  const isLoading = followersIsLoading

  useEffect(() => {
    if (error && typeof error == 'string') {
      toast({
        title: error,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    } else if (error && typeof error?.message == 'string') {
      toast({
        title: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    }
  }, [error, toast])

  const onChangePage = page => {
    if (isMe) {
      navigate(`/profile/followers/${page}`)
    } else {
      navigate(`/profile/${profileId}/followers/${page}`)
    }
  }

  const closeScreen = () => {
    if (isMe) {
      navigate(`/profile`)
    } else {
      navigate(`/profile/${profileId}`)
    }
  }

  return (
    <Wrapper pt="20px" pb="90px" overflow-y="auto" overflow-x="hidden">
      <Flex mb="40px">
        <Text
          cursor="pointer"
          fontSize="20px"
          fontWeight="semibold"
          textColor={page === 'follower' ? 'black' : '#707070'}
          onClick={() => {
            onChangePage('follower')
          }}>
          Follower
        </Text>
        <Spacer />
        <Text
          cursor="pointer"
          fontSize="20px"
          fontWeight="semibold"
          textColor={page === 'seguiti' ? 'black' : '#707070'}
          onClick={() => {
            onChangePage('seguiti')
          }}>
          Seguiti
        </Text>
        <Spacer />
        <Spacer />
        <CloseButton size="22px" onClick={closeScreen} />
      </Flex>

      {((page === 'follower' && followersData?.data?.followers?.length >= 10) ||
        (page === 'seguiti' && followersData?.data?.followings?.length >= 10)) && (
        <InputGroup size="sm" height="40px" mb="40px">
          <ILE
            ml="2"
            mr="2"
            children={
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
                <g id="Raggruppa_182" data-name="Raggruppa 182" transform="translate(-163 -205)">
                  <rect
                    id="Rettangolo_160"
                    data-name="Rettangolo 160"
                    width="29"
                    height="29"
                    transform="translate(163 205)"
                    fill="#fff"
                    opacity="0"
                  />
                  <path
                    id="Icon_awesome-search"
                    data-name="Icon awesome-search"
                    d="M24.66,21.618l-4.869-4.869a1.171,1.171,0,0,0-.83-.342h-.8a10.152,10.152,0,1,0-1.758,1.758v.8a1.171,1.171,0,0,0,.342.83l4.869,4.869a1.167,1.167,0,0,0,1.655,0l1.382-1.382a1.177,1.177,0,0,0,0-1.66Zm-14.5-5.21a6.25,6.25,0,1,1,6.25-6.25A6.247,6.247,0,0,1,10.157,16.407Z"
                    transform="translate(165 206.998)"
                    fill="#ff7436"
                  />
                </g>
              </svg>
            }
          />
          <I
            placeholder="Cerca"
            variant="filled"
            pl="45px !important"
            ml="2"
            mr="2"
            onChange={e => {
              setName(e.target.value)
            }}
          />
        </InputGroup>
      )}

      <Flex direction="column" ml="2" mr="2" overflowY="auto" overflowX="visible">
        {isLoading && <Text>Caricamento in corso...</Text>}
        {page === 'follower' &&
          !error &&
          !isLoading &&
          followersData?.data?.followers
            .filter(e => e?.user?.name?.match(new RegExp(name, 'i')) || name === '')
            .map((e, i) => {
              const handleOnFollowerRemoval = async () => {
                try {
                  if (
                    !(await Confirm({
                      message: `Sei sicuro di voler rimuovere l'utente '${e?.user?.name}' dai tuoi follower?`,
                      title: 'Confermi?',
                      confirmText: 'Sì',
                      declineText: 'No',
                    }))
                  ) {
                    return
                  }
                  await Api.deleteFollowing(e?._id)
                  followersRefetch()
                  toast({
                    title: `Hai rimosso l'utente '${e?.user?.name}' dai tuoi follower`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'bottom',
                  })
                } catch (e: any) {
                  toast({
                    title: e.response.data.message || 'Error',
                    status: 'error',
                    duration: 2500,
                    isClosable: true,
                    position: 'bottom',
                  })
                }
              }

              const handleOnOpenProfile = () => {
                navigate(`/profile/${e?.user?._id}`)
              }

              return (
                <Box
                  pb="5px"
                  pt="5px"
                  borderBottomStyle="solid"
                  borderBottomWidth="1px"
                  borderBottomColor="#E7E7E7"
                  position="relative"
                  mb="15px"
                  key={i}>
                  <HStack spacing="5px">
                    <Avatar
                      src={e?.user?.avatar}
                      size="sm"
                      onClick={handleOnOpenProfile}
                      _hover={{ borderWidth: '3px', borderColor: 'brand.500', borderStyle: 'solid' }}
                      cursor="pointer"
                    />
                    <Text fontSize="14px" fontWeight="medium" onClick={handleOnOpenProfile} _hover={{ textDecoration: 'underline' }} cursor="pointer">
                      {e?.user?.name}
                    </Text>
                  </HStack>
                  {isMe && (
                    <Text
                      textColor="brand.500"
                      textDecoration="underline"
                      position="absolute"
                      right="5px"
                      top="10px"
                      onClick={handleOnFollowerRemoval}
                      cursor="pointer">
                      Rimuovi
                    </Text>
                  )}
                </Box>
              )
            })}

        {page === 'follower' && !error && !isLoading && followersData?.data?.followers?.length === 0 && (
          <Text>{isMe ? 'Non hai alcun follower' : "L'utente non ha alcun follower"}</Text>
        )}

        {page === 'seguiti' &&
          !error &&
          !isLoading &&
          followersData?.data?.followings
            .filter(e => e?.userFollowed?.name?.match(new RegExp(name, 'i')) || name === '')
            .map((e, i) => {
              const handleOnFollowingRemoval = async () => {
                try {
                  if (
                    !(await Confirm({
                      message: `Sei sicuro di voler rimuovere l'utente '${e?.userFollowed?.name}?' dai tuoi seguìti?`,
                      title: 'Confermi?',
                      confirmText: 'Sì',
                      declineText: 'No',
                    }))
                  ) {
                    return
                  }
                  await Api.deleteFollowing(e?._id)
                  followersRefetch()
                  toast({
                    title: `Hai rimosso '${e?.userFollowed?.name}' dai tuoi seguìti`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'bottom',
                  })
                } catch (e: any) {
                  toast({
                    title: e.response.data.message || 'Error',
                    status: 'error',
                    duration: 2500,
                    isClosable: true,
                    position: 'bottom',
                  })
                }
              }

              const handleOnOpenProfile = () => navigate(`/profile/${e?.userFollowed?._id}`)

              return (
                <Box
                  pb="5px"
                  pt="5px"
                  borderBottomStyle="solid"
                  borderBottomWidth="1px"
                  borderBottomColor="#E7E7E7"
                  position="relative"
                  mb="15px"
                  key={i}>
                  <HStack spacing="5px">
                    <Avatar
                      src={e?.userFollowed?.avatar}
                      size="sm"
                      onClick={handleOnOpenProfile}
                      _hover={{ borderWidth: '3px', borderColor: 'brand.500', borderStyle: 'solid' }}
                      cursor="pointer"
                    />
                    <Text fontSize="14px" fontWeight="medium" onClick={handleOnOpenProfile} _hover={{ textDecoration: 'underline' }} cursor="pointer">
                      {e?.userFollowed?.name}
                    </Text>
                  </HStack>
                  {isMe && (
                    <Text
                      textColor="brand.500"
                      textDecoration="underline"
                      position="absolute"
                      right="5px"
                      top="10px"
                      onClick={handleOnFollowingRemoval}
                      cursor="pointer">
                      Non seguire
                    </Text>
                  )}
                </Box>
              )
            })}

        {page === 'seguiti' && !error && !isLoading && followersData?.data?.followings?.length === 0 && (
          <Text>{isMe ? 'Non segui alcun utente' : "L'utente non segue nessuno"}</Text>
        )}
      </Flex>
    </Wrapper>
  )
}

export default FollowersScreen
