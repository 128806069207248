import { useToast } from '@chakra-ui/react'
import { MessageType, Reply } from '@screens/chat/types'
import { JSONValue } from '@twilio/conversations'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import useGetConversationQueryBySid from './use-get-conversation-query'

const useSendPositionMessageMutation = (options = {}): any => {
  const { sid } = useParams()

  const { data } = useGetConversationQueryBySid(sid)
  const conversation = data?.conversation

  const toast = useToast()

  return useMutation(
    'send-position-message',
    async ({ coords, reply }: { coords: [number, number]; reply?: Reply }) => {
      if (!coords) {
        throw Error('Errore nel recuperare le coordinate')
      }

      const attributes = reply ? ({ type: MessageType.Location, coords, reply } as JSONValue) : ({ type: MessageType.Location, coords } as JSONValue)

      await conversation?.self?.prepareMessage().setBody('').setAttributes(attributes).build().send()
    },
    {
      onError(error: any) {
        console.error(error)

        toast({
          title: error?.message || 'Qualcosa è andato storto',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        })
      },
      ...options,
    },
  )
}

export default useSendPositionMessageMutation
