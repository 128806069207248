import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ModalCloseButton,
} from '@chakra-ui/react'
import { refreshUser } from 'features/auth/auth.actions'
import { queryClient } from 'query-client'
import React from 'react'
import Api from 'services/api/api.service'

const AttendanceDeletionDialog = props => {
  const confirmDeleteAttendance = async () => {
    if (props.attendance) {
      await Api.deleteAttendance(props.event._id, props.attendance._id, props.attendance.code)
      await queryClient.invalidateQueries('conversations')
      props.setDeleteAttendanceOpen(false)
      props.refetch()
      refreshUser()
    }
  }

  return (
    <AlertDialog
      isCentered
      isOpen={props.isDeleteAttendanceOpen}
      leastDestructiveRef={props.cancelDeleteAttendanceRef}
      onClose={props.onDeleteAttendanceClose}>
      <AlertDialogOverlay bg="blackAlpha.400" backdropFilter="blur(3px)">
        <AlertDialogContent mr="10px" ml="10px">
          <AlertDialogHeader fontWeight="bold">Annulla partecipazione</AlertDialogHeader>
          <ModalCloseButton size="lg" />
          <AlertDialogBody>Non verranno rimborsati gli sbam coin spesi. Sei sicuro di voler annullare la tua partecipazione?</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant="ghost"
              width="20%"
              fontSize="18px"
              fontWeight="600"
              fontFamily="Poppins"
              textAlign="center"
              pr="50px"
              pl="50px"
              bg="white"
              color="black"
              ref={props.cancelDeleteAttendanceRef}
              onClick={props.onDeleteAttendanceClose}
              mr="2">
              Indietro
            </Button>
            <Button fontSize="18px" width="80%" fontWeight="600" fontFamily="Poppins" textAlign="center" onClick={confirmDeleteAttendance}>
              Annulla partecipazione
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default AttendanceDeletionDialog
