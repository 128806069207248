import { selectorAuth } from 'features/auth/auth.slice'
import { useSelector } from 'react-redux'
import { Warning, WarningTypeModel } from 'typings/database'

type UserStatus = WarningTypeModel.Admonition | WarningTypeModel.Ban

type UseCurrentUserStatusResult = {
  currentUserStatus?: UserStatus
  activeWarning?: Warning
}

export default function useCurrentUserStatus(): UseCurrentUserStatusResult {
  const { user } = useSelector(selectorAuth)

  if (!user) return {}

  const activeWarning = (user.warnings as Warning[] | undefined)?.find(w => new Date(w.expiresAt).getTime() > Date.now())

  return { currentUserStatus: activeWarning ? activeWarning.type : undefined, activeWarning }
}
