import { useToast } from '@chakra-ui/react'
import useGetCurrentCommunities from '@hooks/use-get-current-communities'
import { useFormbitContext } from '@radicalbit/formbit'
import { useChatContext } from '@screens/chat/context/chat-context'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import useSendTextMessageMutation from '../../hooks/use-send-text-message-mutation'
import { Schema } from './schema'

const useHandleOnSubmit = setIsModalOpen => {
  const toast = useToast()
  const { sid = '' } = useParams()
  const { setReply, chatRef } = useChatContext()

  const { submitForm, isDirty, isFormInvalid } = useFormbitContext<Schema>()

  const { data, isLoading: isLoadingCommunities } = useGetCurrentCommunities()

  const sendPoll = useSendTextMessageMutation()

  const isSubmitDisabled = !isDirty || isFormInvalid() || isLoadingCommunities

  const handleOnSubmit = useCallback(() => {
    submitForm(async ({ form }) => {
      if (isSubmitDisabled) {
        return
      }

      const communities = data?.data || []

      try {
        sendPoll.mutate({ sid, message: form.message })

        // Those chats will receive the msg eventually
        communities
          .filter(({ chatSid }) => chatSid !== sid)
          .forEach(({ chatSid }) => {
            sendPoll.mutate({ sid: chatSid, message: form.message })
          })

        setIsModalOpen(false)
        setReply(undefined)

        if (chatRef.current) {
          chatRef.current.scrollTop = chatRef.current?.scrollHeight
        }
      } catch (e) {
        toast({
          title: 'Qualcosa è andato storto',
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
    })
  }, [chatRef, data?.data, isSubmitDisabled, sendPoll, setIsModalOpen, setReply, sid, submitForm, toast])

  return { handleOnSubmit, args: sendPoll, isSubmitDisabled }
}

export default useHandleOnSubmit
