import { Box, Flex, FormControl, Text } from '@chakra-ui/react'
import InputField from '@components/shared/input/input.component'
import React from 'react'

function PasswordFields(props: { errors: any; register: any; password: any }) {
  return (
    <Box>
      <Text textColor="#465362" fontWeight="600" fontSize="0.9rem">
        Imposta la password{' '}
        <Text color="#FF7436" display="inline">
          *
        </Text>
      </Text>
      <Flex flexDir="row" gap="1rem">
        <FormControl isInvalid={!!props.errors.password}>
          <InputField
            type="password"
            placeholder="+5 caratteri"
            {...props.register('password', {
              required: 'Devi specificare una password',
              validate: value => !/\s/g.test(value) || 'La password non può contenere alcuno spazio',
              minLength: { value: 6, message: 'La password deve avere almeno 6 caratteri' },
            })}
          />
        </FormControl>
        <FormControl isInvalid={!!props.errors.confirmPassword}>
          <InputField
            type="password"
            placeholder="Ripetila qui"
            {...props.register('confirmPassword', {
              required: 'Devi confermare la password',
              validate: value => value === props.password || 'Le password non coincidono',
            })}
          />
        </FormControl>
      </Flex>
    </Box>
  )
}

export default PasswordFields
