import React from 'react'
import { useParams } from 'react-router-dom'
import useGetConversationQueryBySid from '../hooks/use-get-conversation-query'
import Header from './header'
import HeaderOneToOne from './header-one-to-one'

function ChatHeader() {
  const { sid } = useParams()

  const { data, isSuccess } = useGetConversationQueryBySid(sid)
  const type = data?.conversation?.attributes?.type

  if (!isSuccess) {
    return <></>
  }

  switch (type) {
    case 'userChat':
      return <HeaderOneToOne />

    default:
      return <Header />
  }
}

export default ChatHeader
