import { Client as ConversationsClient } from '@twilio/conversations'

export const getClient: (chatToken: string) => Promise<ConversationsClient> = chatToken =>
  new Promise((resolve, reject) => {
    const client = new ConversationsClient(chatToken, { region: 'ie1' })

    client.on('initialized', () => {
      resolve(client)
    })

    client.on('initFailed', error => {
      console.error('conversations failed to initialize: ', error.error?.message)
      reject(error)
    })
  })
