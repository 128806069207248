import { Box, Center, BoxProps, Wrap, WrapItem, Grid, GridItem } from '@chakra-ui/react'
import React, { Ref } from 'react'

interface ChipData {
  label: string
  value: number
}

const ChipSelect = ({
  value,
  data,
  onChange,
  ...props
}: Omit<BoxProps, 'onChange'> & { value: ChipData['value'] | null; data: ChipData[]; onChange: (value: ChipData['value'] | null) => void }) => {
  return (
    <Box {...props}>
      <Grid templateColumns="repeat(3, 1fr)" gap="10px">
        {data.map(d => (
          <GridItem key={d.value}>
            <Box
              cursor="pointer"
              onClick={() => (d.value === value ? onChange(null) : onChange(d.value))}
              w="100%"
              h="36px"
              fontSize="14px"
              borderRadius="15px"
              color={d.value === value ? 'white' : '#465362'}
              fontWeight="600"
              backgroundColor={d.value === value ? '#FF7436' : 'transparent'}
              border={`2px ${d.value === value ? '#FF7436' : '#F7F7F7'} solid`}>
              <Center h="100%">{d.label}</Center>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </Box>
  )
}

export default ChipSelect
