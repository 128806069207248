import { useCallback, useState } from 'react'

const useArray = initialValue => {
  const [value, setValue] = useState(initialValue)
  return {
    value,
    setValue,
    add: useCallback(a => setValue(v => (v.indexOf(a) >= 0 ? a : [...v, a]))),
    clear: useCallback(() => setValue(() => [])),
    remove: useCallback(id => setValue(arr => arr.filter(v => v !== id))),
    removeById: useCallback(id => setValue(arr => arr.filter(v => v && v.id !== id))),
    removeIndex: useCallback(index =>
      setValue(v => {
        v.splice(index, 1)
        return v
      }),
    ),
  }
}

export default useArray
