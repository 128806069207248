import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const CompleteProfileModal = (props: { isOpen; onClose }) => {
  const navigate = useNavigate()

  const handleOnClick = () => {
    navigate('/settings')
    props.onClose()
  }

  return (
    <Modal isCentered closeOnOverlayClick={false} isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay bg="blackAlpha.400" backdropFilter="blur(3px)" />
      <ModalContent>
        <ModalHeader fontWeight="bold">Profilo non completato</ModalHeader>
        <ModalCloseButton size="lg" />
        <ModalBody>
          <Text>Imposta una foto del profilo per poter organizzare eventi e partecipare ad essi</Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={props.onClose}>
            Annulla
          </Button>
          <Button onClick={handleOnClick}>Completa profilo</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CompleteProfileModal
