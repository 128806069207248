import React, { useState } from 'react'

import {
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import Api from 'services/api/api.service'
import QRReader from '../qr-reader/qr-reader'
import { ReactComponent as BoltsIcon } from './../../../assets/bolts.svg'

const ScanQRCodeComponent = (props: { eventId: string; onApprove: Function }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)
  const handleQR = async (payload: string) => {
    try {
      setLoading(true)
      const { attendanceId, attendanceCode } = JSON.parse(payload)
      await Api.approveAttendance(props.eventId, attendanceId, attendanceCode)
      onClose()
      props.onApprove()
      setLoading(false)
    } catch (e) {
      alert('Codice QR non valido: ' + ((e as AxiosError).response?.data as any).message)
      setLoading(false)
    }
  }

  return (
    <>
      <Button variant="solid" rightIcon={<BoltsIcon />} onClick={onOpen}>
        <Text fontSize="18px" fontWeight="600" fontFamily="Poppins">
          Conferma partecipanti +1
        </Text>
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.400" backdropFilter="blur(3px)" />
        <ModalContent mr="10px" ml="10px">
          <ModalHeader fontWeight="bold">Scansiona QR partecipanti</ModalHeader>
          <ModalCloseButton size="lg" />
          <ModalBody pb="40px">
            <Flex direction="column" gap=".5rem">
              <Center>
                {!loading && <QRReader onQrFound={handleQR} />}
                {loading && <Text>Invio partecipazione</Text>}
              </Center>

              <Text textAlign="left" fontSize="15px" mb="2">
                Conferma le presenze dei partecipanti e ottieni saette bonus.
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ScanQRCodeComponent
