import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import useGetUserByIdentityFromPartecipants from 'screens/chat/hooks/use-get-user-by-identity-from-partecipants'
import MessageAvatar, { UserAttribute } from 'screens/chat/message-avatar'
import { getTimeFromTimestamp } from '../../hooks/get-time-from-message'
import { Poll, PollOption, Vote } from '../../hooks/use-send-poll-mutation'

function ShowVotesModal({ poll, isOpen, onClose }: { poll: Poll; isOpen: boolean; onClose: () => void }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody pb="40px">
          <Flex direction="column" gap="2rem" padding="1rem">
            <Question question={poll.question} />

            {poll.options.map(o => {
              return (
                <Flex key={o.value} className="option" direction="column" gap=".5rem">
                  <OptionHeader option={o} />

                  <Flex className="option__list-of-voters" direction="column" gap=".75rem" justifyContent="space-between">
                    <Voters votes={o.votes} />
                  </Flex>
                </Flex>
              )
            })}
          </Flex>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}

function Question({ question }: { question: string }) {
  return (
    <Flex className="question" direction="column" gap=".5rem">
      <Box fontWeight="500">Voti sondaggio</Box>
      <Box>{question}</Box>
    </Flex>
  )
}

function OptionHeader({ option }: { option: PollOption }) {
  const votesCount = option.votes.length === 1 ? option.votes.length + ' voto' : option.votes.length + ' voti'

  return (
    <Flex className="option__header" direction="row" justifyContent="space-between" fontWeight="500">
      <Popover>
        <PopoverTrigger>
          <Box whiteSpace="nowrap" overflow="hidden" width="75%" textOverflow="ellipsis">
            {option.value}
          </Box>
        </PopoverTrigger>

        <PopoverContent>
          <PopoverBody>{option.value}</PopoverBody>
        </PopoverContent>
      </Popover>

      <Box>{votesCount}</Box>
    </Flex>
  )
}
function Voters({ votes }: { votes: Vote[] }) {
  if (!votes.length) {
    return (
      <Box as="small" fontStyle="italic">
        Nessun voto
      </Box>
    )
  }

  return (
    <>
      {votes.map(v => (
        <Voter vote={v} />
      ))}
    </>
  )
}

function Voter({ vote }: { vote: Vote }) {
  const identity = vote.identity
  const timestamp = vote.timestamp

  const { data, isSuccess } = useGetUserByIdentityFromPartecipants(identity)
  const name = data && data.attributes ? (data?.attributes as UserAttribute).name : undefined

  if (!isSuccess) {
    return <></>
  }

  if (name) {
    return (
      <Link to={`/profile/${identity}`}>
        <Flex direction="row" width="100%" alignItems="center" justifyContent="flex-start" gap=".5rem">
          <MessageAvatar user={data} width={35} height={35} />

          <Flex direction="column" justifyContent="center" alignItems="flex-start">
            <Box fontWeight="600">{name}</Box>
            <Box as="small">{getTimeFromTimestamp(timestamp)}</Box>
          </Flex>
        </Flex>
      </Link>
    )
  }

  return (
    <Flex direction="row" width="100%" alignItems="center" justifyContent="flex-start" gap=".5rem">
      <MessageAvatar user={data} width={35} height={35} />

      <Flex direction="column" justifyContent="center" alignItems="flex-start">
        <Box fontStyle="italic">Utente rimosso</Box>
        <Box as="small">{getTimeFromTimestamp(timestamp)}</Box>
      </Flex>
    </Flex>
  )
}

export default ShowVotesModal
