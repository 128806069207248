import { Box, Button, Flex, Spacer, Text, useDisclosure } from '@chakra-ui/react'
import ScanQRCode from 'components/shared/scan-qr-code/scan-qr-code'
import moment from 'moment'
import React from 'react'
import { ReactComponent as BoltsIcon } from './../../assets/bolts.svg'
import EventConfirmAttendance from './event-confirm-attendance'
import { useSelector } from 'react-redux'
import { selectorAuth } from 'features/auth/auth.slice'
import { useParams } from 'react-router'

const EventButtons = props => {
  const { isOpen: isQROpen, onOpen: onQROpen, onClose: onQRClose } = useDisclosure()

  const { user } = useSelector(selectorAuth)
  const { id } = useParams<{ id: string }>()

  const goToEventRating = () => {
    if (!user) return

    window.open(`https://tally.so/r/mYr85q?email=${user.email}&eventId=${id}`, '_blank')
  }

  return (
    <Box zIndex="100">
      <EventConfirmAttendance isQROpen={isQROpen} onQRClose={onQRClose} attendance={props.attendance} />

      <Flex direction="row" position="fixed" left="0" bottom="80px" marginBottom="env(safe-area-inset-bottom)" width="100%" mx="auto" zIndex="20">
        <Spacer />

        {props.isOrganizer && moment(props.event.endDate).isAfter() && (
          <ScanQRCode
            eventId={props.event._id}
            onApprove={() => {
              props.toast({
                title: 'Partecipazione confermata',
                status: 'success',
                duration: 2500,
                isClosable: true,
                position: 'top',
              })
              props.refetch()
            }}
          />
        )}

        {!props.isOrganizer && !props.attendance && moment(props.event.endDate).isAfter() && (
          <Button
            width="fit-content"
            height="50px"
            variant="solid"
            px="20px"
            rightIcon={props.user !== null ? <BoltsIcon /> : <></>}
            onClick={props.createAttendance}>
            <Text fontSize="18px" fontWeight="600" fontFamily="Poppins" textAlign="center">
              Partecipa {props.user !== null && `-${props.event.attendanceCost}`}
            </Text>
          </Button>
        )}

        {!props.isOrganizer && props.attendance && !props.isApproved && moment(props.event.endDate).isAfter() && (
          <Button width="fit-content" height="50px" variant="solid" rightIcon={<BoltsIcon />} onClick={onQROpen} px="20px">
            <Text fontSize="18px" fontWeight="600" fontFamily="Poppins" textAlign="center">
              Conferma presenza +2
            </Text>
          </Button>
        )}

        {props.attendance && props.isApproved && moment(props.event.endDate).isBefore() && (
          <Button width="fit-content" height="50px" variant="solid" onClick={goToEventRating} px="20px">
            <Text fontSize="18px" fontWeight="600" fontFamily="Poppins" textAlign="center">
              Valuta evento
            </Text>
          </Button>
        )}
        <Spacer />
      </Flex>
    </Box>
  )
}

export default EventButtons
