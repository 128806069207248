import React, { useEffect } from 'react'
import Api from 'services/api/api.service'
import { Box, useToast, Grid, GridItem, Text, Center } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { Category } from 'typings/database'

const CategoryInput = ({ value, onChange, disabled }: any) => {
  const toast = useToast()
  const {
    isLoading: isLoadingCategories,
    error,
    data: categories,
  } = useQuery('categories', () => Api.categories(), {
    retry: false,
  }) as {
    error: String | any
    isLoading: boolean
    data: { data: Category[] }
  }

  useEffect(() => {
    if (error && typeof error == 'string') {
      toast({
        title: error,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    } else if (error && typeof error?.message == 'string') {
      toast({
        title: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    }
  }, [error, toast])

  if (isLoadingCategories || !categories?.data) return null

  const categoriesByType: { [key: string]: Category[] } = {};
  categories.data.forEach((category) => {
    const typeName = category.categoryType.name;
    if (!categoriesByType[typeName]) {
      categoriesByType[typeName] = [];
    }
    categoriesByType[typeName].push(category);
  });

  const handleClick = (categoryId: string) => {
    if (disabled) return

    const newValue = value?.indexOf(categoryId) > -1
      ? value?.filter((id: string) => id !== categoryId)
      : [...(value || []), categoryId]

    onChange(newValue)
  }

  return (
    <div>
      {Object.keys(categoriesByType).map((typeName) => (
        <Box key={typeName}>
          <Text fontWeight="bold" mb={3}>{typeName}</Text>
          <Grid templateColumns="repeat(3, 1fr)" gap="10px" mb={3}>
            {categoriesByType[typeName].map((c) => (
              <GridItem key={c._id}>
                <Box
                  cursor={!disabled ? 'pointer' : 'not-allowed'}
                  onClick={() => handleClick(c._id)}
                  w="100%"
                  h="36px"
                  fontSize="14px"
                  borderRadius="15px"
                  color={value?.indexOf(c._id) > -1 ? 'white' : '#465362'}
                  fontWeight="600"
                  backgroundColor={value?.indexOf(c._id) > -1 ? '#FF7436' : 'transparent'}
                  border={`2px ${value?.indexOf(c._id) > -1 ? '#FF7436' : '#F7F7F7'} solid`}
                >
                  <Center h="100%">{c.name}</Center>
                </Box>
              </GridItem>
            ))}
          </Grid>
        </Box>
      ))}
    </div>
  )
}

export default CategoryInput
