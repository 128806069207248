import { CloseButton, Flex, Spacer, Text } from '@chakra-ui/react'
import { useAppDispatch } from '@src/features'
import { logoutAction, resetSavedSignupState } from '@src/features/auth/auth.actions'
import React from 'react'
import { useNavigate } from 'react-router'

const ScreenHeader = (props: { title: string; mx: string; doLogout: boolean }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const closeScreen = () => {
    if (props.doLogout) {
      dispatch<any>(resetSavedSignupState())
      dispatch<any>(logoutAction())
    }
    navigate('/')
  }

  return (
    <Flex flexDir="row" alignItems="center" mx={props.mx}>
      <Text textColor="#011936" fontWeight="600" fontSize="1.3rem">
        {props.title}
      </Text>
      <Spacer />
      <CloseButton size="lg" onClick={closeScreen} />
    </Flex>
  )
}

export default ScreenHeader
