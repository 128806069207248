import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { Button } from '@chakra-ui/react'
import { signInWithCredential } from 'firebase/auth'
import React from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'
import { getFirebaseAuth } from 'services/firebase.service'

const GoogleAccessButton = (props: {
  signInWebWith: any
  switchWebProvider: any
  handleSignError: any
  authProvider: string
  setAuthProvider: any
  isNative: any
}) => {
  const navigate = useNavigate()

  const switchProvider = (providerSlug: string) => {
    switch (providerSlug) {
      case 'google':
        return FirebaseAuthentication.signInWithGoogle
      case 'apple':
        return () => FirebaseAuthentication.signInWithApple({ scopes: ['name'] })
      default:
        throw new Error('Provider not supported')
    }
  }

  const signInWith = (providerSlug: string) => async () => {
    if (!props.isNative) return props.signInWebWith(providerSlug)()
    if (!!props.authProvider) return
    props.setAuthProvider(providerSlug)
    const signIn = switchProvider(providerSlug)

    const provider = props.switchWebProvider(providerSlug)

    try {
      const result = await signIn()
      if (result.credential) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const mobileCredential = result.credential
        const token = mobileCredential.idToken
        if (token) {
          const auth = getFirebaseAuth()

          const credential = provider.credential(token)
          const userCredential = await signInWithCredential(auth, credential)

          const { claims } = await userCredential.user.getIdTokenResult(true)
          if (claims.sbamRegistered) {
            navigate('/')
            return
          }
          navigate(`/signup?${createSearchParams({ email: userCredential.user.email + '', isSSO: 'true' })}`)
        }
      }
    } catch (error: any) {
      ;(window as any).a = error
      props.handleSignError(error)
    }
  }

  return (
    <Button
      isLoading={props.authProvider === 'google'}
      disabled={!!props.authProvider}
      onClick={signInWith('google')}
      color="black"
      width="90%"
      bgColor="white"
      _hover={{ color: 'black' }}>
      Accedi con Google
    </Button>
  )
}

export default GoogleAccessButton
