import unsplashService from '@src/services/unsplash.service'
import { useCallback, useState } from 'react'

export type UnsplashImage = { url: string; name: string; portfolioUrl: string }

export const useUnsplashImage = () => {
  const [unsplashPhoto, setUnsplashPhoto] = useState<UnsplashImage | null>(null)

  const getRandomImage = useCallback(async (queryString: string): Promise<string | undefined> => {
    try {
      const {
        urls: { regular: url },
        user: {
          name,
          links: { html: portfolioUrl },
        },
      } = await unsplashService.getRandomPhoto(queryString)
      setUnsplashPhoto({ url, name, portfolioUrl })
      return url
    } catch {
      setUnsplashPhoto(null)
    }
  }, [])

  return { unsplashPhoto, getRandomImage }
}
