import { Position } from 'typings/database'

export function calculateDistance(pos1: Position, pos2: Position) {
  let { lat: lat1, lon: lon1 } = pos1
  let { lat: lat2, lon: lon2 } = pos2
  const R: number = 6371 // km
  const dLat: number = toRad(lat2 - lat1) as any
  const dLon: number = toRad(lon2 - lon1) as any
  lat1 = toRad(lat1) as any
  lat2 = toRad(lat2) as any

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c
  if (d < 10) {
    return d.toFixed(2)
  }

  return d.toFixed(0)
}

// Converts numeric degrees to radians
function toRad(n: number) {
  return (n * Math.PI) / 180
}
