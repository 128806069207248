import React, { useState } from 'react'
import EmailAccessButton from './email-access-button'
import EmailAccessField from './email-access-field'

const EmailAccess = (props: { authProvider: string }) => {
  const [emailAccess, setEmailAccess] = useState(false)

  return emailAccess ? <EmailAccessField /> : <EmailAccessButton authProvider={props.authProvider} setEmailAccess={setEmailAccess} />
}

export default EmailAccess
