import { Button, Flex, Icon, Text, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { MdOutlineEuro } from 'react-icons/md'
import EventCostModal from './event-cost-modal'

const EventCost = props => {
  const { isOpen: isEventCostModalOpen, onOpen: onEventCostModalOpen, onClose: onEventCostModalClose } = useDisclosure()

  return (
    <>
      <Flex direction="row" alignItems="center" justifyContent="space-between">
        <Flex direction="row" alignItems="center">
          <Icon mr="2" as={MdOutlineEuro} boxSize="20px" />
          <Text fontFamily="Poppins" fontWeight="400" fontSize="15px" lineHeight="22.5px" mr="2">
            {props.event.cost.toFixed(2)} - pagamento fuori app
          </Text>
        </Flex>

        <Button
          onClick={onEventCostModalOpen}
          variant="outline"
          height="30px"
          fontFamily="Poppins"
          fontWeight="500"
          fontSize="15px"
          lineHeight="18px"
          textAlign="center"
          width="62px"
          pl="9"
          pr="9">
          Avviso
        </Button>
      </Flex>

      <EventCostModal
        isOpen={isEventCostModalOpen}
        onClose={onEventCostModalClose}
        navigate={props.navigate}
        organizerId={props.event.organizer._id}
      />
    </>
  )
}

export default EventCost
