import { Box, Button, Flex, Icon, Progress, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import signup1 from 'assets/signup-1.svg'
import signup2 from 'assets/signup-2.svg'
import signup3 from 'assets/signup-3.svg'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { URLSearchParamsInit } from 'react-router-dom'

function SignupScreenFormFooter(props: {
  step: number
  isLoading: boolean
  navigate: any
  createSearchParams: (init: URLSearchParamsInit) => URLSearchParams
  queryParams: URLSearchParams
}) {
  const handleBack = () => {
    let entries: URLSearchParamsInit = {}
    props.queryParams.forEach((value: string, key: string) => {
      entries[key] = value
    })
    entries['step'] = String(props.step - 1)
    props.navigate({
      search: `?${props.createSearchParams(entries)}`,
    })
  }

  return (
    <Flex
      position="relative"
      flexDir="column"
      width="100%"
      flexGrow="1"
      backgroundImage={props.step === 1 ? signup1 : props.step === 2 ? signup2 : signup3}
      backgroundRepeat="no-repeat"
      backgroundPosition={props.step === 1 ? 'bottom -0.7rem left -2rem' : props.step === 2 ? 'bottom -0.7rem left 0' : 'bottom 5rem right 1.2rem'}>
      {props.step === 1 ? <FirstTutorialText /> : props.step === 2 ? <SecondTutorialText /> : <ThirdTutorialText />}
      <Spacer />
      <Flex flexDir="column" gap="1.3rem">
        <Flex flexDir="row" alignItems="center" width="100%" px="1.25rem">
          {props.step > 1 && <Icon as={ChevronLeftIcon} boxSize="2.5rem" onClick={handleBack} />}
          <Spacer />
          <Button width="8.5625rem" height="3.125rem" isLoading={props.isLoading} type="submit">
            {props.step === 1 || props.step === 2 ? 'Avanti' : 'E... Sbam!'}
          </Button>
        </Flex>
        <Progress
          value={props.step === 1 ? 33.3333333333 : props.step === 2 ? 66.6666666666 : 100}
          width="100%"
          height="0.375rem"
          colorScheme="brand"
        />
      </Flex>
    </Flex>
  )
}

const FirstTutorialText = () => {
  return (
    <Box position="absolute" bottom="12rem" right="1.8rem" fontStyle="italic">
      <Text fontWeight="600" fontSize="1.35rem" textColor="#FF7436" position="relative" top="1.5rem" left="-0.2rem" display="inline">
        "
      </Text>
      <Text fontSize="0.9375rem" fontFamily="Poppins" textColor="#011936" width="15.25rem" height="2.875rem" position="relative" left="0.5rem">
        Conosci nuove persone con cui uscire, divertirti e fare amicizia.
      </Text>
      <Text fontWeight="600" fontSize="1.35rem" textColor="#FF7436" position="absolute" bottom="-1.5rem" right="0" display="inline">
        "
      </Text>
    </Box>
  )
}

const SecondTutorialText = () => {
  return (
    <Box position="absolute" bottom="12rem" right="1.8rem" fontStyle="italic">
      <Text fontWeight="600" fontSize="1.35rem" textColor="#FF7436" position="relative" top="1.5rem" left="-0.2rem" display="inline">
        "
      </Text>
      <Text fontSize="0.9375rem" fontFamily="Poppins" textColor="#011936" width="10.5rem" height="2.875rem" position="relative" left="0.5rem">
        Partecipa e organizza eventi di ogni tipo.
      </Text>
      <Text fontWeight="600" fontSize="1.35rem" textColor="#FF7436" position="absolute" bottom="-1.5rem" right="1rem" display="inline">
        "
      </Text>
    </Box>
  )
}

const ThirdTutorialText = () => {
  return (
    <Box position="absolute" bottom="10rem" right="1.8rem" fontStyle="italic" width="fit-content" height="fit-content">
      <Text fontWeight="600" fontSize="1.35rem" textColor="#FF7436" position="relative" top="1.5rem" left="-0.2rem" display="inline">
        "
      </Text>
      <Text fontSize="0.9375rem" fontFamily="Poppins" textColor="#011936" width="20.8125rem" height="4.3125rem" position="relative" left="0.5rem">
        Sbam! funziona grazie alle saette che puoi acquistare o ricevere come ricompense.
      </Text>
      <Text fontWeight="600" fontSize="1.35rem" textColor="#FF7436" position="absolute" bottom="-0.2rem" right="1rem" display="inline">
        "
      </Text>
    </Box>
  )
}

export default SignupScreenFormFooter
