import { Flex, Text } from '@chakra-ui/react'
import EventPreview from 'components/shared/event-preview/event-preview.component'
import React from 'react'
import { Link } from 'react-router-dom'

function EventMessage({ msg }) {
  const event = msg?.attributes?.event

  return (
    <Link to={'/event/' + event._id}>
      <Flex direction="column" gap="1rem">
        <Text>Nuovo evento in zona! Partecipa subito.</Text>

        <EventPreview {...event} />
      </Flex>
    </Link>
  )
}

export default EventMessage
