import React from 'react'
import { Avatar, Box } from '@chakra-ui/react'
import { User } from '@twilio/conversations'

// INFO: created printing to the Chrome console the user.attributes
export type UserAttribute = {
  name: string
  avatar: string
}

function MessageAvatar(props: { user?: User; width?: number; height?: number }) {
  const avatar = (props?.user?.attributes as UserAttribute)?.avatar
  const width = props?.width ? `${props.width}px` : '20px'
  const height = props?.height ? `${props.height}px` : '20px'

  if (!avatar) {
    return <Avatar backgroundColor="#011936" width={width} height={height} />
  }

  return <Box w={width} h={height} backgroundImage={avatar ? `url(${avatar})` : ''} bgPos="center" bgSize="cover" borderRadius="50%" />
}

export default MessageAvatar
