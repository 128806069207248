import { useQuery } from 'react-query'
import Api from 'services/api/api.service'
import { Category } from 'typings/database'

const useGetEventCategories = (options = {}) => {
  return useQuery('categories', () => Api.categories(), {
    retry: false,
    ...options,
  }) as {
    error: String | any
    isLoading: boolean
    data: { data: Category[] }
  }
}

export default useGetEventCategories
