import { Box, FormControl, Text } from '@chakra-ui/react'
import InputField from '@components/shared/input/input.component'
import React from 'react'

function CodeField(props: { register: any; userCode: string }) {
  return (
    <Box width="100%">
      <Text textColor="#465362" fontWeight="600" fontSize="0.9rem">
        Hai un codice?
      </Text>
      <FormControl>
        <InputField
          placeholder="Inseriscilo per ottenere un regalo!"
          width="100%"
          {...props.register('userCode', {
            value: props.userCode,
            validate: value => !/\s/g.test(value) || 'Il codice non può contenere alcuno spazio',
          })}
        />
      </FormControl>
    </Box>
  )
}

export default CodeField
