import { Message, MessageUpdateReason } from '@twilio/conversations'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useGetConversationQueryBySid from './use-get-conversation-query'

const useOnMessageAdded = setMessages => {
  const { sid } = useParams()

  const { data } = useGetConversationQueryBySid(sid)
  const conversation = data?.conversation

  const onMessageAdded = useCallback(
    async (message: Message) => {
      if (!message) {
        return
      }

      setMessages(m => [message, ...m])
      await conversation?.self.updateLastReadMessageIndex(message.index)
    },
    [conversation?.self, setMessages],
  )

  const onMessageRemoved = useCallback(
    async (message: Message) => {
      if (!message) {
        return
      }

      setMessages(m => m.filter(({ sid }) => sid !== message.sid))
      await conversation?.self.updateLastReadMessageIndex(message.index)
    },
    [conversation?.self, setMessages],
  )

  const onMessageUpdated = useCallback(
    async ({ message, updateReasons }: { message: Message; updateReasons: MessageUpdateReason[] }) => {
      if (!message) {
        return
      }

      setMessages(m => m.map(m => (m.sid !== message.sid ? m : message)))
      await conversation?.self.updateLastReadMessageIndex(message.index)
    },
    [conversation?.self, setMessages],
  )

  useEffect(() => {
    if (conversation) {
      conversation.self.on('messageAdded', onMessageAdded)
      conversation.self.on('messageRemoved', onMessageRemoved)
      conversation.self.on('messageUpdated', onMessageUpdated)
    }

    return () => {
      conversation?.self.removeAllListeners()
    }
  }, [conversation, onMessageAdded, onMessageRemoved, onMessageUpdated])
}

export default useOnMessageAdded
