import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ModalCloseButton,
} from '@chakra-ui/react'
import { Alert } from 'components/shared/confirm/confirm'
import React, { useRef } from 'react'
import Api from 'services/api/api.service'

const EventDeletionDialog = props => {
  const onDeleteClose = () => props.setDeleteOpen(false)
  const cancelDeleteRef = useRef(null)

  const confirmDeleteEvent = async () => {
    if (props.isOrganizer && props.id) {
      try {
        await Api.deleteEvent(props.id)
        props.navigate('/', { replace: true })
      } catch (e) {
        await Alert({ title: 'Errore', message: `L'evento potrebbe essere in corso/passato o non essere più disponibile.`, confirmText: 'Ho capito' })
      }
    }
  }

  return (
    <AlertDialog isCentered isOpen={props.isDeleteOpen} leastDestructiveRef={cancelDeleteRef} onClose={onDeleteClose}>
      <AlertDialogOverlay bg="blackAlpha.400" backdropFilter="blur(3px)">
        <AlertDialogContent mr="10px" ml="10px">
          <AlertDialogHeader fontWeight="bold">Cancella evento</AlertDialogHeader>
          <ModalCloseButton size="lg" />

          <AlertDialogBody>Sei sicuro? Questa azione non può essere annullata.</AlertDialogBody>

          <AlertDialogFooter>
            <Button bg="white" color="black" ref={cancelDeleteRef} onClick={onDeleteClose} height="30px" width="100%" fontSize="17px">
              Annulla
            </Button>
            <Button
              fontWeight="600"
              fontFamily="Poppins"
              textAlign="center"
              variant="solid"
              height="40px"
              width="100%"
              fontSize="17px"
              onClick={confirmDeleteEvent}
              ml={3}>
              Cancella evento
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default EventDeletionDialog
