import { useMutation } from 'react-query'
import type { ChatSession } from 'firebase/vertexai-preview'
import type { GeminiAnswer } from './types'

const useSendMessageToGeminiMutation = (chat: ChatSession, options = {}) => {
  return useMutation(
    'send-message-to-gemini',
    async (prompt: string): Promise<GeminiAnswer> => {
      const result = await chat.sendMessage(prompt)
      const response = await result.response
      const text = response.text()

      const parsed = text.replaceAll('json', '').replaceAll('`', '').replaceAll('´', '')
      console.debug(parsed)
      return JSON.parse(parsed)
    },
    {
      ...options,
    },
  )
}

export default useSendMessageToGeminiMutation
