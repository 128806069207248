import { Haptics, ImpactStyle } from '@capacitor/haptics'
import { Box, Flex } from '@chakra-ui/react'
import { AppContext } from 'app.context'
import useOnLongPress from 'hooks/use-on-long-press'
import React, { useCallback, useContext } from 'react'
import { FaReply } from 'react-icons/fa'
import { useChatContext } from '../context/chat-context'
import useDeleteMessageMutation from '../hooks/use-delete-message-mutation'
import useGetUserFromMessageQuery from '../hooks/use-get-user-from-message'
import ChatBoxHeader from './chat-box-header'
import ChatBoxBody from './chat-box-body'
import ChatBoxFooter from './chat-box-footer'

function ChatBox({ msg, isSameAuthorAsNext }) {
  const { setMessageForDrawer } = useChatContext()
  const { client: twilioClient } = useContext(AppContext)

  const deleteMessage = useDeleteMessageMutation(msg)

  const { data: user } = useGetUserFromMessageQuery(msg)

  const isMine = user?.identity === twilioClient?.user.identity

  const sid = msg?.sid

  const onLongPressCallback = useCallback(() => {
    setMessageForDrawer(msg)

    Haptics.impact({ style: ImpactStyle.Light })
  }, [msg, setMessageForDrawer])

  const { onLongPressStart, onLongPressEnd } = useOnLongPress({
    namespace: 'chat',
    callback: onLongPressCallback,
  })

  return (
    <Flex className="chat-box" width="100%" gap="1rem" alignItems="center" justifyContent={isMine ? 'end' : 'start'}>
      <ChatMessageIcon sid={sid} />

      <Flex
        backgroundColor={isMine ? 'rgba(255, 116, 54, .1)' : '#F7F7F7'}
        borderRadius="1rem"
        direction="column"
        onMouseDown={onLongPressStart}
        onMouseUp={onLongPressEnd}
        onTouchEnd={onLongPressEnd}
        onTouchStart={onLongPressStart}
        padding="10px 10px"
        gap=".5rem"
        opacity={deleteMessage.isLoading ? '.2' : 1}
        width="75%">
        <ChatBoxHeader msg={msg} user={user} isMine={isMine} isSameAuthorAsNext={isSameAuthorAsNext} />

        <ChatBoxBody msg={msg} />

        <ChatBoxFooter msg={msg} />
      </Flex>
    </Flex>
  )
}

function ChatMessageIcon({ sid }) {
  const { reply } = useChatContext()

  if (reply?.sid === sid) {
    return (
      <Box color="brand.500">
        <FaReply />
      </Box>
    )
  }

  return <></>
}

export default ChatBox
