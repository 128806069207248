import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { I } from 'components/shared/input/input.style'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { sendPasswordResetEmail } from 'firebase/auth'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { auth } from 'services/firebase.service'

export const ForgotPassword = () => {
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const forgotPassword = async () => {
    try {
      // const auth = getFirebaseAuth()
      await sendPasswordResetEmail(auth, email)
      setMessage('Una mail con il link di reset della password è stata inviata alla tua mail, controlla fra qualche minuto.')
      setSuccess(true)
    } catch (e: any) {
      const errorCode = e.code
      if (errorCode === 'auth/user-not-found') {
        setMessage("L'indirizzo email non è registrato.")
        setError(true)
      } else if (errorCode === 'auth/invalid-email') {
        setMessage("L'indirizzo email non è valido.")
        setError(true)
      } else {
        setMessage("C'è stato un errore nel reset della password, contatta l'assistenza.")
        setError(true)
      }
      setSuccess(false)
    }
  }
  return (
    <Wrapper justifyContent="center" minH="full">
      <Flex direction="column">
        <Box mb="30px" textAlign="center">
          <Heading mb={4}>Benvenuto in Sbam!</Heading>
          <Text fontWeight="600">Recupera password</Text>
        </Box>
      </Flex>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" maxWidth={800} height="100%" mb="40px">
        {message && (
          <>
            <Text alignSelf="flex-start" fontSize="14px" mb="24px">
              {message}
            </Text>
          </>
        )}
        {!success && (
          <>
            <I placeholder="E-mail" mb="24px" onChange={e => setEmail(e.target.value)} value={email} />

            <Button size="md" onClick={forgotPassword}>
              Invia link di recupero
            </Button>
          </>
        )}
      </Flex>
      <Box textAlign="center">
        <Text>
          <RouterLink to="/">Torna al login</RouterLink>
        </Text>
      </Box>
    </Wrapper>
  )
}
