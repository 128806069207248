import { Flex } from '@chakra-ui/react'
import ScreenHeader from '@components/shared/screen-header/screen-header'
import Wrapper from '@components/shared/wrapper/wrapper.component'
import React from 'react'
import SignupScreenForm from './signup-screen-form'
import useQueryParams from 'hooks/use-query-params'

function SignupScreen() {
  const queryParams = useQueryParams()
  const queryStep = queryParams.get('step') ?? 1
  const step: number = typeof queryStep == 'number' ? queryStep : parseInt(queryStep)

  return (
    <Wrapper height="100%" minHeight="40rem" pt="1rem" px="0">
      <Flex flexDir="column" gap="2.5rem" height="100%">
        <ScreenHeader title={step === 1 ? 'Entra in Sbam!' : step === 2 ? 'Raccontaci di te' : 'Ci sei quasi'} mx="1.25rem" doLogout={true} />
        <SignupScreenForm step={step} queryParams={queryParams} />
      </Flex>
    </Wrapper>
  )
}

export default SignupScreen
