import { Text } from '@chakra-ui/react'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

const RecoverPasswordButton = () => {
  return (
    <RouterLink to="/forgot-password">
      <Text textColor="black" fontWeight="400" fontSize="0.8rem" textDecor="underline" width="fit-content" _hover={{ color: 'brand.500' }}>
        Recupera la tua password
      </Text>
    </RouterLink>
  )
}

export default RecoverPasswordButton
